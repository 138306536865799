import axios from 'axios';
import { SET_WITHDRAWAL_REQUEST_LIST } from 'constants/actionTypes';
import downloadFile from 'utils/downloadFile';

export const getWithdrawalRequests = (params) => {
  return (dispatch) => {
    return axios
      .get(`withdrawals/list`, { params })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: SET_WITHDRAWAL_REQUEST_LIST,
            payload: response.data.data,
          });
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const createWithdrawalRequests = (withdrawals) => {
  return () => {
    return axios
      .post(`withdrawals/create`, { withdrawals })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const exportWithdrawals = (params) => {
  return async () => {
    try {
      const response = await axios.get('withdrawals/export-withdrawals', {
        params,
        responseType: 'blob',
      });
      if (response.status === 200) {
        downloadFile(response.data, `Withdrawals-${Date.now()}.xlsx`);
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const rejectWithdrawal = (withdrawalId) => {
  return () => {
    return axios
      .post(`withdrawals/reject`, {
        withdrawalIds: [withdrawalId],
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
