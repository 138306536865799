import FieldRow from 'components/FormElements/FieldRow';
import { Address, getFormattedAddress } from 'further-ui/utils';

type Props = {
  adviserData: {
    firstName: string;
    middleName: string;
    lastName: string;
    fcaNumber: string;
    phoneNumber: string;
    email: string;
    address: Array<Address>;
  };
};

const AdviserBasicDetails = ({ adviserData }: Props) => {
  return (
    <>
      <FieldRow title="First name" centerTitle>
        {adviserData.firstName}
      </FieldRow>
      <FieldRow title="Middle name" centerTitle>
        {adviserData.middleName}
      </FieldRow>
      <FieldRow title="Last name" centerTitle>
        {adviserData.lastName}
      </FieldRow>
      <FieldRow title="Personal FCA number" centerTitle>
        {adviserData.fcaNumber}
      </FieldRow>
      <FieldRow title="Phone number" centerTitle>
        {adviserData.phoneNumber}
      </FieldRow>
      <FieldRow title="Email address" centerTitle>
        {adviserData.email}
      </FieldRow>
      <FieldRow title="Correspondence address" centerTitle>
        {adviserData.address?.map((address, i: number) => (
          <div key={i}>{getFormattedAddress(address)}</div>
        ))}
      </FieldRow>
    </>
  );
};

export default AdviserBasicDetails;
