import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { Grid, Button } from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import GridContainer from 'components/GridContainer';
import AddExitDetails, { FormData } from './AddDetail';
import { INVESTOR_REPORTING } from 'constants/routes';
import Summary from '../Common/Summary';
import { useReportingAccountManagers } from 'hooks/data/investorReporting/useReportingAccountManagers';
import useExitStatement from 'hooks/data/exit/useExitStatement';
import { useExit } from 'hooks/data/exit';
import useStyles from './styles';

type Params = {
  id: string;
  exitAmendmentId?: string;
};

const CreateExitStatement = () => {
  const history = useHistory();
  const classes = useStyles();
  const heading = 'Investor communications: Exit statement';
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: ' Exit statement', link: '/', isActive: true },
  ];

  const { id, exitAmendmentId } = useParams<Params>();

  const steps = { addExitStatementDetails: 1, summary: 2 };
  const [activeStep, setActiveStep] = useState(steps.addExitStatementDetails);

  const { exit } = useExit({ params: { id } });

  const { data: managers } = useReportingAccountManagers(
    exit.data?.companyId?.firmId,
  );

  const { createExitStatement } = useExitStatement({
    exitId: id,
    exitAmendmentId,
  });

  const [formValues, setFormValues] = useState<FormData>({
    title: '',
    summary: '',
    letter: '',
    accountManager: { name: '', _id: '' },
    role: '',
    notes: '',
  });

  const handleNext = () => {
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleSubmitDetails = (values: FormData) => {
    setFormValues(values);
    handleNext();
  };

  const handleSubmit = () => {
    if (!formValues) return;
    createExitStatement.mutate(formValues);
  };

  useEffect(() => {
    if (createExitStatement.isSuccess) {
      history.push(INVESTOR_REPORTING);
    }
  }, [createExitStatement.isSuccess]);

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent className={classes.cardContentPadding}>
          <CardHeader
            title={
              activeStep === steps.addExitStatementDetails
                ? 'Exit statement details'
                : 'Exit statement summary'
            }
          />

          {steps.addExitStatementDetails === activeStep ? (
            <AddExitDetails
              managers={managers}
              onNext={handleSubmitDetails}
              defaultValues={formValues}
            />
          ) : (
            <Summary
              title={formValues.title}
              summary={formValues.summary}
              letter={formValues.letter}
              accountManager={formValues.accountManager?.name}
              role={formValues.role}
              notes={formValues.notes}
            />
          )}

          {activeStep === steps.summary && (
            <GridContainer item md={12}>
              <Grid item xs={12}>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setActiveStep(steps.addExitStatementDetails);
                    }}
                  >
                    Back
                  </Button>

                  <Button
                    className={classes.buttonLeftMargin}
                    color="primary"
                    variant="contained"
                    disabled={createExitStatement.isLoading}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </GridContainer>
          )}
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};
export default CreateExitStatement;
