import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import { sumBy } from 'lodash';
import Table, { useStyles as useTableStyles } from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { Delete, Edit } from '@material-ui/icons';
import { deleteInvestment } from '@redux/actions/Investment';
import { createInvestmentEditRoute } from 'constants/routes';
import AlertDialog from 'components/AlertDialog';
import { EIS3Download } from 'components/SVGIcons';
import { useInvestments } from 'hooks/data/investment/useInvestments';
import { usePagination } from 'hooks/ui/usePagination';
import { useGetRole } from 'hooks/ui/useGetRole';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { useDownloadByInvestmentId } from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';

const useStyles = makeStyles((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
    alignItems: 'baseline',
  },
  content: {
    marginBottom: '8px',
  },
}));

const InvestmentsTable = ({ investorId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination();
  const deleteDisclosure = useDisclosure();
  const handleApiRequest = useApiRequestHandler();
  const { firmId } = useGetRole();
  const tableClasses = useTableStyles();

  const downloadEisCerts = useDownloadByInvestmentId();
  const investments = useInvestments({
    params: {
      investorId,
      firmId,
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      type: 'desc',
      keyName: 'investmentDate',
    },
  });

  const downloadAllEISCertificates = (investment) => {
    downloadEisCerts.download({
      investmentId: investment._id,
      fullName: investment.fullName,
      trancheName: `${investment.firmId.firmName}-${investment.fundId.fundName}`,
    });
  };

  const columns = useMemo(
    () => [
      {
        label: 'Firm',
        key: 'firmName',
        sort: false,
        render: (elm) => elm?.firmId?.firmName,
      },
      {
        label: 'Tranche',
        key: 'fundName',
        sort: false,
        render: (elm) => elm?.fundId?.fundName,
      },
      {
        label: 'External investment ID',
        sort: false,
        render: (elm) => elm.externalInvestmentId,
      },
      {
        label: 'Subscription date',
        sort: false,
        render: (elm) => dateToLabel(elm.investmentDate),
      },
      {
        label: 'Investment',
        key: 'investmentAmount',
        sort: false,
        render: (elm) => numberToCurrencyString(elm?.investmentAmount),
      },
      {
        label: 'Undeployed capital',
        key: 'fundsRemainingtoBeAllocated',
        sort: false,
        render: (elm) =>
          numberToCurrencyString(
            elm?.investmentBalance?.deploymentSummary
              ?.heldByFundAwaitingInvestment,
          ),
      },
      {
        label: 'Total current value',
        sort: false,
        render: (elm) =>
          numberToCurrencyString(elm?.investmentBalance?.investmentValue),
      },
      {
        label: 'Net realised value',
        sort: false,
        render: (elm) =>
          numberToCurrencyString(elm?.investmentBalance?.realisedValue?.total),
      },
      {
        label: 'Actions',
        sort: false,
        render: (elm) => (
          <div className={classes.dFlex}>
            <Link
              to={createInvestmentEditRoute(elm._id)}
              className={classes.dFlex}
            >
              <Button
                color="primary"
                className={classes.customButton}
                disabled={downloadEisCerts.isLoading}
              >
                <Edit />
              </Button>
            </Link>
            {elm?.isAllocationExists ? (
              <Button
                title="Download all EIS certificates"
                className={classes.customButton}
                onClick={() => downloadAllEISCertificates(elm)}
              >
                <EIS3Download />
              </Button>
            ) : null}
            <Button
              className={`${classes.customButton}`}
              onClick={() => deleteDisclosure.stageAction(elm)}
            >
              <Delete htmlColor="#E8453C" />
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  const handleConfirmDelete = async () => {
    const record = deleteDisclosure.actionProps;

    await handleApiRequest(async (showSuccess) => {
      const response = await dispatch(deleteInvestment(record._id));
      if (response.status === 200) {
        showSuccess(response.data.responseMsg);
        investments.refetch();
      }
    });

    deleteDisclosure.onClose();
  };

  return (
    <>
      <Table
        pagination={true}
        columns={columns}
        tablebody={investments.data?.result || []}
        variant="nohover"
        emptyMessage={'No investments created for this investor yet.'}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        count={investments.data?.total ?? 0}
        TableFooter={
          <tfoot>
            <tr className={tableClasses.tFootRow}>
              <td className={tableClasses.tFootCell} colSpan={4} />
              <td className={tableClasses.tFootCell}>
                {numberToCurrencyString(
                  sumBy(investments.data?.result, 'investmentAmount'),
                )}
              </td>
              <td className={tableClasses.tFootCell}>
                {numberToCurrencyString(
                  sumBy(
                    investments.data?.result,
                    'investmentBalance.deploymentSummary.heldByFundAwaitingInvestment',
                  ),
                )}
              </td>
              <td className={tableClasses.tFootCell}>
                {numberToCurrencyString(
                  sumBy(
                    investments.data?.result,
                    'investmentBalance.investmentValue',
                  ),
                )}
              </td>
              <td className={tableClasses.tFootCell}>
                {numberToCurrencyString(
                  sumBy(
                    investments.data?.result,
                    'investmentBalance.realisedValue.total',
                  ),
                )}
              </td>
            </tr>
          </tfoot>
        }
      />

      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        onConfirm={handleConfirmDelete}
        title={
          'This cannot be undone and all information relating to the investment will be lost.'
        }
        content={
          <>
            <p className={classes.content}>
              Any money transferred to you must be refunded to the investor
              before the investment is deleted.
            </p>
            <p className={classes.content}>
              Any money that was transferred from the investor's cash balance to
              pay for this investment will be returned to their cash balance.
            </p>
          </>
        }
        confirmBtnProps={{ color: 'secondary' }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete investment',
        }}
      />
    </>
  );
};

export default InvestmentsTable;
