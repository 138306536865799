import GridContainer from 'components/GridContainer';
import { Button, Grid } from '@material-ui/core';
import Switch from 'components/Switch';
import TextField from 'components/FormElements/AppTextInput';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import AppCheckbox from 'components/FormElements/AppCheckBox';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import FieldRow from 'components/FormElements/FieldRow';
import { useGetRole } from 'hooks/ui/useGetRole';
import { TrancheVisibility } from 'further-types/fund';
import { FirmSelector } from 'components/FirmSelector';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  switchLabel: {
    margin: '0 10px',

    '&:first-child': {
      marginLeft: 0,
    },
  },
}));

export default function BasicInformation({
  errorTextBasic,
  handleCancel,
  handleChangeTag,
  handleClick,
  handleDateChange,
  id,
  investment,
  setValue,
  tagList,
  value,
  disabled = false,
}) {
  const classes = useStyles();
  const userRole = useGetRole();
  const { id: idInUrl } = useParams<{ id: string }>();

  return (
    <GridContainer item md={11}>
      <FieldRow title="Firm*" centerTitle>
        <FirmSelector
          firmId={value.firmId}
          onChange={(firmId) => setValue({ ...value, firmId })}
          placeholder="Select firm"
          allowDeselection={false}
          error={errorTextBasic.firmId}
          helperText={errorTextBasic.firmId}
          required
        />
      </FieldRow>
      <FieldRow title="Tranche name*" centerTitle>
        <TextField
          required
          name="tancheName"
          fullWidth
          onChange={(event) =>
            setValue({ ...value, fundName: event.target.value })
          }
          value={value.fundName}
          placeholder="Enter the tranche name"
          variant="outlined"
          error={errorTextBasic.fundName}
          helperText={errorTextBasic.fundName}
        />
      </FieldRow>
      <FieldRow title="Tranche status*" centerTitle>
        <AppRadioButton
          required
          key={'Open'}
          name="fundStatus"
          label={'Open'}
          value={1}
          checked={value.fundStatus === 1}
          onChange={() => setValue({ ...value, fundStatus: 1 })}
          error={errorTextBasic.fundStatus}
          helperText={errorTextBasic.fundStatus}
        />
        {idInUrl && (
          <AppRadioButton
            key={'closed'}
            name="fundStatus"
            label={'Closed'}
            value={0}
            checked={value.fundStatus === 0}
            onChange={() => setValue({ ...value, fundStatus: 0 })}
          />
        )}
        {!investment.length && (
          <AppRadioButton
            key={'Draft'}
            name="fundStatus"
            label={'Draft'}
            value={2}
            checked={value.fundStatus === 2}
            onChange={() => setValue({ ...value, fundStatus: 2 })}
            error={errorTextBasic.fundStatus}
            helperText={errorTextBasic.fundStatus}
          />
        )}
      </FieldRow>

      {!value.fundStatus && (
        <FieldRow title="Fund closed message" centerTitle>
          <TextField
            margin="normal"
            name="fundClosedMessage"
            fullWidth
            onChange={(event) =>
              setValue({
                ...value,
                fundClosedMessage: event.target.value,
              })
            }
            value={value.fundClosedMessage}
            error={errorTextBasic.fundClosedMessage}
            helperText={errorTextBasic.fundClosedMessage}
            variant="outlined"
            placeholder="e.g. Closed, Coming soon, Re-opening soon"
          />
        </FieldRow>
      )}
      {value.fundStatus === 1 && (
        <>
          <FieldRow title="Tranche closing soon" centerTitle>
            {/* @ts-ignore JSX element class does not support attributes because it does not have a 'props' property */}
            <AppCheckbox
              key="fundClosedSoon"
              label="Tick if you wish to display a closing soon message"
              checked={value.fundClosedSoon}
              onChange={(event) =>
                setValue({
                  ...value,
                  fundClosedSoon: event.target.checked,
                })
              }
            />
          </FieldRow>
          {value.fundClosedSoon && (
            <FieldRow title="Closing soon message" centerTitle>
              <TextField
                required
                margin="normal"
                name="fundClosedSoonText"
                placeholder="e.g. Only one week left to invest!"
                fullWidth
                onChange={(event) =>
                  setValue({
                    ...value,
                    fundClosedSoonText: event.target.value,
                  })
                }
                value={value.fundClosedSoonText}
                error={errorTextBasic.fundClosedSoonText}
                helperText={errorTextBasic.fundClosedSoonText}
                variant="outlined"
              />
            </FieldRow>
          )}
        </>
      )}

      <FieldRow title="Show on firm link?" centerTitle>
        <span className={classes.switchLabel}>Only show on tranche link</span>
        <Switch
          key="trancheVisibility"
          checked={value.trancheVisibility === TrancheVisibility.ShowOnFirm}
          onChange={(event) =>
            setValue({
              ...value,
              trancheVisibility: event.target.checked
                ? TrancheVisibility.ShowOnFirm
                : TrancheVisibility.ShowOnTranche,
            })
          }
        />
        <span className={classes.switchLabel}>Show on firm link</span>
      </FieldRow>

      <FieldRow title="Fund type*" centerTitle>
        {tagList.map((item, index) => (
          <AppRadioButton
            key={index}
            name="tag"
            label={item.name}
            value={item._id}
            checked={value.tag === item?._id}
            onChange={(event) => handleChangeTag(event.target.value, item)}
          />
        ))}
        {errorTextBasic.tag && (
          <FormHelperText error={true}>{errorTextBasic.tag}</FormHelperText>
        )}
      </FieldRow>

      <FieldRow title="Professional clients only" centerTitle>
        <span className={classes.switchLabel}>No</span>
        <Switch
          key="isProfessionalClientsOnly"
          checked={value.isProfessionalClientsOnly}
          onChange={(event) =>
            setValue({
              ...value,
              isProfessionalClientsOnly: event.target.checked,
            })
          }
        />
        <span className={classes.switchLabel}>Yes</span>
      </FieldRow>

      <FieldRow title="Scheduled close date*" centerTitle>
        <KeyboardDatePicker
          required
          margin="normal"
          name="finishDate"
          value={value.finishDate}
          onChange={handleDateChange}
          error={errorTextBasic.finishDate}
          helperText={errorTextBasic.finishDate}
          placeholder="Please select close date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FieldRow>

      {userRole?.isSuperAdmin && (
        <FieldRow title="Show on 'View funds' page on Further?" centerTitle>
          <span className={classes.switchLabel}>Hide</span>
          <Switch
            key="isViewFund"
            checked={value.isViewFund}
            onChange={(event) =>
              setValue({ ...value, isViewFund: event.target.checked })
            }
          />
          <span className={classes.switchLabel}>Show</span>
        </FieldRow>
      )}
      <FieldRow
        title="Highest historic return"
        tooltipText="Please enter the highest total return your fund has achieved in a historic tranche with the same strategy (e.g. generalist) and tax treatment (e.g. EIS) as this tranche."
        centerTitle
      >
        <TextField
          margin="normal"
          name="highestHistoricReturn"
          placeholder="e.g. 5.5x"
          fullWidth
          onChange={(event) =>
            setValue({
              ...value,
              highestHistoricReturn: event.target.value,
            })
          }
          value={value.highestHistoricReturn}
          variant="outlined"
          error={errorTextBasic.highestHistoricReturn}
          helperText={errorTextBasic.highestHistoricReturn}
        />
      </FieldRow>
      <FieldRow title="Tag 1" centerTitle>
        <TextField
          margin="normal"
          name="fundTag1"
          placeholder="Enter tag, e.g. FinTech, Generalist or other summary word"
          fullWidth
          onChange={(event) =>
            setValue({ ...value, fundTag1: event.target.value })
          }
          value={value.fundTag1}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Tag 2" centerTitle>
        <TextField
          margin="normal"
          name="fundTag2"
          placeholder="Enter tag, e.g. Pre-seed, Seed, Series A or other summary word"
          fullWidth
          onChange={(event) =>
            setValue({ ...value, fundTag2: event.target.value })
          }
          value={value.fundTag2}
          variant="outlined"
        />
      </FieldRow>
      {value.fundStatus === 1 && (
        <FieldRow title="Order" centerTitle>
          <TextField
            margin="normal"
            name="order"
            fullWidth
            onChange={(event) =>
              setValue({ ...value, order: event.target.value })
            }
            value={value.order}
            variant="outlined"
            error={errorTextBasic.order}
            helperText={errorTextBasic.order}
            placeholder="Enter order"
          />
        </FieldRow>
      )}

      <Grid item xs={12}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleClick}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
