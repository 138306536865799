import { PaymentEmailStatus } from 'further-types/investment';
import { z } from 'zod';

export const investmentFormSchema = z.object({
  investorId: z.string(),
  firmId: z.string(),
  fundId: z.string(),
  investmentDate: z.string(),
  investmentAmount: z.number(),
  adviserId: z.string().optional(),
  adviserFee: z.number().optional(),
  externalInvestmentId: z.string().optional(),
  cashBalance: z.number().optional(),
  paymentEmailStatus: z.nativeEnum(PaymentEmailStatus),
});
