import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button } from '@material-ui/core';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import FieldRow from 'components/FormElements/FieldRow';
import Switch from 'components/Switch';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  switchLabel: {
    color: '#656565',
    fontSize: 12,
    margin: '0 10px',

    '&:first-child': {
      marginLeft: 0,
    },
  },
  switchNote: {
    color: '#656565',
    fontSize: 12,
    marginLeft: 16,
  },
}));

export default function BankDetails({
  bank,
  bankError,
  handleCancel,
  handleClickBank,
  id,
  setBank,
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <GridContainer item md={11}>
      <FieldRow title="Account holder name*" centerTitle>
        <TextField
          required
          name="accountName"
          placeholder="Please enter account holder name"
          fullWidth
          onChange={(event) =>
            setBank({ ...bank, accountName: event.target.value })
          }
          value={bank?.accountName}
          variant="outlined"
          error={bankError?.accountName}
          helperText={bankError?.accountName}
        />
      </FieldRow>
      <FieldRow title="UK bank account number*" centerTitle>
        <TextField
          name="accountNumber"
          placeholder="Please enter bank account number"
          fullWidth
          required
          type="number"
          onChange={(event) =>
            setBank({ ...bank, accountNumber: event.target.value })
          }
          value={bank?.accountNumber}
          variant="outlined"
          error={bankError?.accountNumber}
          helperText={bankError?.accountNumber}
        />
      </FieldRow>
      <FieldRow title="UK bank sort code*" centerTitle>
        <NumberFormat
          required
          value={bank?.sortcode}
          placeholder="Please enter bank sort code"
          name={'sortcode'}
          format="##-##-##"
          customInput={TextField}
          type="text"
          error={bankError?.sortcode}
          helperText={bankError?.sortcode}
          onValueChange={({ value: v }) => setBank({ ...bank, sortcode: v })}
        />
      </FieldRow>
      <FieldRow
        title="Payment helper text"
        informationText="This helper text appears on the bank transfer payment page, alongside bank details."
      >
        <TextField
          name="title"
          fullWidth
          onChange={(event) => setBank({ ...bank, title: event.target.value })}
          value={bank?.title}
          error={bankError?.title}
          helperText={bankError?.title}
          placeholder="(Optional) Enter up to 30-35 words in this field if you wish to provide additional payment instructions or context, for example how to pay by cheque, or what your custodian’s name is. The system automatically creates payment references for each investor, so please do not add references here."
          multiline
          minRows={5}
        />
      </FieldRow>
      <FieldRow title="IBAN" centerTitle>
        <TextField
          name="iban"
          fullWidth
          placeholder="Enter IBAN"
          onChange={(event) => setBank({ ...bank, iban: event.target.value })}
          value={bank?.iban}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="SWIFT" centerTitle>
        <TextField
          name="swift"
          fullWidth
          placeholder="Enter SWIFT"
          onChange={(event) => setBank({ ...bank, swift: event.target.value })}
          value={bank?.swift}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Open banking payments (fee per use)" centerTitle>
        <span className={classes.switchLabel}>Disabled</span>
        <Switch
          label="Open banking"
          key="openBankingMode"
          checked={bank?.openBankingMode}
          onChange={(event) =>
            setBank({
              ...bank,
              openBankingMode: event.target.checked,
            })
          }
        />
        <span className={classes.switchLabel}>Enabled</span>
        <span className={classes.switchNote}>
          <em>Open Banking has a transaction limit of £15,000.</em>
        </span>
      </FieldRow>
      <FieldRow>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleClickBank}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </FieldRow>
    </GridContainer>
  );
}
