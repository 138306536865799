import { api } from 'lib/httpClient';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';

const QUERY_CACHE_KEY = 'investor-reporting';

async function fetchCustomUpdate({ queryKey }) {
  try {
    const [, id] = queryKey;
    const { data: response } = await api.get(`custom-update/${id}`);
    return response.data.customUpdate;
  } catch (error) {
    throw error;
  }
}

async function createCustomUpdate(data) {
  try {
    const response = await api.post(
      `custom-update/send-update-to-recipients`,
      data,
    );
    return response;
  } catch (error) {
    throw error;
  }
}
async function fetchNumberOfReceipients(data) {
  try {
    const response = await api.post(
      `custom-update/get-number-of-recipients-to-send-update`,
      data,
    );
    return response;
  } catch (error) {
    return error;
  }
}

function useCreateCustomUpdateAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCustomUpdate,
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_CACHE_KEY],
      }),
  });
}

function useFetchCustomUpdateAPI(id) {
  return useQuery({
    queryKey: ['custom-update', id],
    queryFn: fetchCustomUpdate,
    keepPreviousData: true,
  });
}

function useGetNumberOfRecipientsToSendUpdateAPI() {
  return useMutation({
    mutationFn: fetchNumberOfReceipients,
  });
}

export function useCustomUpdate(params) {
  return {
    createCustomUpdate: useCreateCustomUpdateAPI(params),
    fectchCustomUpdate: useFetchCustomUpdateAPI,
    getNumberOfRecipientsToSendUpdate:
      useGetNumberOfRecipientsToSendUpdateAPI(params),
  };
}
