import { makeStyles } from '@material-ui/core';
import { Tooltip } from 'components/Tooltip';
import { Api } from 'further-types/eis-wizard';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: '50px',
    display: 'inline-block',
    padding: '0.25rem 1rem',
    fontWeight: 'bold',
  },
  yesWithoutCertificatesChip: {
    color: 'rgba(150, 150, 150, 1)',
    backgroundColor: 'rgba(178, 178, 178, 0.1)',
  },
  yesChip: {
    color: theme.palette.success.main,
    backgroundColor: 'rgba(86, 178, 108, 0.1)',
  },
  noChip: {
    color: theme.palette.error.main,
    backgroundColor: 'rgba(2332, 69, 60, 0.1)',
  },
  naPadding: {
    margin: '0.25rem 0 ',
    display: 'block',
  },
}));

type Props = {
  tasklistItem: Api.GetEisWizardTaskListResponse['results'][number];
};

const ProcessCompletedChip: FC<Props> = ({ tasklistItem }) => {
  const classes = useStyles();

  if (!tasklistItem?.taxReliefElegible)
    return <span className={classes.naPadding}>N/A</span>;

  return tasklistItem.eisWizard?.id || tasklistItem.isKiFundCertificate ? (
    <>
      {tasklistItem.createdWithoutCertificates ? (
        <Tooltip
          arrow
          title={`Created without certificates (as ${tasklistItem?.eisWizard.investmentType})`}
        >
          <span
            className={`${classes.chip} ${classes.yesWithoutCertificatesChip}`}
          >
            Yes
          </span>
        </Tooltip>
      ) : (
        <span className={`${classes.chip} ${classes.yesChip}`}>Yes</span>
      )}
    </>
  ) : (
    <span className={`${classes.chip} ${classes.noChip}`}>No</span>
  );
};

export default ProcessCompletedChip;
