import React from 'react';
import Table from 'components/Table';
import commonColumns from 'helpers/withdrawals/commonColumns';
import { numberToCurrencyString } from 'further-ui/utils';
import { IconButton, makeStyles } from '@material-ui/core';
import { AttachFile, Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
  },
}));

const QueuedWithdrawalsTable = ({
  queuedWithdrawals,
  removeWithdrawalFromQueue,
  firmId,
}) => {
  const classes = useStyles();

  const columns = [
    ...commonColumns(firmId),
    {
      label: 'Amount to withdraw',
      key: 'amountToWithdraw',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm?.withdrawalDetails?.amountToWithdraw),
    },
    {
      label: 'Notes',
      key: 'note',
      sort: false,
      render: (elm) => elm?.withdrawalDetails?.note || '-',
    },

    {
      label: '',
      key: 'attachment',
      sort: false,
      render: (elm) => {
        return (
          <div className={classes.flex}>
            {elm?.withdrawalDetails?.attachment?.signedGetUrl && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={elm.withdrawalDetails.attachment.signedGetUrl}
              >
                <IconButton>
                  <AttachFile />
                </IconButton>
              </a>
            )}
            <IconButton
              title="Delete"
              onClick={() => removeWithdrawalFromQueue(elm._id)}
            >
              <Delete htmlColor="#E8453C" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns.filter((col) => !col.hideCol)}
      tablebody={queuedWithdrawals}
      variant="nohover"
    />
  );
};

export default QueuedWithdrawalsTable;
