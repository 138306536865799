import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { Status, StatusToLabelMap } from 'constants/withdrawalProperties';
import ExportButton from './ExportButton';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '1.5rem',
  },
  exportBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  custodianExport: {
    marginLeft: '10px',
  },
}));

const FiltersAndActions = ({
  filters,
  handleChangeFilters,
  onExportClick,
  withdrawalRequests,
  orderBy,
  order,
}) => {
  const { isSuperAdmin: downloadOnly } = useGetRole();
  const classes = useStyles();
  const disableExport = [
    Status.REJECTED,
    Status.EXPORTED_TO_CUSTODIAN,
  ].includes(filters.status);
  const noData = !withdrawalRequests || withdrawalRequests.length === 0;

  return (
    <GridContainer className={classes.root}>
      <Grid item md={downloadOnly ? 5 : 4} xs={12}>
        <MuiDateRangePicker
          dateRange={{ startDate: filters.startDate, endDate: filters.endDate }}
          onDateRangeChange={(dates) => {
            handleChangeFilters({
              ...dates,
            });
          }}
          TextFieldProps={{ placeholder: 'Select date range' }}
        />
      </Grid>
      <Grid item md={downloadOnly ? 3 : 2} xs={12}>
        <AppSelectBox
          data={Object.values(Status).map((status) => ({
            key: status,
            label: StatusToLabelMap[status],
          }))}
          valueKey="key"
          labelKey="label"
          value={filters.status}
          variant="outlined"
          onChange={(event) => {
            const { value } = event.target;
            handleChangeFilters({ status: value });
          }}
        />
      </Grid>
      <Grid
        item
        md={downloadOnly ? 4 : 6}
        xs={12}
        className={classes.exportBtn}
      >
        <ExportButton
          downloadOnly
          withdrawalRequests={withdrawalRequests}
          search={filters}
          orderBy={orderBy}
          order={order}
        />
        {!downloadOnly && (
          <Button
            disabled={disableExport || noData}
            color="primary"
            variant="contained"
            onClick={onExportClick}
            className={classes.custodianExport}
          >
            Accept all and export to custodian
          </Button>
        )}
      </Grid>
    </GridContainer>
  );
};

export default FiltersAndActions;
