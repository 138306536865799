import React, { useEffect, useState } from 'react';
import { onlineManager } from '@tanstack/react-query';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

import {
  ADVISER,
  ADVISER_ADD,
  ADVISER_EDIT,
  ADVISER_VIEW,
  ALL_FUNDS,
  COMING_SOON,
  COMPANY,
  COMPANY_ADD,
  COMPANY_EDIT,
  DASHBOARD,
  DASHBOARD_ALL_FIRMS,
  DASHBOARD_CLOSED_TRANCHE,
  DASHBOARD_MY_FIRM,
  DASHBOARD_OPEN_TRANCHE,
  DASHBOARD_SINGLE_FIRM,
  EIS,
  EIS_ADD,
  KI_EIS_ADD,
  EIS_EDIT,
  EXITS,
  EXITS_ADD,
  EXIT_STATEMENT_PDF_DOWNLOAD,
  FEE_DISCOUNT_ADD,
  FEE_DISCOUNT_SUCCESS,
  FEE_SUMMARY,
  FIRM,
  FIRM_ADD,
  FIRM_EDIT,
  INCOMPLETE_REGISTER,
  INCOMPLETE_TRANSACTION,
  INTEREST_PAYMENTS,
  INTEREST_PAYMENTS_ADD,
  INVESTMENT,
  INVESTMENT_EDIT,
  INVESTOR,
  INVESTOR_VIEW,
  INVESTOR_EDIT,
  INVESTOR_REPORTING,
  INVESTOR_REPORTING_ADD,
  INVESTOR_REPORTING_EDIT,
  INVESTOR_REPORTING_SUCCESS,
  INVESTOR_REPORTING_VIEW,
  INVESTOR_REPORTING_PDF_DOWNLOAD,
  INVESTOR_UPLOAD,
  ORGANISATION,
  ORGANISATION_ADD,
  ORGANISATION_EDIT,
  ORGANISATION_VIEW,
  ORGANISATION_ADD_USER,
  PROFILE,
  SUBSCRIPTION,
  TAG,
  TAG_ADD,
  TAG_EDIT,
  TRANCHE,
  TRANCHE_ADD,
  TRANCHE_EDIT,
  UPLOAD_SHEET,
  UPLOAD_SHEET_ADD,
  UPLOAD_SHEET_VIEW,
  USER,
  USER_ADD,
  USER_EDIT,
  WITHDRAWALS,
  WITHDRAWALS_ADD,
  SHARE_SPLIT_ADD,
  ADD_CUSTOM_UPDATE,
  VIEW_CUSTOM_UPDATE,
  ADD_DEPLOYMENT_UPDATE,
  ADD_EXIT_STATEMENT,
  VIEW_DEPLOYMENT_UPDATE,
  VIEW_EXIT_STATEMENT,
  EXITS_EDIT,
  INVESTOR_ADD,
  ADD_INVESTMENT_TRANSFER,
  INVESTMENT_TRANSFERS,
  TRANCHE_BESPOKE_LINK,
  INVESTOR_BULLETIN,
  INVESTMENT_ADD,
  KI_EIS_EDIT,
  TAX_REPORT_AUDIT,
  CHANGE_LOG,
} from 'constants/routes';

import OpenTrancheDashboard from './Pages/OpenTrancheDashboard';
import FirmDashboard from './Pages/FirmDashboard';
import ClosedTranchePage from './Pages/AllTranches';
import ClosedTrancheDashboard from './Pages/ClosedTrancheDashboard';
import ClosedTrancheAddUpdatePage from './Pages/Tranche/TrancheForm';
import InvestorPage from './Pages/Investor';
import InvestorDetailPage from './Pages/Investor/ViewInvestor';
import InvestorForm from './Pages/Investor/InvestorForm';
import InvestmentPage from './Pages/Investment';
import PartiallyInvestorPage from './Pages/Investor/IncompleteRegistrations';
import UploadInvestorInvestment from './Pages/UploadInvestorInvestment';
import PartiallyInvestmentPage from './Pages/Investment/IncompleteInvestment';
import UpdateInvestmentPage from './Pages/Investment/EditInvestment';
import Error404 from './Pages/404';
import ProfilePage from './Pages/Profile';
import UserPage from './Pages/User';
import AddUserPage from './Pages/User/UserForm';
import InvestmentFirmPage from './Pages/InvestmentFirm';
import TagPage from './Pages/Tag';
import AddTagPage from './Pages/Tag/TagForm';
import TranchePage from './Pages/Tranche';
import SubscriptionPage from './Pages/Subscription';
import EisWizard from './Pages/EisWizard/ListCertificates';
import CompanyPage from './Pages/Company';
import AddUpdateCompanyPage from './Pages/Company/CompanyForm';
import AddUpdateEisWizardPage from './Pages/EisWizard/EisWizardForm';
import InterestPaymentListPage from './Pages/InterestPayment';
import InterestPaymentCreatePage from './Pages/InterestPayment/InterestPaymentCreate';
import UploadSheetInvestmentPage from './Pages/UploadSheetInvestment';
import AddUploadSheet from './Pages/UploadSheetInvestment/AddUpdateUploadSheet';
import ViewUploadSheet from './Pages/UploadSheetInvestment/ViewUploadSheet';
import Adviser from './Pages/Adviser';
import AddUpdateAdviser from './Pages/Adviser/AdviserForm';
import ViewAdviser from './Pages/Adviser/ViewAdviser';
import Organisation from './Pages/Organisation/ListOrganisations';
import AddUpdateOrganisation from './Pages/Organisation/AddUpdateOrganisation';
import ViewOrganisation from './Pages/Organisation/ViewOrganisation';
import AddOrganisationUser from './Pages/Organisation/AddOrganisationUser';
import InvestorReporting from './Pages/InvestorReporting/View';
import AddUpdateInvestorReporting from './Pages/InvestorReporting/InvestorReportingWizard';
import InvestorReportingSuccess from './Pages/InvestorReporting/InvestorReportingWizard/Success';
import InvestorReportingPdfDownload from './Pages/InvestorReporting/InvestorReportingPdfDownload';
import FeeSummary from './Pages/FeeSummary';
import AddFeeAndDiscount from './Pages/FeeManagement/AddFeeAndDiscount';
import FeeAndDiscountSuccess from './Pages/FeeManagement/AddFeeAndDiscount/Success';
import ComingSoon from './Pages/ComingSoon';
import ReviewWithdrawals from './Pages/Withdrawals/ReviewWithdrawals';
import RequestWithdrawals from './Pages/Withdrawals/RequestWithdrawals';
import CreateAndEditExit from './Pages/Exits/ExitForm';
import ListExits from './Pages/Exits/ListExits';
import InvestmentFirmAddEditScreen from './Pages/InvestmentFirmAddEdit';
import AddShareSplit from './Pages/ShareSplit/AddShareSplit';
import CustomUpdate from './Pages/CustomUpdate/Add';
import ViewCustomUpdate from './Pages/CustomUpdate/View';
import DeploymentUpdate from './Pages/DeploymentUpdate/Add';
import AddExitStatement from './Pages/Exits/CreateStatement';
import ViewDeploymentUpdate from './Pages/DeploymentUpdate/View';
import ViewExitStatement from './Pages/Exits/ViewStatement';
import AddInvestmentTransfer from './Pages/InvestmentTransfer/AddInvestmentTransfer';
import InvestmentTransferList from './Pages/InvestmentTransfer/InvestmentTransferList';
import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';
import { useGetRole } from 'hooks/ui/useGetRole';
import { TrancheLink } from './Pages/Tranche/TrancheLink';
import InvestorBulletin from './Pages/InvestorBulletin';
import { AddInvestment } from './Pages/Investment/AddInvestment';
import KiFundCertificateBatchForm from './Pages/KiFundCertificate/CertificateBatchForm';
import TaxReportAudit from './Pages/TaxReportAudit';
import ChangeLog from './Pages/ChangeLog';
import ExitStatementPdfDownload from './Pages/InvestorReporting/ExitStatementPdfDownload';

const RestrictedRoute = ({
  component,
  ...rest
}: RouteProps & { component: React.FC<RouteComponentProps> }): JSX.Element => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <div>Loading...</div>,
    })}
    {...rest}
  />
);

const DashboardRedirectRoute = () => {
  const { isSuperAdmin } = useGetRole();

  if (isSuperAdmin) {
    return <Redirect to={DASHBOARD_ALL_FIRMS} />;
  }

  return <Redirect to={DASHBOARD_MY_FIRM} />;
};

const Routes = () => {
  const user = useCurrentUser();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [renderRoutes, setRenderRoutes] = useState<boolean>();
  const { hash } = useLocation();

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();

      // Sometimes react-query thinks we are offline, because navigator.onLine is false.
      // This means the call to user.refetch() below and any other react-query calls will fail
      // and the user will just get a blank screen. However, we can't trust navigator.onLine
      // as it can be false even when we are online. So we set it to true here to avoid this issue.
      onlineManager.setOnline(true);

      if (token) {
        const decodedToken = jwtDecode<{ permissions: string[] }>(token);
        localStorage.setItem('token', token);
        localStorage.setItem(
          'permission',
          JSON.stringify(decodedToken.permissions),
        );
        if (!user.data?.email) user.refetch();
      }
    };

    // if auth0 is loaded and the user is still not authenticated, render the
    // routes, and this in turn will trigger the withAuthenticationRequired HOC
    // from RestrictedRoute which will ultimately redirect the user to the login page
    if (!isLoading && !isAuthenticated) {
      setRenderRoutes(true);
    }

    if (!isLoading && isAuthenticated) {
      getToken();
    }

    // once auth0 has loaded and we know the user is authenticated,
    // render the routes once we have user data fetched from the API
    if (user.data?.email) {
      setRenderRoutes(true);
    }
  }, [
    user.data,
    // trigger on there being an error fetching the user
    user.isError,
    user.error,
    isAuthenticated,
    isLoading,
    // change the hash to trigger this useeffect hook to re-run
    hash,
  ]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (renderRoutes) {
    return (
      <>
        <Switch>
          <RestrictedRoute path="/" exact component={DashboardRedirectRoute} />
          <RestrictedRoute
            component={DashboardRedirectRoute}
            exact
            path={DASHBOARD}
          />
          <RestrictedRoute
            component={OpenTrancheDashboard}
            path={DASHBOARD_OPEN_TRANCHE}
          />
          <RestrictedRoute
            component={ClosedTrancheDashboard}
            path={DASHBOARD_CLOSED_TRANCHE}
          />
          <RestrictedRoute
            component={FirmDashboard}
            path={DASHBOARD_SINGLE_FIRM}
          />
          <RestrictedRoute component={FirmDashboard} path={DASHBOARD_MY_FIRM} />
          <RestrictedRoute
            component={FirmDashboard}
            path={DASHBOARD_ALL_FIRMS}
          />

          <RestrictedRoute component={ClosedTranchePage} path={ALL_FUNDS} />
          <RestrictedRoute component={TranchePage} path={TRANCHE} />
          <RestrictedRoute
            component={ClosedTrancheAddUpdatePage}
            path={TRANCHE_ADD}
          />
          <RestrictedRoute
            component={TrancheLink}
            path={TRANCHE_BESPOKE_LINK}
          />
          <RestrictedRoute
            component={ClosedTrancheAddUpdatePage}
            path={TRANCHE_EDIT}
          />
          <RestrictedRoute component={InvestorPage} path={INVESTOR} />
          <RestrictedRoute
            component={PartiallyInvestorPage}
            path={INCOMPLETE_REGISTER}
          />
          <RestrictedRoute
            component={InvestorDetailPage}
            path={INVESTOR_VIEW}
          />
          <RestrictedRoute component={InvestorForm} path={INVESTOR_EDIT} />
          <RestrictedRoute component={InvestorForm} path={INVESTOR_ADD} />
          <RestrictedRoute
            component={UploadInvestorInvestment}
            path={INVESTOR_UPLOAD}
          />
          <RestrictedRoute component={InvestmentPage} path={INVESTMENT} />
          <RestrictedRoute component={AddInvestment} path={INVESTMENT_ADD} />
          <RestrictedRoute
            component={PartiallyInvestmentPage}
            path={INCOMPLETE_TRANSACTION}
          />
          <RestrictedRoute
            component={UpdateInvestmentPage}
            path={INVESTMENT_EDIT}
          />
          <RestrictedRoute
            component={InterestPaymentListPage}
            exact
            path={INTEREST_PAYMENTS}
          />
          <RestrictedRoute
            component={InterestPaymentCreatePage}
            exact
            path={INTEREST_PAYMENTS_ADD}
          />
          <RestrictedRoute
            component={UploadSheetInvestmentPage}
            path={UPLOAD_SHEET}
          />
          <RestrictedRoute component={AddUploadSheet} path={UPLOAD_SHEET_ADD} />
          <RestrictedRoute
            component={ViewUploadSheet}
            path={UPLOAD_SHEET_VIEW}
          />
          <RestrictedRoute component={UserPage} path={USER} />
          <RestrictedRoute component={AddUserPage} path={USER_ADD} />
          <RestrictedRoute component={AddUserPage} path={USER_EDIT} />
          <RestrictedRoute component={ProfilePage} path={PROFILE} />
          <RestrictedRoute component={InvestmentFirmPage} path={FIRM} />
          <RestrictedRoute
            component={InvestmentFirmAddEditScreen}
            path={FIRM_ADD}
          />
          <RestrictedRoute
            component={InvestmentFirmAddEditScreen}
            path={FIRM_EDIT}
          />
          <RestrictedRoute component={TagPage} path={TAG} />
          <RestrictedRoute component={AddTagPage} path={TAG_ADD} />
          <RestrictedRoute component={AddTagPage} path={TAG_EDIT} />
          <RestrictedRoute component={SubscriptionPage} path={SUBSCRIPTION} />
          <RestrictedRoute component={CompanyPage} path={COMPANY} />
          <RestrictedRoute
            component={AddUpdateCompanyPage}
            path={COMPANY_ADD}
          />
          <RestrictedRoute
            component={AddUpdateCompanyPage}
            path={COMPANY_EDIT}
          />
          <RestrictedRoute component={EisWizard} path={EIS} />
          <RestrictedRoute component={AddUpdateEisWizardPage} path={EIS_ADD} />
          <RestrictedRoute component={AddUpdateEisWizardPage} path={EIS_EDIT} />
          <RestrictedRoute
            component={KiFundCertificateBatchForm}
            path={KI_EIS_ADD}
          />
          <RestrictedRoute
            component={KiFundCertificateBatchForm}
            path={KI_EIS_EDIT}
          />
          <RestrictedRoute
            component={AddFeeAndDiscount}
            path={FEE_DISCOUNT_ADD}
          />
          <RestrictedRoute
            component={FeeAndDiscountSuccess}
            path={FEE_DISCOUNT_SUCCESS}
          />
          <RestrictedRoute component={Adviser} path={ADVISER} />
          <RestrictedRoute component={AddUpdateAdviser} path={ADVISER_ADD} />
          <RestrictedRoute component={ViewAdviser} path={ADVISER_VIEW} />
          <RestrictedRoute component={AddUpdateAdviser} path={ADVISER_EDIT} />
          <RestrictedRoute component={Organisation} path={ORGANISATION} />
          <RestrictedRoute
            component={AddUpdateOrganisation}
            path={ORGANISATION_ADD}
          />
          <RestrictedRoute
            component={AddUpdateOrganisation}
            path={ORGANISATION_EDIT}
          />
          <RestrictedRoute
            component={ViewOrganisation}
            path={ORGANISATION_VIEW}
          />
          <RestrictedRoute
            component={AddOrganisationUser}
            path={ORGANISATION_ADD_USER}
          />
          <RestrictedRoute
            component={InvestorReporting}
            path={INVESTOR_REPORTING}
            exact
          />
          <RestrictedRoute
            component={AddUpdateInvestorReporting}
            path={INVESTOR_REPORTING_EDIT}
          />
          <RestrictedRoute
            component={AddUpdateInvestorReporting}
            path={INVESTOR_REPORTING_ADD}
          />
          <RestrictedRoute
            component={AddUpdateInvestorReporting}
            path={INVESTOR_REPORTING_VIEW}
          />
          <RestrictedRoute
            component={InvestorReportingSuccess}
            path={INVESTOR_REPORTING_SUCCESS}
          />
          <RestrictedRoute
            component={InvestorReportingPdfDownload}
            path={INVESTOR_REPORTING_PDF_DOWNLOAD}
          />
          <RestrictedRoute
            component={ExitStatementPdfDownload}
            path={EXIT_STATEMENT_PDF_DOWNLOAD}
          />
          <RestrictedRoute component={CustomUpdate} path={ADD_CUSTOM_UPDATE} />
          <RestrictedRoute
            component={ViewCustomUpdate}
            path={VIEW_CUSTOM_UPDATE}
          />
          <RestrictedRoute
            component={InvestorBulletin}
            path={INVESTOR_BULLETIN}
          />
          <RestrictedRoute component={TaxReportAudit} path={TAX_REPORT_AUDIT} />
          <RestrictedRoute component={FeeSummary} path={FEE_SUMMARY} />
          <RestrictedRoute component={ComingSoon} path={COMING_SOON} />
          <RestrictedRoute
            component={RequestWithdrawals}
            path={WITHDRAWALS_ADD}
          />
          <RestrictedRoute component={ReviewWithdrawals} path={WITHDRAWALS} />
          <RestrictedRoute component={CreateAndEditExit} path={EXITS_ADD} />
          <RestrictedRoute component={CreateAndEditExit} path={EXITS_EDIT} />
          <RestrictedRoute component={ListExits} path={EXITS} />
          <RestrictedRoute component={AddShareSplit} path={SHARE_SPLIT_ADD} />
          <RestrictedRoute
            component={DeploymentUpdate}
            path={ADD_DEPLOYMENT_UPDATE}
          />
          <RestrictedRoute
            component={AddExitStatement}
            path={ADD_EXIT_STATEMENT}
          />
          <RestrictedRoute
            component={ViewDeploymentUpdate}
            path={VIEW_DEPLOYMENT_UPDATE}
          />
          <RestrictedRoute
            component={ViewExitStatement}
            path={VIEW_EXIT_STATEMENT}
          />
          <RestrictedRoute
            component={InvestmentTransferList}
            path={INVESTMENT_TRANSFERS}
          />
          <RestrictedRoute
            component={AddInvestmentTransfer}
            path={ADD_INVESTMENT_TRANSFER}
          />
          <RestrictedRoute path={CHANGE_LOG} component={ChangeLog} />
          <RestrictedRoute component={Error404} />
        </Switch>
      </>
    );
  }
};

export default Routes;
