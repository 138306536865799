import React from 'react';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { numberToDisplayString } from 'utils/numbers';

import { Api } from 'further-types/investment';
import useStyles from './styles';
import { Tooltip } from 'components/Tooltip';
import { Box } from '@material-ui/core';
import { CompareArrows } from '@material-ui/icons';
import { createInvestmentEditRoute } from 'constants/routes';

type Exit = Api.GetInvestmentExitsResponse['exits'][number];

type Props = {
  exitedShareholdings: Array<Exit>;
  transferDate?: string;
};

const ShareholdingCompanyName: React.FC<{
  exit: Exit & { isTransferred?: boolean };
  transferDate?: string;
}> = ({ exit, transferDate }) => {
  const classes = useStyles();

  if (!exit.isTransfer && !exit.isTransferred) return <>{exit.company.name}</>;

  const tooltipText = exit.isTransferred
    ? `${exit.noOfShares} exited shares were transferred to  <a class="${
        classes.tooltipLink
      }" href="${createInvestmentEditRoute(
        exit.investmentId,
      )}" target="_blank">${exit.investorName}</a> on ${dateToLabel(
        exit.transferDate,
      )}.`
    : `${exit.noOfShares} exited shares were transferred from <a class="${
        classes.tooltipLink
      }" href="${createInvestmentEditRoute(
        exit.sourceInvestmentId,
      )}" target="_blank">${exit.sourceInvestorName}</a> on ${dateToLabel(
        transferDate,
      )}.`;

  return (
    <>
      <Tooltip
        title={<div dangerouslySetInnerHTML={{ __html: tooltipText }} />}
        interactive
      >
        <Box alignItems="center" display="flex" gridGap={4}>
          {exit.company.name} <CompareArrows className={classes.splitIcon} />
        </Box>
      </Tooltip>
    </>
  );
};

const ExitedShareholdings: React.FC<Props> = ({
  exitedShareholdings,
  transferDate,
}) => {
  const classes = useStyles();

  const combinedExits =
    exitedShareholdings?.reduce((combined, exit) => {
      return [
        ...combined,
        ...(exit.noOfShares ? [exit] : []),
        ...exit.transferredExits
          .filter(({ noOfShares }) => !!noOfShares)
          .map((transferredExit) => ({
            ...transferredExit,
            isTransferred: true,
          })),
      ];
    }, []) ?? [];

  const columns = [
    {
      label: 'Company',
      key: 'companyName',
      render: (elm: Exit) => (
        <ShareholdingCompanyName exit={elm} transferDate={transferDate} />
      ),
    },
    {
      label: 'Exit date',
      key: 'exitDate',
      render: (elm: Exit) => dateToLabel(elm.date),
    },
    {
      label: 'Investment date',
      key: 'investmentDate',
      render: (elm: Exit) => {
        return dateToLabel(elm.investmentDate);
      },
    },
    {
      label: 'Capital allocated',
      key: 'capitalAllocated',
      render: (elm: Exit) =>
        numberToCurrencyString(elm.currentCapitalAllocated, { fallback: '-' }),
    },
    {
      label: 'Shares exited',
      key: 'noOfShares',
      render: (elm: Exit) => numberToDisplayString(elm?.noOfShares),
    },
    {
      label: 'Initial share price',
      key: 'intitalSharePrice',
      render: (elm: Exit) => {
        return numberToCurrencyString(elm.initialSharePrice, {
          unlimitedDp: true,
        });
      },
    },
    {
      label: 'Exit share price',
      key: 'exitedSharePrice',
      render: (elm: Exit) => {
        const value = elm?.amendedExitSharePrice || elm?.sharePrice;
        return numberToCurrencyString(value, { unlimitedDp: true });
      },
    },
    {
      label: 'Gross exit value',
      key: 'valueBeforeDeductions',
      render: (elm: Exit) => numberToCurrencyString(elm.valueBeforeDeductions),
    },
    {
      label: 'Performance fee',
      key: 'performanceFee',
      render: (elm: Exit) => numberToCurrencyString(elm.performanceFeesCharged),
    },
    {
      label: 'Accrued fees',
      render: (elm: Exit) => numberToCurrencyString(elm.accruedFeesCharged),
    },
    {
      label: 'Net exit value',
      key: 'netExitValue',
      render: (elm: Exit) => numberToCurrencyString(elm.valueAfterDeductions),
    },
    {
      label: 'Gain/Loss',
      key: 'gainOrLoss',
      render: (elm: Exit) => {
        const netExitValue = elm.valueAfterDeductions;
        return numberToCurrencyString(
          netExitValue - elm.currentCapitalAllocated,
          { fallback: '-' },
        );
      },
    },
  ];

  const getTotalCapitalAllocatedAndExitValue = (dataArray: Array<Exit>) => {
    let totalCapitalAllocated = 0;
    let totalExitValue = 0;
    let grossExitValue = 0;
    let performanceFee = 0;
    let gainLoss = 0;
    let accruedFees = 0;

    dataArray.forEach((item) => {
      totalCapitalAllocated += item.currentCapitalAllocated;
      totalExitValue += item.valueAfterDeductions;
      performanceFee += item.performanceFeesCharged;
      grossExitValue += item.valueBeforeDeductions;
      gainLoss += item.valueAfterDeductions - item.currentCapitalAllocated;
      accruedFees += item.accruedFeesCharged;
    });

    return {
      totalCapitalAllocated: numberToCurrencyString(totalCapitalAllocated),
      totalExitValue: numberToCurrencyString(totalExitValue),
      grossExitValue: numberToCurrencyString(grossExitValue),
      performanceFee: numberToCurrencyString(performanceFee),
      accruedFees: numberToCurrencyString(accruedFees),
      gainLoss: numberToCurrencyString(gainLoss),
    };
  };

  const totals = getTotalCapitalAllocatedAndExitValue(exitedShareholdings);

  return (
    <Table
      columns={columns.map((col) => ({
        ...col,
        sort: false,
        getCellClassName: (exit: Exit & { isTransferred: boolean }) =>
          exit.isTransferred ? classes.inactiveCell : '',
      }))}
      tablebody={combinedExits}
      pagination={false}
      emptyMessage="No exited shareholdings found"
      variant="nohover"
      TableFooter={
        <tfoot>
          <tr className={classes.summaryRow}>
            <td className={classes.summaryValue} colSpan={3} />
            <td className={classes.summaryValue}>
              {totals.totalCapitalAllocated}
            </td>
            <td className={classes.summaryValue} colSpan={3} />
            <td className={classes.summaryValue}>{totals.grossExitValue}</td>
            <td className={classes.summaryValue}>{totals.performanceFee}</td>
            <td className={classes.summaryValue}>{totals.accruedFees}</td>
            <td className={classes.summaryValue}>{totals.totalExitValue}</td>
            <td className={classes.summaryValue}>{totals.gainLoss}</td>
          </tr>
        </tfoot>
      }
    />
  );
};

export default ExitedShareholdings;
