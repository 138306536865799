//Investor Module
export const GET_INVESTOR_DETAIL = 'GET_INVESTOR_DETAIL';
export const RESET_INVESTOR = 'RESET_INVESTOR';
export const UPDATE_INVESTOR_QUESTION = 'UPDATE_INVESTOR_QUESTION';

//users
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const GET_USER_LIST = 'GET_USER_LIST';
export const RESET_USER = 'RESET_USER';

//subscription
export const GET_SUBSCRIPTION_LIST = 'GET_SUBSCRIPTION_LIST';
export const RESET_SUBSCRIPTION_LIST = 'RESET_SUBSCRIPTION_LIST';

// Withdrawals
export const SET_WITHDRAWAL_REQUEST_LIST = 'SET_WITHDRAWAL_REQUEST_LIST';
