export const RequestSourceToLabelMap = {
  INVESTOR: 'Client request',
  ADMIN_SITE: 'Fund request',
};

export const StatusToLabelMap = {
  REQUESTED: 'Requested',
  EXPORTED_TO_CUSTODIAN: 'Exported to custodian',
  REJECTED: 'Rejected',
};

export const Status = {
  REQUESTED: 'REQUESTED',
  EXPORTED_TO_CUSTODIAN: 'EXPORTED_TO_CUSTODIAN',
  REJECTED: 'REJECTED',
};
