import { Controller } from 'react-hook-form';
import Switch from 'components/Switch';
import FieldRow from 'components/FormElements/FieldRow';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import {
  CompanyBreakdownDisplayMode,
  TaxReliefDisplayMode,
} from 'further-types/firm';
import { TileType } from 'further-types/firm';

const ConfigurableTilesDropDown = ({ onChange, name, errors, ...rest }) => (
  <GridContainer>
    <Grid item xs={12} sm={6}>
      <AppSelectBox
        data={[
          {
            value: TileType.MULTIPLE,
            label: 'Multiple on Investment',
          },
          {
            value: TileType.MOIC,
            label: 'MOIC',
          },
          {
            value: TileType.SUBSCRIPTIONS,
            label: 'Subscriptions',
          },
          {
            value: TileType.DISTRIBUTABLE,
            label: 'Distributable balance',
          },
        ]}
        valueKey="value"
        labelKey="label"
        onChange={(e: { target: { value: any } }) => onChange(e.target.value)}
        error={!!errors.whiteLabelConfig?.[name]}
        helperText={errors.whiteLabelConfig?.[name]?.message}
        {...rest}
      />
    </Grid>
  </GridContainer>
);

const InvestorPortalSettings = ({ control, firm, errors, classes }) => (
  <>
    <FieldRow
      title={'Show multiple on investment'}
      centerTitle
      tooltipText={
        'In the tables in the investor portal, show or hide the "Multiple on investment" column.'
      }
    >
      <Controller
        name="whiteLabelConfig.showMultipleOnInvestment"
        defaultValue={
          firm?.data?.whiteLabelConfig?.showMultipleOnInvestment === false
            ? false
            : true
        }
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <>
            <span className={classes.switchLabel}>No</span>
            <Switch {...rest} inputRef={ref} checked={value} />
            <span className={classes.switchLabel}>Yes</span>
          </>
        )}
      />
    </FieldRow>
    <FieldRow
      title="Show MOIC"
      centerTitle
      tooltipText="In the tables in the investor portal, show or hide the “Multiple on Invested Capital” column."
    >
      <Controller
        name="whiteLabelConfig.showMultipleOnInvestedCapital"
        defaultValue={
          firm?.data?.whiteLabelConfig?.showMultipleOnInvestedCapital
        }
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <>
            <span className={classes.switchLabel}>No</span>
            <Switch {...rest} inputRef={ref} checked={value} />
            <span className={classes.switchLabel}>Yes</span>
          </>
        )}
      />
    </FieldRow>
    <FieldRow title="Arrange syndicates" centerTitle>
      <Controller
        name="whiteLabelConfig.syndicatesArrangement"
        defaultValue={
          firm?.data?.whiteLabelConfig?.syndicatesArrangement ?? 'taxYear'
        }
        control={control}
        render={({ field: { onChange, ...rest } }) => (
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppSelectBox
                data={[
                  {
                    value: 'taxYear',
                    label: 'By tax year',
                  },
                  {
                    value: 'calendarYear',
                    label: 'By calendar year',
                  },
                  {
                    value: 'all',
                    label: 'All together',
                  },
                ]}
                valueKey="value"
                labelKey="label"
                onChange={(e: { target: { value: any } }) =>
                  onChange(e.target.value)
                }
                error={!!errors.whiteLabelConfig?.syndicatesArrangement}
                helperText={
                  errors.whiteLabelConfig?.syndicatesArrangement?.message
                }
                {...rest}
              />
            </Grid>
          </GridContainer>
        )}
      />
    </FieldRow>
    <FieldRow title="Show menu investment link" centerTitle>
      <Controller
        name="whiteLabelConfig.showInvestmentLink"
        defaultValue={firm?.data?.whiteLabelConfig?.showInvestmentLink}
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <>
            <span className={classes.switchLabel}>No</span>
            <Switch {...rest} inputRef={ref} checked={value} />
            <span className={classes.switchLabel}>Yes</span>
          </>
        )}
      />
    </FieldRow>
    <FieldRow
      title="Legal entity KYB"
      centerTitle
      tooltipText="If KYB is switched on, the system will direct legal entity investors through Further’s digitally enabled KYB service. This service is charged on a per use basis. If switched off, you must complete manual KYB offline for each investor and provide KYB information to Further before your legal entity investors can proceed to make their subscription through Further."
    >
      <Controller
        name="whiteLabelConfig.enableKyb"
        defaultValue={firm?.data?.whiteLabelConfig?.enableKyb ?? true}
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <>
            <span className={classes.switchLabel}>Off</span>
            <Switch {...rest} inputRef={ref} checked={value} />
            <span className={classes.switchLabel}>On</span>
          </>
        )}
      />
    </FieldRow>

    <FieldRow title="Allow portal cash withdrawals" centerTitle>
      <Controller
        name="whiteLabelConfig.allowPortalCashWithdrawals"
        defaultValue={
          firm?.data?.whiteLabelConfig?.allowPortalCashWithdrawals === undefined //want to default to true, so if not set will set value to true
            ? true
            : firm?.data?.whiteLabelConfig?.allowPortalCashWithdrawals
        }
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <>
            <span className={classes.switchLabel}>No</span>
            <Switch {...rest} inputRef={ref} checked={value} />
            <span className={classes.switchLabel}>Yes</span>
          </>
        )}
      />
    </FieldRow>

    <FieldRow
      title="Top centre portal tile"
      centerTitle
      tooltipText={
        'This selection chooses what is displayed on the top centre tile of the investor "My portal" page.'
      }
    >
      <Controller
        name="whiteLabelConfig.configurableTile1"
        defaultValue={
          firm?.data?.whiteLabelConfig?.configurableTile1 ?? TileType.MULTIPLE
        }
        control={control}
        render={({ field: { onChange, ...rest } }) => (
          <ConfigurableTilesDropDown
            onChange={onChange}
            errors={errors}
            name={'configurableTile1'}
            {...rest}
          />
        )}
      />
    </FieldRow>

    <FieldRow
      title="Top right portal tile"
      centerTitle
      tooltipText={
        'This selection chooses what is displayed on the top right tile of the investor "My portal" page.'
      }
    >
      <Controller
        name="whiteLabelConfig.configurableTile2"
        defaultValue={
          firm?.data?.whiteLabelConfig?.configurableTile2 ??
          TileType.SUBSCRIPTIONS
        }
        control={control}
        render={({ field: { onChange, ...rest } }) => (
          <ConfigurableTilesDropDown
            onChange={onChange}
            errors={errors}
            name={'configurableTile2'}
            {...rest}
          />
        )}
      />
    </FieldRow>

    <FieldRow title="Hide cash balance before" centerTitle>
      <Controller
        name="whiteLabelConfig.hideCashBalanceBefore"
        defaultValue={
          firm?.data?.whiteLabelConfig?.hideCashBalanceBefore
            ? new Date(firm?.data?.whiteLabelConfig?.hideCashBalanceBefore)
            : null
        }
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppDatePicker
                {...rest}
                value={value ?? null}
                onChange={(e) => onChange(e?.toDate() ?? null)}
                clearable
              />
            </Grid>
          </GridContainer>
        )}
      />
      <p className={classes.errorText}>
        {errors.whiteLabelConfig?.hideCashBalanceBefore?.message}
      </p>
    </FieldRow>
    <FieldRow
      title="Tax relief displayed"
      centerTitle
      tooltipText={
        <div>
          This option changes the income tax relief figures shown in tables on
          the portal. <i>Actual</i> displays income tax relief for all holdings
          where S/EIS certificates have been processed, accurate to investment
          amounts. <i>Forecast</i> displays an estimated tax relief, based on an
          individual’s initial investment multiplied by the ‘Income tax relief’
          setting for the applicable tranche (adjustable in the Tranches
          section)
        </div>
      }
    >
      <Controller
        name="whiteLabelConfig.taxReliefDisplayMode"
        defaultValue={
          firm?.data?.whiteLabelConfig?.taxReliefDisplayMode !==
          TaxReliefDisplayMode.Forecast
        }
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <>
            <span className={classes.switchLabel}>Forecast</span>
            <Switch {...rest} inputRef={ref} checked={value} />
            <span className={classes.switchLabel}>Actual</span>
          </>
        )}
      />
    </FieldRow>
    <FieldRow title="Company-by-company breakdown display" centerTitle>
      <Controller
        name="whiteLabelConfig.companyBreakdownDisplayMode"
        defaultValue={
          firm?.data?.whiteLabelConfig?.companyBreakdownDisplayMode ===
          CompanyBreakdownDisplayMode.UnderSummaryTable
        }
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <>
            <span className={classes.switchLabel}>In separate tab</span>
            <Switch {...rest} inputRef={ref} checked={value} />
            <span className={classes.switchLabel}>
              Under subscription summary table
            </span>
          </>
        )}
      />
    </FieldRow>
  </>
);

export default InvestorPortalSettings;
