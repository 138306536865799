import Grid, { GridProps } from '@material-ui/core/Grid';
import { PropsWithChildren } from 'react';

const GridContainer: React.FC<PropsWithChildren<GridProps>> = ({
  children,
  ...rest
}) => {
  return (
    <Grid container spacing={6} {...rest}>
      {children}
    </Grid>
  );
};

export default GridContainer;
