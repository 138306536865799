import React from 'react';
import { Button } from '@material-ui/core';
import { rejectWithdrawal } from '@redux/actions/Withdrawals';
import { useDispatch } from 'react-redux';
import { Status } from 'constants/withdrawalProperties';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';

const RejectButton = ({ withdrawal = {}, onRejectSuccess }) => {
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();

  const handleClick = async () => {
    await handleApiRequest(async (success) => {
      await dispatch(rejectWithdrawal(withdrawal._id));
      success('Withdrawal rejected');
      onRejectSuccess();
    });
  };

  if (
    [Status.REJECTED, Status.EXPORTED_TO_CUSTODIAN].includes(withdrawal.status)
  )
    return null;

  return (
    <Button onClick={handleClick} color="secondary" size="small">
      Reject
    </Button>
  );
};

export default RejectButton;
