import { useMutation } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';

const useResendInvite = ({ onResendInviteSuccess }) => {
  const { error } = useNotification();

  return useMutation({
    mutationFn: async (investorId) => {
      const { data: response } = await api.post(
        `investor/resend-platform-invite/${investorId}`,
      );
      return response;
    },
    onError: () => {
      error('Error when trying to resend platform invite to Investor');
    },
    onSuccess: async () => {
      onResendInviteSuccess?.();
    },
  });
};

const useResendInvites = ({ onResendInvitesSuccess }) => {
  const { error } = useNotification();

  return useMutation({
    mutationFn: async (investorsIds) => {
      const { data: response } = await api.post(
        `investor/resend-platform-invites`,
        {
          investorsIds,
        },
      );
      return response;
    },
    onError: () => {
      error('Error when trying to resend platform invites');
    },
    onSuccess: async () => {
      onResendInvitesSuccess?.();
    },
  });
};

const useInvestorPlatformInvites = (params = {}) => {
  return {
    resendInvite: useResendInvite(params),
    resendInvites: useResendInvites(params),
  };
};

export default useInvestorPlatformInvites;
