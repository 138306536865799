import {
  INVESTOR_REPORTING,
  createInvestorReportingEditSuccessRoute,
} from 'constants/routes';
import { z } from 'zod';
import { pick, flatten } from 'lodash';
import { useHistory } from 'react-router-dom';
import { status } from 'constants/investorReporting';
import { useInvestorReporting } from 'hooks/data/investorReporting/useInvestorReporting';
import { useNotification } from 'hooks/ui/useNotification';

export const stripTags = (value) =>
  value
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/gi, '')
    .trim();

const updateCompanyFields = [
  'updatedSharePrice',
  'description',
  'updateSummary',
  'media',
  'mediaType',
  'additionalMetrics',
  'additionalTitle',
  'additionalValue',
  'attachments',
  'draftDescription',
  'copyFromCompanies',
];

const fundUpdateFields = [
  'title',
  'provideCommentary',
  'shortDescription',
  'commentary',
  'accountManagerId',
  'role',
  'copyFund',
];

const titleValidation = z.string().min(1, 'Please enter update title.');
const roleValidation = z.string().min(1, 'Please enter manager role.');
const commentaryValidation = z
  .string()
  .min(1, 'Please enter fund manager commentary.');
const managerIdValidation = z
  .string()
  .min(1, 'Please select a manager. Add a new manager in the Firm settings.');
const shortDescriptionValidation = z
  .string()
  .min(1, 'Please enter short description.')
  .transform(stripTags)
  .pipe(z.string().max(220, 'Only 220 characters are allowed.'));

const managerOverviewSchema = z.intersection(
  z.discriminatedUnion('managerOverViewProvideCommentary', [
    z.object({
      managerOverViewProvideCommentary: z.literal(true),
      managerOverViewCommentary: commentaryValidation,
      managerOverViewAccountManagerId: managerIdValidation,
      managerOverViewRole: roleValidation,
    }),
    z.object({
      managerOverViewProvideCommentary: z.literal(false),
    }),
  ]),
  z.object({
    managerOverViewTitle: titleValidation,
    managerOverViewShortDescription: shortDescriptionValidation,
  }),
);

export const fundInformationSchema = z.object({
  submitDate: z
    .any()
    .transform((value) => value?.format('DD/MM/YYYY'))
    .pipe(z.string().min(1, 'Please enter report date.')),
  fundInformation: z.array(
    z
      .discriminatedUnion('copyFund', [
        z.object({
          copyFund: z.literal(true),
        }),
        z.object({
          copyFund: z.literal(false),
          title: titleValidation,
          shortDescription: shortDescriptionValidation,
        }),
      ])
      .and(
        z.discriminatedUnion('provideCommentary', [
          z.object({ provideCommentary: z.literal(false) }),
          z.object({
            provideCommentary: z.literal(true),
            commentary: commentaryValidation,
            accountManagerId: managerIdValidation,
            role: roleValidation,
          }),
        ]),
      ),
  ),
  managerOverview: managerOverviewSchema,
});

export const companyInformationSchema = z.object({
  notifyInvestors: z.boolean(),
  companyInformation: z.array(
    z.discriminatedUnion('copiedFromAnotherCompany', [
      z.object({
        copiedFromAnotherCompany: z.literal(true),
        updatedSharePrice: z.union([
          z
            .string()
            .regex(/^[0-9]*\.?(.?:|[0-9])*$/, 'Invalid share price.')
            .optional(),
          z.null(),
        ]),
      }),
      z.object({
        copiedFromAnotherCompany: z.literal(false),
        description: z
          .string()
          .transform((value) => value.replace(/(<([^>]+)>)/gi, '').trim())
          .pipe(z.string().min(1, 'Please enter company description.')),
        updatedSharePrice: z.union([
          z
            .string()
            .regex(/^[0-9]*\.?(.?:|[0-9])*$/, 'Invalid share price')
            .optional(),
          z.null(),
        ]),
        updateSummary: z.string().optional(),
        media: z.any().optional(),
        additionalTitle: z.string().optional(),
        additionalValue: z.string().optional(),
        additionalMetrics: z
          .array(
            z.object({
              key: z.string().min(1, 'Please enter key.'),
              value: z.string().min(1, 'Please enter value.'),
            }),
          )
          .optional(),
      }),
    ]),
  ),
});

const getFieldsWithErrors = (errors) => {
  const errorValues = Object.values(errors);

  if (errors && 'ref' in errors) {
    return errors.ref?.focus ? errors.ref.focus : errors.ref?.name ?? null;
  } else {
    return flatten(
      errorValues.map((errorValue) => getFieldsWithErrors(errorValue)),
    );
  }
};

const scrollToFirstError = (errors) => {
  const fieldsWithErrors = flatten(getFieldsWithErrors(errors)).filter(
    (error) => !!error,
  );
  if (!fieldsWithErrors.length) return;

  if (typeof fieldsWithErrors[0] === 'function') {
    fieldsWithErrors[0]();
  } else {
    const firstErrorElement = document.getElementById(fieldsWithErrors[0]);
    firstErrorElement?.scrollIntoView({ behavior: `smooth`, block: 'center' });
  }
};

const useInvestorReportingForm = () => {
  const history = useHistory();
  const notification = useNotification();
  const { createInvestorReport, updateInvestorReport } = useInvestorReporting();

  const submitForm = async (formContext, submitStatus, isQuickSave) => {
    const {
      investorReport,
      setInvestorReport,
      firmId,
      companyInformation,
      submitDate,
      managerOverview,
      fundInformation,
      notifyInvestors,
      allActiveFundIds,
    } = formContext;

    if (investorReport?._id) {
      if (!fundUpdateFields.includes('_id')) {
        fundUpdateFields.push('_id');
      }
      if (!updateCompanyFields.includes('_id')) {
        updateCompanyFields.push('_id');
      }
    }

    const companyData = companyInformation?.map((item) => {
      item.media = item.media
        ?.filter((media) => media && media.key === item.mediaType)
        .map(({ url, title, key }) => ({ url, title, key }));
      item.attachments = item.attachments.map(({ url, title }) => ({
        url,
        title,
      }));

      return {
        companyId: item.companyId || item._id, // uses companyId from the previous InvesteeUpdate record, or the _id from the company
        ...pick(item, updateCompanyFields),
      };
    });

    const fundData = fundInformation?.map((item) => ({
      fundId: item._id,
      copyFund: item.copyFund,
      ...(investorReport?._id ? { _id: item._id } : {}),
      ...(!item.copyFund ? pick(item, fundUpdateFields) : {}),
    }));
    const payload = {
      status: submitStatus,
      firmId,
      fundInformation: fundData,
      allActiveFundIds,
      companyInformation: companyData,
      ...managerOverview,
      notifyInvestors,
      submitDate: submitDate.format('DD/MM/YYYY'),
      skipExtendedValidation: isQuickSave,
    };
    try {
      const response = investorReport?._id
        ? await updateInvestorReport.mutateAsync({
            _id: investorReport?._id,
            notifyInvestors,
            ...payload,
          })
        : await createInvestorReport.mutateAsync(payload);

      if (response.statusCode === 200) {
        const redirectMap = {
          [status.DRAFT]: INVESTOR_REPORTING,
          [status.SUBMITTED]: createInvestorReportingEditSuccessRoute(
            response.data.investorReporting._id,
          ),
        };

        setInvestorReport(response.data.investorReporting);

        if (submitStatus in redirectMap && !isQuickSave) {
          history.push(redirectMap[submitStatus]);
        }
      }
    } catch (err) {
      notification.error(err?.response?.data?.responseMsg);
    }
  };

  return {
    submitForm,
    scrollToFirstError,
  };
};

export default useInvestorReportingForm;
