import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { GetExitStatementResponse } from 'further-types/exit';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';

export type FormData = {
  title?: string;
  summary?: string;
  letter?: string;
  accountManager?: { name?: string; _id?: string };
  role?: string;
  notes?: string;
};

type Params = {
  id?: string;
  exitId?: string;
  exitAmendmentId?: string;
  firmId?: string;
};

const useExitStatement = ({ id, exitId, exitAmendmentId, firmId }: Params) => {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();

  const createExitStatement = useMutation({
    useErrorBoundary: false,
    mutationFn: async (data: FormData) => {
      const { data: response } = await api.put<ApiResponse<unknown>>(
        `exit/${exitId}/statement`,
        data,
        { params: { exitAmendmentId, firmId } },
      );

      return response;
    },
    onSuccess: async () => {
      notification.success('Exit statement created successfully');

      await queryClient.invalidateQueries({ queryKey: ['exits'] });
      await queryClient.invalidateQueries({ queryKey: ['investor-reporting'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const exitStatement = useQuery({
    queryKey: ['exit-statement', id],
    queryFn: async () => {
      const { data: result } = await api.get<
        ApiResponse<GetExitStatementResponse>
      >(`exit/statement/${id}`);
      return result.data;
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
    enabled: !!id,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  return {
    createExitStatement,
    exitStatement,
  };
};

export default useExitStatement;
