import { type as feeConst } from 'constants/typeConstant';

export const displayFeeAccountingType = (feeAccounting) => {
  switch (feeAccounting) {
    case feeConst.feeAccounting.accrued:
      return 'Accrued';
    case feeConst.feeAccounting.chargedToInvestor:
      return 'Charged to investor';
    default:
      return '';
  }
};
