import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  Button,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CardHeader from 'components/CardHeader';
import InvestmentDetails from './InvestmentDetails';
import CurrentShareholdings from './CurrentShareholdings';
import ExitedShareholdings from './ExitedShareholdings';
import CoreFees from './CoreFees';
import FutureFees from './FutureFees';
import PaymentsTable from './PaymentsTable';
import Dividend from './Dividend';
import InterestPayments from './InterestPayments';
import InvestmentBalanceAndValue from './InvestmentBalanceAndValue';
import { useDisclosure } from 'further-ui/hooks';
import { useInvestment } from 'hooks/data/investment/useInvestments';
import InvestmentWarningModal from './InvestmentWarningModal';
import { usePayments } from 'hooks/data/investment/usePayments';
import { createAddInvestmentTransferRoute } from 'constants/routes';
import useInvestmentDetails from 'hooks/data/investment/useInvestmentDetails';
import { Tooltip } from 'components/Tooltip';
import Notes from 'components/Notes';
import { NoteRelation } from 'further-types/notes';

const useStyles = makeStyles(() => ({
  gridMargin: {
    margin: '1rem 0',
  },
  divider: {
    marginBottom: 30,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noMargin: {
    margin: 0,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard' },
  { label: 'Investments', link: '/investment' },
  { label: 'Update Investment', link: '/', isActive: true },
];

const UpdateInvestment = ({ history }) => {
  // misc hooks
  const classes = useStyles();
  const { id } = useParams();
  const {
    isOpen: shouldDisplayPrompt,
    onOpen: displayPrompt,
    onClose: hidePrompt,
  } = useDisclosure();
  // state hooks
  const [investmentBalance, setInvestmentBalance] = useState({});
  const [investmentDetails, setInvestmentDetails] = useState({});
  const [initialInvestmentAmount, setInitialInvestmentAmount] = useState(0);
  //data hooks
  const investment = useInvestment(id);
  const { fetchPayments, markPaymentReceived } = usePayments(id);
  const { getFees, getExits, getShareholdings } = useInvestmentDetails();
  const { data: fees } = getFees(id);
  const { data: exits } = getExits(id);
  const { data: currentShareholdings } = getShareholdings(id);

  useEffect(
    function fetchInvestment() {
      const data = investment.fetch.data;
      if (data) {
        const { investmentDate, investmentBalance } = data;

        // NB.there's little point in setting all these details in state
        // now since we can just access them from the data object.
        setInvestmentDetails({
          investorId: data.investorId?._id,
          fullName: data.investorId?.fullName,
          fundId: data.fundId?._id,
          investmentAmount: data.investmentAmount,
          isPaymentReceived: data.isPaymentReceived,
          investmentDate,
          firmName: data.firmId?.firmName,
          fundName: data.fundId?.fundName,
          showEisBtn: data.showEisBtn,
          adviser: data.adviserId,
          adviserFee: data.adviserFee,
          commissionFee: data.commissionFee,
          fundStatus: data.fundId?.fundStatus,
          externalInvestmentId: data.externalInvestmentId,
          advisedClientCertifiedAt: data?.advisedClientCertifiedAt,
          id,
          isAllocationExists: data?.isAllocationExists,
        });

        setInvestmentBalance(investmentBalance);
        setInitialInvestmentAmount(data.investmentAmount);
        fetchPayments.refetch();
      }
    },
    [id, investment.fetch.data],
  );

  useEffect(
    function refreshInvestment() {
      if (investment.update.data) {
        investment.fetch.refetch();
      }
    },
    [investment.update.data],
  );

  useEffect(
    function handlePaymentReceivedSuccess() {
      if (markPaymentReceived.isSuccess) {
        setInvestmentDetails((investmentDetails) => ({
          ...investmentDetails,
          isPaymentReceived: !!markPaymentReceived.data.isPaymentReceived,
        }));
      }
    },
    [markPaymentReceived.isSuccess],
  );

  const clickMarkPaymentReceived = (id) => markPaymentReceived.mutate(id);

  const handleInvestmentUpdate = async () => {
    hidePrompt();

    const values = {
      id,
      fundId: investmentDetails.fundId,
      adviserId: investmentDetails.adviser?._id || null,
      externalInvestmentId: investmentDetails.externalInvestmentId,
    };

    if (initialInvestmentAmount !== investmentDetails.investmentAmount) {
      values.investmentAmount = investmentDetails.investmentAmount;
    }
    investment.update.mutate(values);
  };

  const handleSave = async () => {
    if (investmentDetails.investmentAmount !== initialInvestmentAmount) {
      displayPrompt();
    } else void handleInvestmentUpdate();
  };

  const { interest, dividends } =
    investmentBalance?.realisedValue?.sourcesOfRealisedValue ?? {};

  const isTransferDisabled =
    !!investmentBalance?.deploymentSummary?.heldByFundAwaitingInvestment;
  const transferDisabledTooltipText =
    "To transfer any holdings from this investment, please first freeze the investment and withdraw all this investment's uninvested capital.";

  const onTransfer = () =>
    isTransferDisabled
      ? null
      : history.push(
          createAddInvestmentTransferRoute(
            investment.fetch.data?.investorId._id ?? '',
          ),
        );

  return (
    <>
      <PageContainer heading="Update Investment" breadcrumbs={breadcrumbs}>
        <InvestmentWarningModal
          isOpen={shouldDisplayPrompt}
          onConfirm={handleInvestmentUpdate}
          onCancel={hidePrompt}
        />
        <CmtCard>
          <CmtCardContent>
            <InvestmentDetails
              shareholdings={currentShareholdings ?? []}
              investmentDetails={investmentDetails}
              setInvestmentDetails={setInvestmentDetails}
              isInvestmentDetailsLoaded={
                investment.fetch.isSuccess &&
                Object.keys(investmentDetails).length > 0
              }
              frozenOn={investment.fetch.data?.freezeStartDate}
              fees={investment.fetch.data?.fees ?? []}
              hasFrozenFundsMoved={!!investment.fetch.data?.hasFrozenFundsMoved}
              availableFunds={
                investmentBalance?.deploymentSummary
                  ?.heldByFundAwaitingInvestment ?? 0
              }
              transferredInvestments={
                investment.fetch.data?.transferredInvestments ?? []
              }
            />
            <GridContainer>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
                <Grid container alignItems="center" className={classes.divider}>
                  <Grid item xs={12} sm>
                    <CardHeader
                      title="Current shareholdings"
                      className={classes.noMargin}
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Tooltip
                      title={
                        isTransferDisabled ? transferDisabledTooltipText : ''
                      }
                    >
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={onTransfer}
                          disabled={isTransferDisabled}
                        >
                          Transfer holdings
                        </Button>
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>

                <CurrentShareholdings
                  currentShareholdings={currentShareholdings ?? []}
                  transferDate={investment.fetch.data?.transferDate}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridMargin}>
                <CardHeader title="Exited shareholdings" />
                <ExitedShareholdings
                  exitedShareholdings={exits?.exits ?? []}
                  transferDate={investment.fetch.data?.transferDate}
                />
              </Grid>
              <Grid item xs={12}>
                <CardHeader title="Dividends" />
                <Dividend
                  dividends={dividends?.items}
                  firmName={investmentDetails.firmName}
                  fundName={investmentDetails.fundName}
                />
              </Grid>

              <Grid item xs={12}>
                <InterestPayments interestPayments={interest?.items} />
              </Grid>

              <Grid item xs={12}>
                <CardHeader title="Initial and recurring fees" />
                <CoreFees fees={fees?.fees ?? []} />
              </Grid>
              <Grid item xs={12}>
                <FutureFees fees={fees?.futureFees ?? []} />
              </Grid>

              <Grid item xs={12}>
                <InvestmentBalanceAndValue
                  investmentBalance={investmentBalance}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
                <PaymentsTable
                  loading={fetchPayments.isLoading}
                  markPaymentReceived={clickMarkPaymentReceived}
                  payments={fetchPayments.data ?? []}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={investment.update.isLoading}
                  >
                    Update
                  </Button>
                  {investment.update.isLoading && (
                    <CircularProgress
                      style={{ marginLeft: '1rem' }}
                      size={15}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
                <Notes relationId={id} noteRelation={NoteRelation.Investment} />
              </Grid>
            </GridContainer>
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </>
  );
};

export default UpdateInvestment;
