import React from 'react';
import clsx from 'clsx';
import { Typography, Divider, makeStyles } from '@material-ui/core';
import { numberToCurrencyString, renderMultiple } from 'further-ui/utils';
import CardHeader from 'components/CardHeader';
import { Tooltip } from 'components/Tooltip';
import { InfoOutlined } from '@material-ui/icons';

const useRowStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '1rem',
  },
  divider: {
    backgroundColor: '#EDEDED',
    margin: '2rem 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #EDEDED',
    padding: '1rem 0.75rem 1rem 0',
    position: 'relative',
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
  summaryRow: {
    backgroundColor: '#FBFBFA',
    padding: '0.5rem 0.75rem 0.5rem 0',
  },
  postSummaryRow: {
    backgroundColor: '#FBFBFA',
    padding: '1.25rem 0.75rem 1.25rem 0',
  },
  largeSummaryRow: {
    backgroundColor: 'rgba(196, 196, 196, 0.4)',
    padding: '1.25rem 0.75rem 1.25rem 0',
  },
  labelText: {
    fontWeight: 'normal',
    padding: 0,
    margin: '0 0 0 0.75rem',
    display: 'flex',
    alignItems: 'center',
  },
  valueText: {
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  normal: {
    fontWeight: 'normal',
  },
  italic: {
    fontStyle: 'italic',
  },
  sectionTitle: {
    fontWeight: 'bold',
    margin: '1.5rem 0 0.5rem 0.75rem',
    color: '#656565',
  },
}));

const useRowAccentStyles = makeStyles((theme) => ({
  accent: {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: '25%',
      height: '50%',
      width: '4px',
      backgroundColor: (props) => props.accent || 'transparent',
    },
  },
}));

const InvestmentBalanceAndValue = ({ investmentBalance }) => {
  const {
    deploymentSummary = {},
    realisedValue = {},
    unrealisedValue = {},
    valueChangesOnInitialInvestment = {},
    investmentValue,
  } = investmentBalance;

  const rowClasses = useRowStyles();

  const Row = ({
    label,
    value,
    bold,
    subtraction,
    accent,
    noBorderBottom,
    tooltipText,
  }) => {
    const labelClassname = clsx(
      rowClasses.labelText,
      bold && rowClasses.bold,
      subtraction && rowClasses.italic,
    );
    const accentClasses = useRowAccentStyles({ accent });
    const rowClassname = clsx(
      rowClasses.row,
      accentClasses.accent,
      noBorderBottom && rowClasses.noBorderBottom,
    );

    return (
      <div className={rowClassname}>
        <Typography className={labelClassname} component="h4" variant="inherit">
          {label}
          {tooltipText && (
            <Tooltip title={tooltipText}>
              <InfoOutlined style={{ marginLeft: '0.5rem', fontSize: 18 }} />
            </Tooltip>
          )}
        </Typography>
        <Typography
          className={rowClasses.valueText}
          component="h4"
          variant="inherit"
        >
          {subtraction
            ? `(${numberToCurrencyString(value)})`
            : numberToCurrencyString(value)}
        </Typography>
      </div>
    );
  };

  const SummaryRow = ({ label, value, large }) => {
    const extraRowClass = large
      ? rowClasses.largeSummaryRow
      : rowClasses.summaryRow;

    return (
      <div className={clsx(rowClasses.row, extraRowClass)}>
        <Typography
          className={clsx(rowClasses.labelText, rowClasses.bold)}
          component="h4"
          variant="inherit"
        >
          {label}
        </Typography>
        <Typography
          className={rowClasses.valueText}
          component="h4"
          variant="inherit"
        >
          {numberToCurrencyString(value)}
        </Typography>
      </div>
    );
  };

  const PostSummaryRow = ({ label, value }) => {
    return (
      <div className={clsx(rowClasses.row, rowClasses.postSummaryRow)}>
        <Typography
          className={rowClasses.labelText}
          component="h4"
          variant="inherit"
        >
          {label}
        </Typography>
        <Typography
          className={clsx(rowClasses.valueText, rowClasses.normal)}
          component="h4"
          variant="inherit"
        >
          {renderMultiple(value)}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <CardHeader
        title="Initial investment balance"
        className={rowClasses.header}
      />
      <Row
        label="Initial investment balance"
        value={deploymentSummary.initialInvestmentAmount}
      />
      <Row
        label="Amount invested"
        value={deploymentSummary.purchasePriceOfAllShareholdings}
      />
      <Row
        label="Cash fees on initial investment"
        value={deploymentSummary.cashFeesOnInitialInvestment}
      />
      <Row
        label="Undeployed initial investment"
        value={deploymentSummary.undeployedInitialInvestment}
      />
      <Row
        label="Reserved for future cash fees"
        value={deploymentSummary.reservedForFutureCashFee}
        bold
        accent="#E07973"
      />
      <Row
        label="Transferred to investor cash balance"
        value={deploymentSummary.transferredToInvestorCashBalance}
        bold
        accent="#7BCDEF"
      />
      <Row
        label="Held by fund awaiting investment"
        value={deploymentSummary.heldByFundAwaitingInvestment}
        bold
        accent="#56B26C"
        noBorderBottom
      />
      <Divider className={rowClasses.divider} />
      <CardHeader title="Investment value" className={rowClasses.header} />
      <Typography className={rowClasses.sectionTitle} component="h4">
        Realised return
      </Typography>
      <Row
        label="Total exit value"
        value={realisedValue.sourcesOfRealisedValue?.exitProceeds.total}
      />
      <Row
        label="Dividends"
        value={realisedValue.sourcesOfRealisedValue?.dividends.total}
      />
      <Row
        label="Interest"
        value={realisedValue.sourcesOfRealisedValue?.interest.total}
      />
      <Row
        label="Undeployed initial investment transferred to investor cash balance"
        value={realisedValue.sourcesOfRealisedValue?.residualCash.total}
      />
      <Row
        label="Less accrued fees charged to investor"
        value={
          realisedValue.sourcesOfRealisedValue?.accruedFeesChargedToInvestor
            .total
        }
        subtraction
      />
      <SummaryRow label="Total realised return" value={realisedValue.total} />
      <Typography className={rowClasses.sectionTitle} component="h4">
        Unrealised return
      </Typography>
      <Row
        label="Current value of unexited shareholdings"
        value={unrealisedValue.valueOfAllShareholdings}
      />
      <Row
        label="Undeployed initial investment held by fund"
        value={deploymentSummary.undeployedInitialInvestmentHeldByFund}
      />
      <Row
        label="Accrued fees not yet charged"
        value={unrealisedValue.accruedFeesNotYetCharged}
        subtraction
        tooltipText="This fee figure is presented here for information but is not included in the total unrealised return figure, as the fees are contingent until charged."
      />
      <SummaryRow
        label="Total unrealised return"
        value={unrealisedValue.total}
      />
      <SummaryRow
        large
        label="Total investment value"
        value={investmentValue}
      />
      <PostSummaryRow
        label="Multiple on investment"
        value={valueChangesOnInitialInvestment.multipleOnInvestment}
      />
      <PostSummaryRow
        label="Multiple on invested capital"
        value={valueChangesOnInitialInvestment.multipleOnInvestedCapital}
      />
    </>
  );
};

export default InvestmentBalanceAndValue;
