import { Divider } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import FieldRow from 'components/FormElements/FieldRow';

const Summary = ({
  data,
  classes,
  selectedTranchesOrCompanies,
  isTrancheSelected,
}) => {
  return (
    <>
      <GridContainer item md={11}>
        <FieldRow title={'Update type'} centerTitle>
          Custom update
        </FieldRow>

        <FieldRow
          title={isTrancheSelected ? 'Tranches' : 'Company'}
          centerTitle
        >
          {selectedTranchesOrCompanies?.join(', ') || '-'}
        </FieldRow>
        <FieldRow title="Number of recipients" centerTitle>
          {data?.numberOfRecipients || '-'}
        </FieldRow>
      </GridContainer>
      <Divider className={classes.divider} />
      <GridContainer item md={11}>
        <FieldRow title={'Subject line'} centerTitle>
          {data?.subject || '-'}
        </FieldRow>

        <FieldRow title="Email title" centerTitle>
          {data?.title || '-'}
        </FieldRow>
        <FieldRow title="Email Body" centerTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.emailBody,
            }}
          />
        </FieldRow>
        <FieldRow title={'Send to advised investors?'} centerTitle>
          {data?.sendToAdvisedInvestor ? 'Yes' : 'No'}
        </FieldRow>
        {data?.sendToAdvisedInvestor && (
          <FieldRow title={'Copy (CC:) to advisers'} centerTitle>
            {data?.addAdviserToCC ? 'Yes' : 'No'}
          </FieldRow>
        )}
      </GridContainer>
    </>
  );
};

export default Summary;
