import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import {
  Button,
  makeStyles,
  Typography,
  Switch,
  Grid,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';

import TextField from 'components/FormElements/AppTextInput';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import {
  addCompany,
  getCompanyById,
  updateCompany,
} from '@redux/actions/Company';
import { runValidationRules } from 'helpers/company/formValidations';
import NonVctFields from './NonVctFields';
import DividendRows from './DividendRows';
import ShareSplitRows from './ShareSplitRows';
import RevaluationRows from './RevaluationRows';
import NewsRows from './NewsRows';
import CardHeader from 'components/CardHeader';
import FieldRow from 'components/FormElements/FieldRow';
import StageAndSector from './StageAndSector';
import { COMPANY } from 'constants/routes';
import useFileUpload from 'hooks/ui/useFileUpload';
import AllocationRecords from './AllocationRecords';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { InitialSharePrice } from './InitialSharePrice';
import { UploadType } from 'further-types/files';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useFunds } from 'hooks/data/fund/useFunds';
import { FirmSelector } from 'components/FirmSelector';
import { toRequestDate } from 'further-ui/utils';
import { useQueryClient } from '@tanstack/react-query';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  dropZone: {
    minHeight: 0,
  },
  marginRevalution: {
    margin: 'revert',
  },
  marginShareFile: {
    marginTop: 5,
  },
  addRevalutionBtn: {
    textAlign: 'end',
    marginTop: '14px',
  },
  mxC: {
    marginLeft: '0',
    marginRight: '0',
    marginBottom: '15px',
  },
  cmxc: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
  },
  firmDropMt: {
    marginTop: '20px',
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  smallFlexContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  spanLabel: {
    color: '#C4C4C4',
    fontSize: 12,
    fontWeight: 'bold',
    width: 115,
  },
  divider: {
    marginBottom: 32,
    marginTop: 16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
  },
  deleteButton: {
    color: '#E8453C',
  },
  rowWrapper: {
    marginBottom: 14,
  },
  spinner: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
}));

const GreenSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#56b26c',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#56b26c',
  },
}));

const SectionDivider = ({ classes }) => (
  <Grid item md={12} xs={12}>
    <Divider className={classes.divider} />
  </Grid>
);

const AddUpdateCompany = () => {
  const queryClient = useQueryClient();
  const { id: companyId } = useParams();
  let duplicateId = localStorage.getItem('duplicate-company-id');

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Company', link: COMPANY },
    {
      label: companyId ? 'Update Company' : 'Add Company',
      link: '/',
      isActive: true,
    },
  ];
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { isSuperAdmin, firmId: usersFirmId } = useGetRole();
  const handleApiRequest = useApiRequestHandler();
  const [value, setValue] = useState({
    tradingName: '',
    legalName: '',
    url: '',
    twitterUrl: '',
    linkedinUrl: '',
    description: '',
    initialPrice: '',
    date: null,
    firmId: usersFirmId || '',
    shareClass: '',
    stage: '',
    sector: '',
    companyLogo: null,
    previewCompanyLogo: null,
    companiesHouseNumber: null,
  });
  const [fundId, setFundId] = useState('');
  const [news, setNews] = useState([]);
  const [revaluation, setRevaluation] = useState([]);
  const [dividends, setDividends] = useState([]);
  const [errorText, setErrorText] = useState({});
  const [errorTextRevalution, setErrorTextRevalution] = useState({
    amount: '',
    date: '',
  });
  const [errorTextDividends, setErrorTextDividends] = useState({
    amount: '',
    date: '',
  });
  const [errorTextNews, setErrorTextNews] = useState({
    title: '',
    date: '',
    url: '',
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [isVct, setIsVct] = useState(false);
  const { uploadFile } = useFileUpload();

  const { tranches } = useFunds({
    firmId: value.firmId,
    isVCT: true,
    disabled: !isVct,
  });

  const validate = () => {
    const { dividendsError, errors, revalutionError, newsError, isError } =
      runValidationRules(isVct, value, revaluation, news, dividends, fundId);

    setErrorTextDividends(dividendsError);
    setErrorText(errors);
    setErrorTextRevalution(revalutionError);
    setErrorTextNews(newsError);

    return {
      errors,
      isError,
    };
  };

  const onSubmit = async () => {
    setSubmitDisabled(true);
    let validateData = validate();
    if (!validateData.isError) {
      var response;
      value.date = value.date !== 'Invalid date' ? value.date : null;
      let values = {
        revaluation: revaluation.map((rev) => ({
          ...rev,
          date: toRequestDate(new Date(rev.date)),
        })),
        news: news.map((news) => ({
          ...news,
          date: toRequestDate(new Date(news.date)),
        })),
        isVCT: isVct,
        firmId: value.firmId,
        initialPrice: value.initialPrice,
        date: toRequestDate(new Date(value.date)),
        dividends: dividends.map((dividend) => ({
          ...dividend,
          date: toRequestDate(new Date(dividend.date)),
        })),
        shareClass: value.shareClass,
        companiesHouseNumber: value?.companiesHouseNumber,
      };
      if (value?.companyLogo?.includes('.com/')) {
        value.companyLogo = value?.companyLogo.split('.com/')[1];
      }

      if (isVct) {
        values = {
          ...values,
          fundId,
        };
      } else {
        values = {
          ...value,
          ...values,
        };
      }

      await handleApiRequest(async (showSuccess) => {
        if (companyId) {
          values.id = companyId;
          response = await dispatch(updateCompany({ ...values }));
        } else {
          response = await dispatch(addCompany({ ...values }));
        }

        if (response.status === 200) {
          showSuccess(response.data.responseMsg);
          queryClient.invalidateQueries('company');

          if (duplicateId) {
            localStorage.removeItem('duplicateId');
          }
          history.push(COMPANY);
        } else {
          setValue({
            ...value,
            url: value?.url,
            twitterUrl: value?.twitterUrl,
            linkedinUrl: value?.linkedinUrl,
          });
        }
      });
      setSubmitDisabled(false);
    }
    setSubmitDisabled(false);
  };

  const handleChangeLogo = async (file) => {
    if (file?.[0]?.path) {
      await handleApiRequest(async () => {
        const { filePath } = await uploadFile(file[0], UploadType.CompanyLogos);

        setValue({
          ...value,
          companyLogo: filePath,
          previewCompanyLogo: file[0]?.preview,
        });
      }, "Something went wrong - the file couldn't be uploaded");
    }
  };

  useEffect(() => {
    let duplicateId = localStorage.getItem('duplicate-company-id');
    const getCompanyDetail = async (id) => {
      await handleApiRequest(async () => {
        let response = await dispatch(getCompanyById(id));
        if (response) {
          let data = {
            tradingName: response.tradingName,
            legalName: response.legalName,
            url: response?.url?.replace('https://', ''),
            twitterUrl: response?.twitterUrl?.replace('https://', ''),
            linkedinUrl: response?.linkedinUrl?.replace('https://', ''),
            description: response?.description,
            sector: response?.sector,
            stage: response?.stage,
            firmId: response?.firmId,
            date: null,
            shareClass: response?.shareClass,
            companyLogo: response?.companyLogo,
            previewCompanyLogo: response?.companyLogo,
            companiesHouseNumber: response?.companiesHouseNumber,
          };
          if (!duplicateId) {
            data = {
              ...data,
              initialPrice: response?.initialPrice,
              date: response?.date,
            };
            setDividends(response?.dividends);
            if (response?.revaluation) {
              setRevaluation(response?.revaluation);
            }
            if (response?.news) {
              response.news.forEach((newsItem) => {
                newsItem.url = newsItem?.url?.replace('https://www.', '');
              });
              setNews(response?.news);
            }
          }
          setValue(data);
          setFundId(response?.fundId);
          setIsVct(response?.isVCT);
          setLoading(false);
        }
      });
    };
    if (companyId) {
      getCompanyDetail(companyId);
    } else if (duplicateId) {
      getCompanyDetail(duplicateId);
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  return (
    <PageContainer
      heading={
        companyId ? 'Companies: Update Company' : 'Companies: Add Company'
      }
      breadcrumbs={breadcrumbs}
    >
      <CmtCard>
        <CmtCardContent>
          <CardHeader title="Company details" />
          {!loading && (
            <GridContainer item md={11}>
              {isSuperAdmin && (
                <Grid item md={12}>
                  <div className={classes.toggle}>
                    <Typography>EIS/SEIS</Typography>
                    <GreenSwitch
                      checked={isVct}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setNews([]);
                        }
                        setErrorText({});
                        setIsVct(!isVct);
                      }}
                    />
                    <Typography>VCT</Typography>
                  </div>
                </Grid>
              )}
              <FieldRow title="Firm*" centerTitle>
                <FirmSelector
                  firmId={value.firmId}
                  onChange={(firmId) => {
                    setValue({ ...value, firmId });
                    setFundId('');
                  }}
                  placeholder="Select a firm"
                  required
                  error={errorText.firmId}
                  helperText={errorText.firmId}
                />
              </FieldRow>
              {!isVct && (
                <NonVctFields
                  values={value}
                  setValues={setValue}
                  errorText={errorText}
                  handleChangeLogo={handleChangeLogo}
                  classes={classes}
                />
              )}
              {isVct && (
                <FieldRow
                  title="Fund*"
                  centerTitle
                  className={classes.firmDropMt}
                >
                  <AppSelectBox
                    disabled={!value.firmId || !tranches.data?.length}
                    required
                    data={tranches.data}
                    valueKey="_id"
                    label={
                      value.firmId && !tranches.data?.length
                        ? 'No VCT funds found'
                        : 'Fund'
                    }
                    name="fundId"
                    labelKey="fundName"
                    variant="outlined"
                    value={fundId}
                    error={errorText.fundId}
                    helperText={errorText.fundId}
                    onChange={(event) => {
                      setFundId(event.target.value);
                    }}
                  />
                </FieldRow>
              )}
              <SectionDivider classes={classes} />
              <FieldRow title="Share class*" centerTitle>
                <TextField
                  name="shareClass"
                  placeholder="Enter share class. e.g. Ordinary"
                  required
                  fullWidth
                  onChange={(event) =>
                    setValue({ ...value, shareClass: event.target.value })
                  }
                  value={value.shareClass}
                  error={errorText.shareClass}
                  helperText={errorText.shareClass}
                />
              </FieldRow>
              {companyId && (
                <>
                  <SectionDivider classes={classes} />
                  <AllocationRecords companyId={companyId} />
                </>
              )}

              <InitialSharePrice
                classes={classes}
                setValue={setValue}
                value={value}
                errorText={errorText}
              />
              <RevaluationRows
                revaluations={revaluation}
                setRevaluations={setRevaluation}
                errorTextRevaluation={errorTextRevalution}
                companyId={companyId}
                classes={classes}
              />
              {companyId && (
                <>
                  <SectionDivider classes={classes} />
                  <ShareSplitRows companyId={companyId} classes={classes} />
                </>
              )}
              <SectionDivider classes={classes} />
              <DividendRows
                dividends={dividends}
                setDividends={setDividends}
                errorTextDividends={errorTextDividends}
                classes={classes}
              />
              {!isVct && (
                <>
                  <SectionDivider classes={classes} />
                  <NewsRows
                    news={news}
                    setNews={setNews}
                    errorTextNews={errorTextNews}
                    classes={classes}
                  />
                  <SectionDivider classes={classes} />
                  <StageAndSector
                    values={value}
                    setValues={setValue}
                    errorText={errorText}
                  />
                </>
              )}
              <Grid item xs={12}>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      if (duplicateId) {
                        localStorage.removeItem('duplicateId');
                      }
                      history.push(COMPANY);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.marginLeftBtn}
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    disabled={submitDisabled}
                  >
                    {!submitDisabled && (companyId ? 'Update' : 'Save')}
                    {submitDisabled && (
                      <CircularProgress size={18} className={classes.spinner} />
                    )}
                  </Button>
                </div>
              </Grid>
            </GridContainer>
          )}
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};
export default AddUpdateCompany;
