import React, { MouseEventHandler, ReactNode } from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',

    '& .MuiDialog-paperWidthMd': {
      maxWidth: 500,
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  exportSpinner: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: 10,
  },
  dialogIcon: {
    color: theme.palette.warning.main,
    fontSize: 65,
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block',

    '&.MuiSvgIcon-root': {
      fontSize: 65,
      display: 'block',
    },
  },
  title: {
    color: '#282525',
    fontSize: '26px',
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center',
    margin: '1.5rem 1rem',
    lineHeight: 1.3,
  },
  content: {
    fontSize: '16px',
    color: '#7B7B7B',
    letterSpacing: 0,
    padding: '0 2rem',
    margin: 0,
    textAlign: 'center',
  },
  dialogActions: {
    padding: '1.5rem 0 2rem 0 ',
    justifyContent: 'center',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
}));

type Props = Pick<DialogProps, 'open'> & {
  size?: DialogProps['maxWidth'];
  onConfirm?: MouseEventHandler;
  onClose?: MouseEventHandler;
  title?: string;
  content?: ReactNode;
  btnLabels?: {
    cancel: string | boolean;
    confirm: string | boolean;
  };
  icon?: ReactNode;
  cancelBtnProps?: ButtonProps;
  confirmBtnProps?: ButtonProps & { isFetching?: boolean };
  confirmBtnComponent?: ReactNode;
};

const AlertDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  content,
  icon,
  cancelBtnProps,
  confirmBtnProps,
  confirmBtnComponent,
  open = false,
  title = 'Confirm Delete',
  btnLabels = {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  size = 'md',
}) => {
  const classes = useStyles();

  return open ? (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      className={classes.dialogRoot}
      maxWidth={size}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {icon ? icon : <WarningIcon className={classes.dialogIcon} />}
        <DialogContentText className={classes.title}>{title}</DialogContentText>
        <div className={classes.content}>{content}</div>
      </DialogContent>

      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button onClick={onClose} variant="outlined" {...cancelBtnProps}>
          {btnLabels.cancel}
        </Button>
        {btnLabels.confirm && !confirmBtnComponent ? (
          <>
            <Button
              onClick={onConfirm}
              color="primary"
              variant="contained"
              {...confirmBtnProps}
            >
              {confirmBtnProps?.isFetching && (
                <CircularProgress className={classes.exportSpinner} size={18} />
              )}
              {btnLabels.confirm}
            </Button>
          </>
        ) : null}
        {confirmBtnComponent ? confirmBtnComponent : null}
      </DialogActions>
    </Dialog>
  ) : null;
};

export default React.memo(AlertDialog);
