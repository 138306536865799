import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sumBy } from 'lodash';
import moment from 'moment';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { makeStyles } from '@material-ui/core';
import {
  RequestSourceToLabelMap,
  Status,
  StatusToLabelMap,
} from 'constants/withdrawalProperties';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import AlertDialog from 'components/AlertDialog';
import PageContainer from 'components/PageContainer';
import { usePagination } from 'hooks/ui/usePagination';
import { getWithdrawalRequests } from '@redux/actions/Withdrawals';
import RejectButton from './RejectButton';
import ExportButton from './FiltersAndActions/ExportButton';
import FiltersAndActions from './FiltersAndActions';
import { useGetRole } from 'hooks/ui/useGetRole';
import NotesAndAttachmentColumn from './NotesAndAttachmentColumn';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import useFilters from 'hooks/ui/useFilters';

const useStyles = makeStyles(() => ({
  tableFooter: {
    background: '#FBFBFA',
  },
  tableFooterTd: {
    padding: '0.5rem 0',
    fontWeight: 'bold',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
  },
}));

const ReviewWithdrawals = () => {
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();

  const { withdrawalRequests, totalCount } = useSelector(
    //@ts-ignore
    ({ WithdrawalsReducer }) => WithdrawalsReducer,
  );
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const { isSuperAdmin } = useGetRole();
  const classes = useStyles();

  const pagination = usePagination({
    id: 'withdrawal-requests-table',
    orderBy: 'createdAt',
    order: 'desc',
  });
  const { filters, handleChangeFilters } = useFilters(
    'withdrawal-requests-table',
    {
      defaultFilters: {
        startDate: null,
        endDate: null,
        status: Status.REQUESTED,
      },
      onFiltersChange: pagination.toFirstPage,
    },
  );

  useEffect(() => {
    const getTableData = async () => {
      const query = {
        perPage: pagination.rowsPerPage,
        order: pagination.order,
        orderBy: pagination.orderBy,
        page: pagination.page,
        ...filters,
      };
      if (filters.startDate && !moment(filters.startDate).isValid()) {
        return null;
      }
      if (filters.endDate && !moment(filters.endDate).isValid()) {
        return null;
      }
      await handleApiRequest(async () => {
        dispatch(getWithdrawalRequests(query));
      });
    };
    getTableData();
  }, [
    dispatch,
    pagination.page,
    pagination.rowsPerPage,
    pagination.order,
    pagination.orderBy,
    filters,
    refetch,
  ]);

  const columns = [
    {
      label: 'Investor Name',
      key: 'investorFullName',
    },
    {
      label: 'Total to be returned',
      key: 'amount',
      render: (elm) => numberToCurrencyString(elm?.amount),
    },
    {
      label: 'Withdrawal request date',
      key: 'createdAt',
      render: (elm) => dateToLabel(elm?.createdAt),
    },
    {
      label: 'Withdrawal type',
      key: 'requestSource',
      render: (elm) => RequestSourceToLabelMap[elm?.requestSource],
    },
    {
      label: 'Name on bank account',
      key: 'nameOnBankAccount',
      render: (elm) => elm?.investorBankDetails?.bankAccountName,
      sort: false,
    },
    {
      label: 'Bank account number',
      key: 'bankAccountNumber',
      render: (elm) => elm?.investorBankDetails?.bankAccountNumber,
      sort: false,
    },
    {
      label: 'Bank sort code',
      key: 'bankSortCode',
      render: (elm) => elm?.investorBankDetails?.bankSortCode,
      sort: false,
    },
    {
      label: 'Status',
      key: 'status',
      render: (elm) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {StatusToLabelMap[elm?.status]}
        </span>
      ),
      sort: false,
    },
    {
      label: 'Notes',
      key: 'notes',
      render: (elm) => <NotesAndAttachmentColumn elm={elm} />,
      sort: false,
    },
    {
      label: 'Actions',
      render: (elm) => (
        <RejectButton
          withdrawal={elm}
          onRejectSuccess={() => setRefetch(!refetch)}
        />
      ),
      hideCol:
        [Status.REJECTED, Status.EXPORTED_TO_CUSTODIAN].includes(
          filters.status,
        ) || isSuperAdmin,
      sort: false,
    },
  ];
  const tableEmtpyMessage = [
    Status.EXPORTED_TO_CUSTODIAN,
    Status.REJECTED,
  ].includes(filters.status)
    ? `No withdrawal requests found that have been ${StatusToLabelMap[
        filters.status
      ].toLowerCase()}`
    : 'No withdrawal requests found';

  return (
    <PageContainer heading="Withdrawals: Withdrawal record">
      <CmtCard>
        <CmtCardContent>
          <FiltersAndActions
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            onExportClick={() => setIsConfirmDialogOpen(true)}
            withdrawalRequests={withdrawalRequests}
            orderBy={pagination.orderBy}
            order={pagination.order}
          />
          <Table
            columns={columns.filter((col) => !col.hideCol)}
            tablebody={withdrawalRequests}
            count={totalCount}
            orderBy={pagination.orderBy}
            order={pagination.order}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onRequestSort={pagination.handleRequestSort}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            pagination={true}
            emptyMessage={tableEmtpyMessage}
            variant="nohover"
            TableFooter={
              <tr className={classes.tableFooter}>
                <td className={classes.tableFooterTd}>Total</td>
                <td className={classes.tableFooterTd}>
                  {numberToCurrencyString(sumBy(withdrawalRequests, 'amount'))}
                </td>
              </tr>
            }
          />
        </CmtCardContent>
      </CmtCard>

      <AlertDialog
        open={isConfirmDialogOpen}
        title={`Are you sure you wish to export these ${withdrawalRequests.length} withdrawals to your custodian?`}
        content={
          <p>
            This cannot be undone. Upon exporting these withdrawal requests,
            they will be marked as transferred in the Further system. Exported
            files must be shared with your custodian for actioning.
          </p>
        }
        onClose={() => setIsConfirmDialogOpen(false)}
        confirmBtnComponent={
          <ExportButton
            withdrawalRequests={withdrawalRequests}
            //@ts-ignore
            search={filters}
            onStatusUpdateSuccess={() => {
              setIsConfirmDialogOpen(false);
              setRefetch(!refetch);
            }}
            orderBy={pagination.orderBy}
            order={pagination.order}
          />
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: false,
        }}
        cancelBtnProps={{
          color: 'primary',
          style: { minWidth: '120px' },
        }}
      />
    </PageContainer>
  );
};

export default ReviewWithdrawals;
