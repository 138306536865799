import React from 'react';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { makeStyles } from '@material-ui/core';
import { useFirm } from 'hooks/data/firm/useFirm';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    color: '#999999',
  },
}));

export const FirmSelector = ({
  firmId,
  onChange,
  placeholder,
  allowDeselection,
  deselectionLabel = 'None',
  ...rest
}) => {
  const { firms } = useFirm({ params: {} });
  const classes = useStyles();

  const emptyOption = { firmId: null, firmName: deselectionLabel };

  return (
    <AppSelectBox
      required
      data={
        allowDeselection
          ? [emptyOption, ...(firms.data?.result ? firms.data.result : [])]
          : firms.data?.result
      }
      valueKey="_id"
      labelKey="firmName"
      fullWidth
      value={firmId ?? undefined}
      variant="outlined"
      disabled={firms.isLoading || firms.length === 0}
      renderValue={() => {
        if (firms.isLoading)
          return <span className={classes.placeholder}>Loading...</span>;
        if (firms.length === 0)
          return <span className={classes.placeholder}>No firms found</span>;
        if (!firmId && placeholder)
          return <span className={classes.placeholder}>{placeholder}</span>;

        return firms.data?.result?.find((firm) => firm?._id === firmId)
          ?.firmName;
      }}
      onChange={(e) => {
        const firmId = e.target.value;
        const firm = firms.data?.result?.find((firm) => firm?._id === firmId);
        onChange(firmId, firm);
      }}
      {...rest}
    />
  );
};
