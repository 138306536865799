import React from 'react';
import { Link } from 'react-router-dom';
import { createClosedTrancheRoute } from 'constants/routes';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { numberToCurrencyString } from 'further-ui/utils';
import { numberToPercentageString } from 'utils/numbers';
import Box from 'components/Box';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import { CompanyCategoryPieChart } from '../CompanyCategoryPieChart';
import { ShowChart } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  flexWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  pieWrapper: {
    marginTop: '3rem',
  },
  centeredPieWrapper: {
    marginTop: '3rem',
    display: 'flex',
    flex: 1,
  },
  centeredPie: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const TrancheDeploymentSummary = ({
  deploymentSummary,
  tranche,
  classes,
  displayFundsRaisedInsteadOfDeployed,
}) => {
  const componentClasses = useStyles();

  const percentageRemainingToDeploy = numberToPercentageString(
    deploymentSummary?.remainingAmountToDeploy /
      deploymentSummary?.totalAmountInvestedIntoTranche,
  );
  const percentageDeployed = numberToPercentageString(
    deploymentSummary?.totalAmountDeployed /
      deploymentSummary?.totalAmountInvestedIntoTranche,
  );

  const GenericCardHeader = () => (
    <CmtCardHeader
      title="Tranche deployment summary"
      icon={<ShowChart className={classes.green} />}
    />
  );

  const DetailedCardHeader = ({ name, id }) => (
    <CmtCardHeader title={name} icon={<ShowChart className={classes.green} />}>
      <Link className={classes.viewAll} to={createClosedTrancheRoute(id)}>
        View dashboard
      </Link>
    </CmtCardHeader>
  );

  const maxLegendKeys =
    deploymentSummary?.amountDeployedByCompanyStage &&
    deploymentSummary?.amountDeployedByCompanySector
      ? Math.max(
          Object.keys(deploymentSummary.amountDeployedByCompanyStage).length,
          Object.keys(deploymentSummary.amountDeployedByCompanySector).length,
        )
      : 0;
  let pieHeight = 270;
  if (maxLegendKeys > 7) pieHeight = 300;
  if (maxLegendKeys > 10) pieHeight = 340;

  const centerPies = false;

  return (
    <CmtCard
      className={
        centerPies
          ? `${componentClasses.flexWrapper} ${classes.flexGrow}`
          : classes.flexGrow
      }
    >
      {tranche && tranche.fundName && tranche._id ? (
        <DetailedCardHeader
          name={`${tranche.firmId?.firmName} - ${tranche.fundName}`}
          id={tranche._id}
        />
      ) : (
        <GenericCardHeader />
      )}
      <Divider className={classes.divider} />
      <CmtCardContent
        className={centerPies ? componentClasses.flexWrapper : ''}
      >
        <GridContainer>
          <Grid item xs={6}>
            <Box
              title={numberToCurrencyString(
                deploymentSummary?.remainingAmountToDeploy,
                { hidePenniesIfInteger: true },
              )}
              titleTooltip={`${percentageRemainingToDeploy} remaining`}
              subtitle="Remaining to deploy"
            ></Box>
          </Grid>
          <Grid item xs={6}>
            {displayFundsRaisedInsteadOfDeployed ? (
              <Box
                title={numberToCurrencyString(
                  deploymentSummary?.totalAmountInvestedIntoTranche,
                  { hidePenniesIfInteger: true },
                )}
                subtitle="Funds raised"
              ></Box>
            ) : (
              <Box
                title={numberToCurrencyString(
                  deploymentSummary?.totalAmountDeployed,
                  { hidePenniesIfInteger: true },
                )}
                titleTooltip={`${percentageDeployed} deployed`}
                subtitle="Deployed"
              ></Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box
              title={deploymentSummary?.countOfInvestments}
              subtitle="Investments"
            />
          </Grid>
          <Grid item xs={6}>
            <Box
              title={deploymentSummary?.countOfCompanies}
              subtitle="Companies"
            />
          </Grid>
        </GridContainer>

        <GridContainer
          className={
            centerPies
              ? componentClasses.centeredPieWrapper
              : componentClasses.pieWrapper
          }
        >
          <Grid
            item
            md={6}
            sm={12}
            className={centerPies ? componentClasses.centeredPie : ''}
          >
            <CompanyCategoryPieChart
              title="Investments by stage"
              subtitle="(Ordered by current value)"
              deploymentData={deploymentSummary.amountDeployedByCompanyStage}
              height={pieHeight}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            className={centerPies ? componentClasses.centeredPie : ''}
          >
            <CompanyCategoryPieChart
              title="Investments by sector"
              subtitle="(Ordered by current value)"
              deploymentData={deploymentSummary.amountDeployedByCompanySector}
              height={pieHeight}
              numberOfLegendToSkip={
                deploymentSummary?.amountDeployedByCompanySector?.length - 4
              }
            />
          </Grid>
        </GridContainer>
      </CmtCardContent>
    </CmtCard>
  );
};
