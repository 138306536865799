import { useState } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import { Button, makeStyles } from '@material-ui/core';
import {
  Delete,
  Edit,
  FileCopy,
  Dvr,
  LinkOutlined,
  CloudDownload,
} from '@material-ui/icons';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { fundStatusMap, fundStatusToLabel } from 'constants/fundProperties';
import { Link } from 'react-router-dom';
import {
  TRANCHE_ADD,
  createTrancheEditRoute,
  TRANCHE_BESPOKE_LINK,
} from 'constants/routes';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { useSortTranches } from 'hooks/ui/useSortTranches';
import { useFunds } from 'hooks/data/fund/useFunds';
import AlertDialog from 'components/AlertDialog';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useTags } from 'hooks/data/tag/useTags';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import useFilters from 'hooks/ui/useFilters';
import { createTrancheOnboardingLink } from 'helpers/trancheLink/trancheLink';
import { useFund } from 'hooks/data/fund/useFund';

export const url = process.env.REACT_APP_STAGE;

const useStyles = makeStyles(() => ({
  customButton: {
    minWidth: '35px',
    color: '#56B26C',
  },
  trashButton: {
    minWidth: '35px',
    color: '#E8453C',
  },
  view: {
    padding: '10px 16px 6px',
  },
  dFlex: {
    display: 'flex',
  },
  tableMt: {
    marginTop: 10,
  },
  link: {
    display: 'flex',
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Tranches', isActive: true },
];

const fundStatus = Object.entries(fundStatusToLabel).map(([value, name]) => ({
  name,
  value,
}));

const Tranche = () => {
  const classes = useStyles();
  const pagination = usePagination({
    id: 'tranches-table',
  });
  const { filters, handleChangeFilters } = useFilters('tranches-table', {
    defaultFilters: {
      firmId: null,
      fundStatus: null,
      tagId: null,
      fundId: null,
      startDate: null,
      endDate: null,
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const { tags } = useTags();
  const { remove, duplicate } = useFund();
  const { tranches } = useFunds({
    firmId: filters?.firmId?._id,
    fundStatus: filters?.fundStatus?.value,
    tagId: filters.tagId?._id,
    fundId: filters.fundId?._id,
    startDate: filters.startDate,
    endDate: filters.endDate,
    includeInvestmentsSummary: true,
    keyName: pagination.orderBy,
    page: pagination.page === 0 ? 1 : pagination.page,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
  });
  const { tranches: allTranches } = useFunds({
    leanResponse: true,
  });
  const { exportFund } = useFund();
  const sortedTranches = useSortTranches(allTranches?.data);
  const { firms } = useFirm({ params: {} });

  const [copyFlag, setCopyFlag] = useState(false);
  const quickRanges = setDefinedRanges();
  const deleteDisclosure = useDisclosure();
  const [createPermission, deletePermission, updatePermission] =
    useGetPermissions(['create:tranche', 'delete:tranche', 'edit:tranche']);

  const columns = [
    {
      label: 'Firm name',
      key: 'firmId',
      render: (elm) => elm?.firmId?.firmName,
    },
    { label: 'Tranche name', key: 'fundName' },
    {
      label: 'First investment date',
      key: 'firstInvestmentDate',
      render: (elm) => dateToLabel(elm.firstInvestmentDate),
    },
    {
      label: 'Close date',
      key: 'closedDate',
      render: (elm) => elm.closedDate && dateToLabel(elm.closedDate),
    },
    {
      label: 'Investors',
      key: 'investorCount',
      sort: false,
      render: (elm) => String(elm.investorCount),
    },
    {
      label: 'Total investment',
      key: 'totalInvestment',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.totalInvestment),
    },
    {
      label: 'Status',
      key: 'fundStatus',
      render: (elm) =>
        elm.fundStatus === 1
          ? 'Open'
          : elm.fundStatus === 0
          ? 'Closed'
          : 'Draft',
    },
    {
      label: 'Type',
      sort: false,
      render: (elm) => elm?.tag?.[0]?.name,
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm) => {
        return (
          <div className={classes.dFlex}>
            <Button
              title="Duplicate"
              className={classes.customButton}
              color="primary"
              onClick={async () => {
                setCopyFlag(!copyFlag);
                duplicate.mutate(elm._id);
              }}
            >
              <FileCopy />
            </Button>
            <Button
              color="primary"
              title="Export tranche"
              onClick={() =>
                exportFund(elm?._id, elm?.fundName, elm?.firmId?.firmName)
              }
            >
              <CloudDownload />
            </Button>
            {updatePermission && (
              <Link
                to={createTrancheEditRoute(elm._id)}
                className={classes.link}
              >
                <Button
                  title="Edit"
                  color="primary"
                  className={classes.customButton}
                >
                  <Edit />
                </Button>
              </Link>
            )}

            {deletePermission && (
              <Button
                title="Delete"
                className={classes.trashButton}
                onClick={() => deleteDisclosure.stageAction(elm)}
              >
                <Delete htmlColor="#E8453C" />
              </Button>
            )}

            {elm?.firmId?.domain && elm.fundStatus === fundStatusMap.open && (
              <>
                <Link
                  to={{
                    pathname: TRANCHE_BESPOKE_LINK,
                    state: { trancheId: elm._id },
                  }}
                  className={classes.link}
                >
                  <Button
                    title="Bespoke investment link"
                    className={classes.customButton}
                    color="primary"
                  >
                    <LinkOutlined />
                  </Button>
                </Link>
                <a
                  rel="noopener noreferrer"
                  title="View"
                  type="button"
                  className={clsx(
                    classes.tableMt,
                    classes.view,
                    classes.customButton,
                  )}
                  href={createTrancheOnboardingLink(elm)}
                  target="_blank"
                >
                  <Dvr />
                </a>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleConfirmDelete = async () => {
    const { _id } = deleteDisclosure.actionProps || {};
    remove.mutate(_id);
    if (tranches?.data?.length === 1) {
      pagination.pageBack();
    }
    deleteDisclosure.onClose();
  };

  return (
    <PageContainer heading="Tranches" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} md={3} className={classes.marginSelect}>
                  <Autocomplete
                    id="firmId"
                    options={firms.data?.result}
                    getOptionLabel={(option) => option.firmName}
                    filterSelectedOptions
                    value={filters.firmId}
                    onChange={(_, newValue) => {
                      handleChangeFilters({ firmId: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select firm"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Autocomplete
                    id="fundId"
                    options={sortedTranches}
                    getOptionLabel={(option) => option.label}
                    value={filters.fundId}
                    filterSelectedOptions
                    onChange={(_, newValue) => {
                      handleChangeFilters({ fundId: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Tranche"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <AppAutocomplete
                    name="tagId"
                    options={tags?.data}
                    getOptionLabel={(option) => option?.name}
                    filterSelectedOptions
                    value={filters.tagId}
                    onChange={(_, newValue) => {
                      handleChangeFilters({ tagId: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Fund type"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3} className={classes.marginSelect}>
                  <Autocomplete
                    id="status"
                    options={fundStatus?.reverse()}
                    getOptionLabel={(option) => option.name}
                    value={filters.fundStatus}
                    filterSelectedOptions
                    onChange={(_, newValue) => {
                      handleChangeFilters({ fundStatus: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select tranche status"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiDateRangePicker
                    dateRange={{
                      startDate: filters.startDate,
                      endDate: filters.endDate,
                    }}
                    onDateRangeChange={(data) => {
                      handleChangeFilters({
                        startDate: data.startDate,
                        endDate: data.endDate,
                      });
                    }}
                    TextFieldProps={{ placeholder: 'Search by close date' }}
                    definedRanges={quickRanges}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {createPermission && (
                    <Link to={TRANCHE_ADD}>
                      <Button
                        color="primary"
                        variant="contained"
                        className="bt-cls"
                      >
                        Add Tranche
                      </Button>
                    </Link>
                  )}
                </Grid>
              </GridContainer>
              <div className={classes.tableMt}>
                <Table
                  columns={columns}
                  onRequestSort={pagination.handleRequestSort}
                  order={pagination.order}
                  orderBy={pagination.orderBy}
                  tablebody={tranches.data || []}
                  onPageChange={pagination.handleChangePage}
                  onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                  page={pagination.page}
                  rowsPerPage={pagination.rowsPerPage}
                  count={tranches.total}
                  pagination
                  loading={tranches.isFetching}
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <AlertDialog
          open={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          onConfirm={handleConfirmDelete}
          title={`Are you sure you want to delete this tranche?`}
          content={
            <p className={classes.content}>
              This cannot be undone and all information relating to the tranche
              will be lost.
            </p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Delete tranche',
          }}
        />
      </GridContainer>
    </PageContainer>
  );
};

export default Tranche;

function setDefinedRanges() {
  const currentYear = new Date().getFullYear();
  const ranges = [];

  for (let i = 0; i < 7; i++) {
    const year = currentYear - i;
    ranges.push({
      label: `${year}`,
      startDate: new Date(`${year}-01-01`),
      endDate: new Date(`${year}-12-31`),
    });
  }

  return ranges;
}
