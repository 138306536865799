import { useMemo, useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { orderBy } from 'lodash';
import { useDebounce } from 'usehooks-ts';
import Table from 'components/Table';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/GridContainer';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { INVESTOR_REPORTING } from 'constants/routes';
import { AppSelectBox, AppTextInput } from 'components/FormElements';
import { Tooltip } from 'components/Tooltip';
import { usePagination } from 'hooks/ui/usePagination';
import { useExitStatement } from 'hooks/data/exit';
import { useBulkDownloadExitStatementPdf } from 'hooks/data/investorReporting/useBulkDownloadExitStatementPdf';
import { DownloadPdfExitStatementButton } from 'components/DownloadPdfExitUpdateButton';

const useStyles = makeStyles(() => ({
  gridMargin: {
    marginBottom: '1rem',
  },
  downloadBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '4.5rem',
  },
}));

const ExitStatementPdfDownload = () => {
  const { exitStatementId } = useParams<{ exitStatementId: string }>();

  const [search, setSearch] = useState('');
  const [isHardCopyOnly, setIsHardCopyOnly] = useState(false);
  const pagination = usePagination({
    order: 'asc',
    orderBy: 'fullName',
    pageSize: 25,
  });
  const debouncedSearchTerm = useDebounce(search, 300);
  const {
    exitStatement: { data, isFetching },
  } = useExitStatement({ id: exitStatementId });

  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        label: 'Name',
        key: 'fullName',
      },
      {
        label: 'Email',
        key: 'email',
      },
      {
        label: 'External Investor ID',
        key: 'externalInvestorId',
      },
      {
        label: 'Download',
        key: 'download',
        sort: false,
        render: (elm) => (
          <DownloadPdfExitStatementButton
            exitStatementId={exitStatementId}
            investorId={elm._id}
            filename={`${elm.fullName} ${data?.exitStatement.title} Statement.pdf`}
          />
        ),
      },
    ],
    [exitStatementId, data?.exitStatement.title],
  );

  useEffect(() => {
    pagination.toFirstPage();
  }, [debouncedSearchTerm, isHardCopyOnly]);

  const handleFilters = useCallback(
    (investor) => {
      // No Filters Applied
      if (!debouncedSearchTerm && !isHardCopyOnly) return true;

      const term = debouncedSearchTerm?.toLowerCase() ?? '';
      return (
        (!debouncedSearchTerm ||
          investor?.fullName?.toLowerCase().includes(term) ||
          investor?.email?.toLowerCase().includes(term) ||
          investor?.externalInvestorId?.toLowerCase().includes(term)) &&
        (!isHardCopyOnly || investor.requiresHardCopyReports)
      );
    },
    [debouncedSearchTerm, isHardCopyOnly],
  );

  const filteredTablebody = useMemo(
    () =>
      orderBy(
        data?.investors?.filter(handleFilters) ?? [],
        [pagination.orderBy],
        [pagination.order],
      ),
    [
      data?.investors,
      debouncedSearchTerm,
      pagination.orderBy,
      pagination.order,
      isHardCopyOnly,
    ],
  );

  const paginatedTablebody = useMemo(
    () =>
      filteredTablebody.slice(
        (pagination.page - 1) * pagination.rowsPerPage,
        pagination.rowsPerPage * pagination.page,
      ),
    [
      filteredTablebody,
      pagination.page,
      pagination.rowsPerPage,
      isHardCopyOnly,
    ],
  );

  const investorOptions = [
    {
      key: false,
      label: 'All investors',
    },
    {
      key: true,
      label: 'Investors requiring hard copies',
    },
  ];

  const areAllPdfDownloadable =
    data?.exitStatement.buildingPdfStatus === undefined ||
    data?.exitStatement.buildingPdfStatus === 'complete';

  const bulkDownload = useBulkDownloadExitStatementPdf(
    exitStatementId,
    data?.exitStatement.title,
    filteredTablebody.map(({ _id, fullName }) => {
      return { _id: _id.toString(), fullName };
    }),
  );

  const isDownloadAllButtonDisabled =
    !areAllPdfDownloadable ||
    !filteredTablebody.length ||
    bulkDownload.isLoading;

  return (
    <PageContainer
      heading="Investor Reporting: Download Exit Statement PDFs"
      breadcrumbs={[
        { label: 'Dashboard', link: '/' },
        { label: 'Investor Reporting', link: INVESTOR_REPORTING },
        { label: 'Download PDFs', isActive: true, withoutFormatting: true },
      ]}
    >
      <CmtCard>
        <CmtCardContent>
          <GridContainer className={classes.gridMargin}>
            <Grid item md={4} xs={12}>
              <AppTextInput
                name="searchInvestors"
                variant="outlined"
                placeholder="Filter by name, email or ID"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <AppSelectBox
                data={investorOptions}
                valueKey="key"
                labelKey="label"
                value={isHardCopyOnly}
                variant="outlined"
                onChange={(event) => {
                  const { value } = event.target;
                  setIsHardCopyOnly(value as boolean);
                }}
              />
            </Grid>

            {!isFetching && (
              <Grid item md={4} xs={12} className={classes.downloadBtn}>
                <Tooltip
                  title={
                    data?.exitStatement?.buildingPdfStatus === 'processing'
                      ? 'Bulk PDF Exit Statements will be available for download in 15-30 minutes'
                      : ''
                  }
                >
                  <span>
                    {/* @ts-ignore */}
                    <Button
                      onClick={bulkDownload.mutate}
                      disabled={isDownloadAllButtonDisabled}
                      color={
                        isDownloadAllButtonDisabled ? 'default' : 'primary'
                      }
                      variant="contained"
                    >
                      {!bulkDownload.isLoading ? (
                        'Download all'
                      ) : (
                        <CircularProgress color="inherit" size={24} />
                      )}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </GridContainer>

          <Table
            pagination={true}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            count={filteredTablebody?.length ?? 0}
            columns={columns}
            tablebody={paginatedTablebody || []}
            variant="nohover"
            emptyMessage="No investors with given filters were included in this statement."
            loading={isFetching}
            order={pagination.order}
            orderBy={pagination.orderBy}
            onRequestSort={pagination.handleRequestSort}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          />
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default ExitStatementPdfDownload;
