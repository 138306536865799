import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { pluralize } from 'utils/pluralize';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import InvestorSearch from './InvestorSearch';
import InvestorWithdrawalForm from './InvestorWithdrawalForm';
import QueuedWithdrawalsTable from './QueuedWithdrawalsTable';
import CardHeader from 'components/CardHeader';
import { useLocation } from 'react-router';
import AlertDialog from 'components/AlertDialog';
import { useGetRole } from 'hooks/ui/useGetRole';
import { createWithdrawalRequests } from '@redux/actions/Withdrawals';
import { FirmSelector } from 'components/FirmSelector';
import FullPageSuccess from 'components/FullPageSuccess';
import { WITHDRAWALS } from 'constants/routes';
import FieldRow from 'components/FormElements/FieldRow';
import GridContainer from 'components/GridContainer';
import { useNotification } from 'hooks/ui/useNotification';
import { useDisclosure } from 'further-ui/hooks';
import NoBankAccountWithdrawalModal from './NoBankAccountWithdrawalModal';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridGap: '1rem',
    marginTop: '2rem',
  },
  firmSelectorRow: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '2rem',
    marginBottom: '1rem',
  },
  pendingTitle: {
    margin: '2rem 0',
  },
  divider: {
    margin: '2rem 0',
  },
}));

const RequestWithdrawals = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { error } = useNotification();
  const query = new URLSearchParams(location.search);
  const [investorIdInWithdrawalForm, setInvestorIdInWithdrawalForm] = useState(
    query.get('investorId') || null,
  );
  const [queuedWithdrawals, setQueuedWithdrawals] = useState([]);
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  // firm ID is required so that we retrieve the selected investors correct cash balance.
  // If user is a super admin they'll need to first select a firm from the dropdown
  const { isSuperAdmin, firmId: usersFirmId } = useGetRole();
  const [firmId, setFirmId] = useState(usersFirmId);
  const [isSubmittingWithdrawals, setIsSubmittingWithdrawals] = useState(false);
  const {
    isOpen: shouldDisplayPrompt,
    onOpen: displayPrompt,
    onClose: hidePrompt,
  } = useDisclosure();

  const addInvestorToWithdrawalQueue = (investor, withdrawalDetails) => {
    setQueuedWithdrawals([
      ...queuedWithdrawals,
      { ...investor, withdrawalDetails },
    ]);
  };

  const removeWithdrawalFromQueue = (investorId) => {
    setQueuedWithdrawals(
      queuedWithdrawals.filter((qw) => qw._id !== investorId),
    );
  };

  const checkAndSubmitWithdrawalRequests = async () => {
    if (
      queuedWithdrawals.some(
        (queuedWithdrawal) =>
          !queuedWithdrawal.bankDetails.length ||
          !queuedWithdrawal.bankDetails[0].bankAccountNumber,
      )
    ) {
      displayPrompt();
    } else {
      await submitWithdrawalRequests();
    }
  };

  const submitWithdrawalRequests = async () => {
    hidePrompt();
    setIsSubmittingWithdrawals(true);
    const requestBody = queuedWithdrawals.map((qw) => ({
      investorId: qw._id,
      firmId,
      amount: qw.withdrawalDetails.amountToWithdraw,
      notes: qw.withdrawalDetails.note,
      attachment: qw.withdrawalDetails.attachment?.key,
    }));
    try {
      await dispatch(createWithdrawalRequests(requestBody));

      setShowSuccess(true);
      setIsSubmittingWithdrawals(false);
    } catch (e) {
      error(e.response.data.responseMsg);
      setIsSubmittingWithdrawals(false);
    }
  };

  if (showSuccess) {
    return (
      <FullPageSuccess
        title="Your withdrawal requests have been successfully processed"
        additionalText="To export these to your custodian, please go to the Withdrawal Record by clicking on the button below."
        proceedText="Review Withdrawals"
        proceedLink={WITHDRAWALS}
      />
    );
  }

  return (
    <PageContainer heading="Withdrawals: Bulk withdrawal request">
      <NoBankAccountWithdrawalModal
        isOpen={shouldDisplayPrompt}
        onConfirm={submitWithdrawalRequests}
        onCancel={hidePrompt}
      />
      <CmtCard>
        <CmtCardContent>
          <CardHeader title="Add a new withdrawal request" />
          <GridContainer item md={8}>
            {isSuperAdmin && (
              <FieldRow title="Select a firm" centerTitle>
                <FirmSelector
                  firmId={firmId}
                  onChange={(selectedFirmId) => {
                    setFirmId(selectedFirmId);
                    setQueuedWithdrawals([]);
                  }}
                />
              </FieldRow>
            )}
            {firmId && (
              <InvestorSearch
                onInvestorSelect={(id) => setInvestorIdInWithdrawalForm(id)}
                selectedInvestorId={investorIdInWithdrawalForm}
                queuedWithdrawals={queuedWithdrawals}
                firmId={firmId}
              />
            )}
          </GridContainer>

          {firmId && (
            <>
              <Divider className={classes.divider} />
              <InvestorWithdrawalForm
                investorId={investorIdInWithdrawalForm}
                addInvestorToWithdrawalQueue={(investor, withdrawalDetails) => {
                  addInvestorToWithdrawalQueue(investor, withdrawalDetails);
                  setInvestorIdInWithdrawalForm(null);
                }}
                queuedWithdrawals={queuedWithdrawals}
                firmId={firmId}
              />
            </>
          )}
          {queuedWithdrawals.length > 0 && (
            <>
              <CardHeader
                title="Pending withdrawal requests"
                className={classes.pendingTitle}
              />
              <QueuedWithdrawalsTable
                queuedWithdrawals={queuedWithdrawals}
                removeWithdrawalFromQueue={removeWithdrawalFromQueue}
                firmId={firmId}
              />
              <div className={classes.buttons}>
                {isSubmittingWithdrawals ? (
                  <CircularProgress color="primary" size={28} />
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={checkAndSubmitWithdrawalRequests}
                    >
                      Submit {pluralize('request', queuedWithdrawals.length)}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsCancelConfirmOpen(true)}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </>
          )}
        </CmtCardContent>
      </CmtCard>

      <AlertDialog
        open={isCancelConfirmOpen}
        onClose={() => setIsCancelConfirmOpen(false)}
        onConfirm={() => {
          setQueuedWithdrawals([]);
          setIsCancelConfirmOpen(false);
        }}
        title="Cancel all pending withdrawal requests?"
        content="This will remove all pending withdrawal requests from the table. Are you sure you want to continue?"
        cancelBtnProps={{ variant: 'outlined', color: 'primary' }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'OK',
        }}
      />
    </PageContainer>
  );
};

export default RequestWithdrawals;
