import AlertDialog from 'components/AlertDialog';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';

const InvestmentWarningModal = ({ onConfirm, onCancel, isOpen }) => (
  <AlertDialog
    open={isOpen}
    title={'Investment amount changed'}
    content={
      <>
        <Typography>
          Upon saving this change, percentage-based initial, recurring and
          one-off fees/discounts will be recalculated with the new investment
          amount as the basis. Any payable commission will also be updated.
        </Typography>

        <Typography>
          If the investment's advice fee needs to change along with the
          investment amount, please contact{' '}
          <Link href={'mailto:support@joinfurther.com'}>
            support@joinfurther.com
          </Link>{' '}
          for assistance. This does not change automatically.
        </Typography>
      </>
    }
    onConfirm={onConfirm}
    onClose={onCancel}
    btnLabels={{
      confirm: 'Save changes',
      cancel: 'Go back',
    }}
  />
);

export default InvestmentWarningModal;
