import moment from 'moment';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { dateToLabel, renderMultiple } from 'further-ui/utils';

export const exportExcelSheet = (exportInvestment) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`Investments-${Date.now()}`);
  worksheet.columns = [
    { header: 'Investor name', key: 'investorName' },
    { header: 'Email address', key: 'email' },
    { header: 'Date of birth', key: 'dob' },
    { header: 'Phone number', key: 'phoneNumber' },
    {
      header: 'Firm',
      key: 'firm',
    },
    {
      header: 'Fund',
      key: 'fund',
    },
    {
      header: 'Investment date',
      key: 'investmentDate',
      style: { numFmt: 'dd/mm/yyyy' },
    },
    {
      header: 'Investment amount',
      key: 'investmentAmount',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Adviser',
      key: 'adviser',
    },
    {
      header: 'Adviser email',
      key: 'adviserEmail',
    },
    {
      header: 'Advised client suitability assessment',
      key: 'advisedClientSuitabilityAssessment',
    },
    {
      header: 'Adviser fee',
      key: 'adviserFee',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Commission',
      key: 'commission',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'External investment ID',
      key: 'externalInvestmentID',
    },
    {
      header: 'Initial investment balance',
      key: 'initialInvestmentBalance',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Amount invested',
      key: 'amountInvested',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Cash fees on initial investment',
      key: 'cashFeesOnInitialInvestment',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Undeployed initial investment',
      key: 'undeployedInitialInvestment',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Reserved for future cash fees',
      key: 'reservedForFutureCashFees',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Transferred to investor cash balance',
      key: 'transferredToInvestorCashBalance',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Held by fund awaiting investment',
      key: 'heldByFundAwaitingInvestment',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Total exit value',
      key: 'totalExitValue',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Dividends',
      key: 'dividends',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Interest',
      key: 'interest',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header:
        'Undeployed initial investment transferred to investor cash balance',
      key: 'undeployedInitialInvestmentTransferredToInvestorCashBalance',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Less accrued fees charged to investor',
      key: 'lessAccruedFeesChargedToInvestor',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Total realised return',
      key: 'totalRealisedReturn',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Current value of unexited shareholdings',
      key: 'currentValueOfUnexitedShareholdings',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Undeployed initial investment held by fund',
      key: 'undeployedInitialInvestmentHeldByFund',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Less accrued fees not yet charged',
      key: 'lessAccruedFeesNotYetCharged',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Total unrealised return',
      key: 'totalUnrealisedReturn',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Total investment value',
      key: 'totalInvestmentValue',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Multiple on invested capital',
      key: 'multipleOnInvestment',
    },
    {
      header: 'Multiple on invested capital',
      key: 'multipleOnInvestedCapital',
    },
  ];
  exportInvestment?.forEach((item) => {
    const {
      investorId,
      fullName,
      firmId,
      fundId,
      investmentAmount,
      investmentDate,
      investmentBalance = {},
      adviserFee,
      adviserId = {},
      commissionFee,
      externalInvestmentId,
      advisedClientCertifiedAt = null,
    } = item;
    const {
      deploymentSummary,
      realisedValue,
      unrealisedValue,
      investmentValue,
      valueChangesOnInitialInvestment = {},
    } = investmentBalance;
    worksheet.addRows([
      {
        investorName: fullName,
        email: investorId?.email,
        dob: dateToLabel(investorId?.dob),
        phoneNumber: investorId?.phoneNumber,
        firm: firmId?.firmName,
        fund: fundId?.fundName,
        investmentDate: new Date(investmentDate),
        investmentAmount,
        adviser: adviserId?.fullName ? adviserId.fullName : 'N/A',
        adviserEmail: adviserId?.email,
        advisedClientSuitabilityAssessment: advisedClientCertifiedAt
          ? `Confirmed by ${adviserId?.fullName} - ${moment(
              advisedClientCertifiedAt,
            ).format('DD/MM/YYYY LT')}`
          : 'N/A - Direct investment',
        adviserFee: adviserFee || 0,
        commission: commissionFee || 0,
        externalInvestmentID: externalInvestmentId,
        initialInvestmentBalance: deploymentSummary?.initialInvestmentAmount,
        amountInvested: deploymentSummary?.purchasePriceOfAllShareholdings,
        cashFeesOnInitialInvestment:
          deploymentSummary?.cashFeesOnInitialInvestment,
        undeployedInitialInvestment:
          deploymentSummary?.undeployedInitialInvestment,
        reservedForFutureCashFees: deploymentSummary?.reservedForFutureCashFee,
        transferredToInvestorCashBalance:
          deploymentSummary?.transferredToInvestorCashBalance,
        heldByFundAwaitingInvestment:
          deploymentSummary?.heldByFundAwaitingInvestment,
        totalExitValue: realisedValue?.exitProceeds,
        dividends: realisedValue?.dividends,
        interest: realisedValue?.interest,
        undeployedInitialInvestmentTransferredToInvestorCashBalance:
          realisedValue?.residualCash,
        lessAccruedFeesChargedToInvestor:
          realisedValue?.accruedFeesChargedToInvestor,
        totalRealisedReturn: realisedValue?.total,
        currentValueOfUnexitedShareholdings:
          unrealisedValue?.valueOfAllShareholdings,
        undeployedInitialInvestmentHeldByFund:
          deploymentSummary?.undeployedInitialInvestmentHeldByFund,
        lessAccruedFeesNotYetCharged: unrealisedValue?.accruedFeesNotYetCharged,
        totalUnrealisedReturn: unrealisedValue?.total,
        totalInvestmentValue: investmentValue,
        multipleOnInvestment: renderMultiple(
          valueChangesOnInitialInvestment.multipleOnInvestment,
        ),
        multipleOnInvestedCapital: renderMultiple(
          valueChangesOnInitialInvestment.multipleOnInvestedCapital,
        ),
      },
    ]);
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Investments-${Date.now()}.xlsx`,
    );
  });
};
