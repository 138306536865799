import { FC } from 'react';
import { AttachFile, Comment } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
  },
}));

type Props = {
  elm: {
    notes: string;
    attachment?: string;
    attachmentSignedGetUrl?: string;
  };
};

const NotesAndAttachmentColumn: FC<Props> = ({ elm }) => {
  const classes = useStyles();

  if (!elm?.notes && !elm?.attachment) {
    return <span>-</span>;
  }

  return (
    <div className={classes.flex}>
      {elm.notes && (
        <Tooltip title={elm.notes}>
          <IconButton color="primary">
            <Comment style={{ fontSize: 22 }} />
          </IconButton>
        </Tooltip>
      )}
      {elm.attachmentSignedGetUrl && (
        <a
          href={elm.attachmentSignedGetUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          <IconButton color="primary">
            <AttachFile style={{ fontSize: 22 }} />
          </IconButton>
        </a>
      )}
    </div>
  );
};

export default NotesAndAttachmentColumn;
