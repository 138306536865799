import { api } from 'lib/httpClient';
import { useQuery } from '@tanstack/react-query';

async function fetchInvestee({ queryKey }) {
  try {
    const [, params] = queryKey;
    const {
      data: { data },
    } = await api.get(`investor-reporting/investee`, { params });
    return data;
  } catch (error) {
    throw error;
  }
}

export function useReportingInvestee({ firmId, submitDate, enabled }) {
  return useQuery({
    queryKey: ['reporting-investee', { firmId, submitDate }],
    queryFn: fetchInvestee,
    enabled,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
}
