import {
  GET_INVESTOR_DETAIL,
  RESET_INVESTOR,
  UPDATE_INVESTOR_QUESTION,
} from 'constants/actionTypes';

const INIT_STATE = {
  investorDetail: {},
  isLoading: false,
};

const Investor = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVESTOR_DETAIL: {
      return { ...state, investorDetail: action.payload };
    }
    case UPDATE_INVESTOR_QUESTION: {
      state.investorDetail.questionnaireData[0] = { ...action.payload };
      return { ...state };
    }
    case RESET_INVESTOR: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

export default Investor;
