export const CashBalanceItemTypeToLabelMap = {
  INVESTMENT_RESIDUAL_CASH: 'Residual cash',
  INVESTMENT_RESIDUAL_CASH_REVERSAL: 'Residual cash return',
  INVESTMENT_FROZEN_CASH: 'Frozen investment cash',
  EXIT: 'Exit',
  EXIT_AMENDMENT: 'Exit Amendment',
  WITHDRAWAL: 'Withdrawal',
  REJECTED_WITHDRAWAL: 'Rejected withdrawal',
  NEW_INVESTMENT: 'Move to new investment',
  INTEREST: 'Interest',
  DIVIDEND: 'Dividend',
  MANUAL_ADJUSTMENT: 'Manual adjustment',
  INVESTOR_FEE: 'Investor balance fee',
  INVESTOR_DISCOUNT: 'Investor discount',
  TRANSFER: 'Transfer',
};
