import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

interface AppDatePickerProps extends KeyboardDatePickerProps {
  openOnFocus?: boolean;
  clearable?: boolean;
}

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AppDatePicker: React.FunctionComponent<AppDatePickerProps> = ({
  name,
  id,
  value,
  onChange,
  helperText,
  error,
  className,
  disableFuture,
  disabled,
  variant = 'dialog',
  inputVariant = 'outlined',
  fullWidth = true,
  size = 'small',
  openOnFocus = false,
  clearable,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <KeyboardDatePicker
        variant={variant || 'inline'}
        inputVariant={inputVariant}
        format="DD/MM/YYYY"
        name={name}
        disableFuture={disableFuture}
        id={id || name}
        fullWidth={fullWidth}
        size={size}
        value={value}
        onChange={onChange}
        autoOk
        helperText={helperText}
        error={error}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        TextFieldComponent={(props) => (
          <TextField {...props} onClick={() => !disabled && setOpen(true)} />
        )}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onFocus={openOnFocus ? () => setOpen(true) : undefined}
        className={`${className || 'form-control'}`}
        disabled={disabled}
        {...rest}
      />
      {clearable && (
        <IconButton onClick={() => onChange(null)}>
          <Clear />
        </IconButton>
      )}
    </div>
  );
};

export default AppDatePicker;
