import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getInvestorDetail } from '@redux/actions/Investor';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import AppTextInput from 'components/FormElements/AppTextInput';
import Table from 'components/Table';
import commonColumns from 'helpers/withdrawals/commonColumns';
import NumberFormat from 'react-number-format';
import { FileUploadButton } from 'components/FileUploadButton';
import useFileUpload from 'hooks/ui/useFileUpload';
import { UploadType } from 'further-types/files';

const useStyles = makeStyles((theme) => ({
  alignTop: {
    verticalAlign: 'top',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  dropzone: {
    minHeight: 0,
  },
}));

const InvestorWithdrawalForm = ({
  investorId,
  addInvestorToWithdrawalQueue,
  queuedWithdrawals,
  firmId,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [investorDetail, setInvestorDetail] = useState();
  const [amountToWithdraw, setAmountToWithdraw] = useState('');
  const [amountToWithdrawError, setAmountToWithdrawError] = useState('');
  const [note, setNote] = useState('');
  const [attachment, setAttachment] = useState();
  const [attachmentUploadError, setAttachmentUploadError] = useState();
  const [attachmentUploading, setAttachmentUploading] = useState(false);
  const { uploadFile } = useFileUpload();
  const classes = useStyles();

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const response = await dispatch(getInvestorDetail(investorId));
        setInvestorDetail(response);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    if (investorId) fetch();
    // reset the form each time the investorId
    // changes (i.e. when a new investor is selected)
    resetForm();
  }, [investorId]);

  const resetForm = () => {
    setAmountToWithdraw('');
    setNote('');
    setAmountToWithdrawError('');
    setInvestorDetail(undefined);
    setAttachment();
  };

  const validate = () => {
    const firmCashBalance = investorDetail.cashBalances?.find(
      (cb) => cb.firm._id === firmId,
    )?.cashBalance;

    if (!firmCashBalance || Number(firmCashBalance) <= 0) {
      setAmountToWithdrawError('Investor has nothing available to withdraw');
      return false;
    }
    if (!amountToWithdraw || Number(amountToWithdraw) <= 0) {
      setAmountToWithdrawError('Amount to withdraw must be greater than 0');
      return false;
    }
    if (Number(amountToWithdraw) > Number(firmCashBalance)) {
      setAmountToWithdrawError(
        'Amount to withdraw must be less than or equal to the available balance',
      );
      return false;
    }
    return true;
  };

  const onAttachmentUpload = async (file) => {
    if (!file?.name) return;

    try {
      setAttachmentUploading(true);

      const { filePath, url } = await uploadFile(
        file,
        UploadType.WithdrawalAttachments,
        firmId,
      );

      setAttachment({ key: filePath, signedGetUrl: url });
      setAttachmentUploading(false);
    } catch (err) {
      setAttachmentUploading(false);
      setAttachmentUploadError(
        "Something went wrong - the file couldn't be uploaded",
      );
    }
  };

  const onAttachmentDelete = () => {
    setAttachment();
    setAttachmentUploadError();
  };

  const columns = [
    ...commonColumns(firmId),
    {
      label: 'Amount to withdraw',
      key: 'amountToWithdraw',
      sort: false,
      tdClassname: !!amountToWithdrawError && classes.alignTop,
      render: (elm) => (
        <NumberFormat
          decimalScale={2}
          onValueChange={(values) => {
            setAmountToWithdraw(values.value);
            setAmountToWithdrawError('');
          }}
          value={amountToWithdraw}
          prefix="£"
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={true}
          customInput={AppTextInput}
          error={!!amountToWithdrawError}
          helperText={amountToWithdrawError}
        />
      ),
    },
    {
      label: 'Notes',
      key: 'note',
      sort: false,
      tdClassname: !!amountToWithdrawError && classes.alignTop,
      render: (elm) => (
        <AppTextInput onChange={(e) => setNote(e.target.value)} value={note} />
      ),
    },
    {
      label: 'Attachment',
      key: 'attachment',
      sort: false,
      tdClassname: !!amountToWithdrawError && classes.alignTop,
      render: (elm) => (
        <FileUploadButton
          onUpload={onAttachmentUpload}
          onDelete={onAttachmentDelete}
          uploadError={attachmentUploadError}
          isUploading={attachmentUploading}
        />
      ),
    },
    {
      label: '',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <Button
          color="primary"
          variant="contained"
          className={classes.nowrap}
          onClick={() => {
            if (!validate()) return;
            if (attachmentUploading) return;
            addInvestorToWithdrawalQueue(investorDetail, {
              amountToWithdraw,
              note,
              attachment,
            });
            resetForm();
          }}
        >
          Add request
        </Button>
      ),
    },
  ];

  return loading ? (
    <CircularProgress size={20} />
  ) : (
    <Table
      columns={columns.filter((col) => !col.hideCol)}
      tablebody={investorDetail ? [investorDetail] : []}
      emptyMessage={
        queuedWithdrawals.length > 0
          ? 'Select another investor from the list'
          : 'First select an investor from the list'
      }
      variant="nohover"
    />
  );
};

export default InvestorWithdrawalForm;
