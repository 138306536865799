import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/GridContainer';
import FieldRow from 'components/FormElements/FieldRow';
import { useGetRole } from 'hooks/ui/useGetRole';
import { numberToPercentageString } from 'utils/numbers';

type Props = {
  changeToInitialInvestmentFees: any;
  commissionOnInitialInvestmentFees: any;
  firmsInFeeAmendments: any;
};

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    gridGap: '2rem',
  },
  nameCell: {
    minWidth: '8rem',
  },
  pcCell: {
    minWidth: '3rem',
  },
}));

const AdviserFeeAmendments = ({
  changeToInitialInvestmentFees,
  commissionOnInitialInvestmentFees,
  firmsInFeeAmendments,
}: Props) => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();

  const getFirmName = (id: string) =>
    firmsInFeeAmendments?.find((firm: any) => firm._id === id)?.firmName ??
    'Unknown firm';

  return (
    <>
      <Grid item md={12} xs={12}>
        <GridContainer alignItems="center">
          <FieldRow
            title="Changes to initial investment fees for this adviser"
            centerTitle
          >
            {changeToInitialInvestmentFees.length === 0 && (
              <div className={classes.row}>
                <span className={classes.nameCell}>No changes</span>
              </div>
            )}
            {changeToInitialInvestmentFees.map(
              (feeAmendment: any, i: number) => (
                <div className={classes.row} key={i}>
                  {isSuperAdmin && (
                    <span className={classes.nameCell}>
                      {getFirmName(feeAmendment.firmId)}
                    </span>
                  )}
                  <span className={classes.pcCell}>
                    {numberToPercentageString(feeAmendment.amendByPercentage, {
                      accuracy: 2,
                      noMultiply: true,
                    })}
                  </span>
                  {Number(feeAmendment.vatPercentage) > 0 && (
                    <span>
                      +{' '}
                      {numberToPercentageString(feeAmendment.vatPercentage, {
                        accuracy: 2,
                        noMultiply: true,
                      })}{' '}
                      VAT
                    </span>
                  )}
                </div>
              ),
            )}
          </FieldRow>
        </GridContainer>
      </Grid>

      <Grid item md={12} xs={12}>
        <GridContainer alignItems="center">
          <FieldRow title="Commission" centerTitle>
            {commissionOnInitialInvestmentFees.length === 0 && (
              <div className={classes.row}>
                <span className={classes.nameCell}>No changes</span>
              </div>
            )}
            {commissionOnInitialInvestmentFees.map(
              (feeAmendment: any, i: number) => (
                <div className={classes.row} key={i}>
                  {isSuperAdmin && (
                    <span className={classes.nameCell}>
                      {getFirmName(feeAmendment.firmId)}
                    </span>
                  )}
                  <span className={classes.pcCell}>
                    {numberToPercentageString(feeAmendment.amendByPercentage, {
                      accuracy: 2,
                      noMultiply: true,
                    })}
                  </span>
                  {Number(feeAmendment.vatPercentage) > 0 && (
                    <span>
                      +{' '}
                      {numberToPercentageString(feeAmendment.vatPercentage, {
                        accuracy: 2,
                        noMultiply: true,
                      })}{' '}
                      VAT
                    </span>
                  )}
                </div>
              ),
            )}
          </FieldRow>
        </GridContainer>
      </Grid>
    </>
  );
};

export default AdviserFeeAmendments;
