import { useParams, Link } from 'react-router-dom';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import FieldRow from 'components/FormElements/FieldRow';
import { createInvestorViewRoute } from 'constants/routes';
import AppTextInput from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import AdviserSelect from './AdviserSelect';
import InvestmentsFreeze from './InvestmentFreeze';
import { CompareArrows } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import { sumBy } from 'lodash';
import TransferLabel from './TransferLabel';
import { useDownloadByInvestmentId } from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#656565',
    fontSize: 22,
  },
  label: {
    color: '#656565',
    fontSize: 12,
  },
  value: {
    color: '#656565',
    fontWeight: 'bold',
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18,
  },
  endField: {
    display: 'flex',
    height: 32,
  },
}));

const InvestmentDetails = ({
  shareholdings,
  investmentDetails,
  setInvestmentDetails,
  isInvestmentDetailsLoaded,
  frozenOn,
  fees,
  hasFrozenFundsMoved,
  availableFunds,
  transferredInvestments,
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const freezeDisclosure = useDisclosure();
  const downloadEisCerts = useDownloadByInvestmentId();

  const handleDownload = async () =>
    downloadEisCerts.download({
      investmentId: id,
      fullName,
      trancheName: `${firmName}-${fundName}`,
    });

  const {
    investorId,
    fullName,
    firmName,
    fundName,
    investmentDate,
    investmentAmount,
    adviser,
    externalInvestmentId,
    advisedClientCertifiedAt,
    adviserFee,
    commissionFee,
    isAllocationExists,
  } = investmentDetails;

  const isFrozen = frozenOn && new Date() >= new Date(frozenOn);

  const recentShareholdingDate =
    (shareholdings ?? [])
      .map((shareholding) => new Date(shareholding.date))
      .sort((date1, date2) => date2 - date1)[0] ?? null;

  const totalTransferredAmount = sumBy(
    transferredInvestments,
    'investmentAmount',
  );
  const transferTooltipText = `This investment was originally ${numberToCurrencyString(
    totalTransferredAmount + (investmentDetails?.investmentAmount ?? 0),
  )}. ${numberToCurrencyString(
    totalTransferredAmount,
  )} has been transferred as set out on this page.`;

  return (
    <>
      <InvestmentsFreeze
        investmentId={id}
        frozenOn={frozenOn}
        freezeDisclosure={freezeDisclosure}
        fees={fees}
        hasFrozenFundsMoved={!!hasFrozenFundsMoved}
        availableFunds={availableFunds}
        recentShareholdingDate={recentShareholdingDate}
      />
      {transferredInvestments.map((transferredInvestment) => (
        <TransferLabel
          key={transferredInvestment._id}
          investment={transferredInvestment}
        />
      ))}
      <div className={classes.headerContainer}>
        <Typography variant="h2" className={classes.heading}>
          Investor details
        </Typography>
        <div className="flex">
          {!isFrozen ? (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className="mr-2"
              onClick={freezeDisclosure.onOpen}
            >
              Freeze investment
            </Button>
          ) : null}
          {!!isAllocationExists && (
            <Button
              className="mr-2"
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleDownload}
            >
              Download all EIS certificates
            </Button>
          )}

          <Link to={createInvestorViewRoute(investorId)}>
            <Button size="small" variant="outlined" color="primary">
              View investor
            </Button>
          </Link>
        </div>
      </div>
      <GridContainer>
        <FieldRow title="Investor name" centerTitle>
          {fullName}
        </FieldRow>
        <FieldRow title="Firm" centerTitle>
          {firmName}
        </FieldRow>
        <FieldRow title="Fund" centerTitle>
          {fundName}
        </FieldRow>
        <FieldRow title="Investment date" centerTitle>
          {dateToLabel(investmentDate)}
        </FieldRow>
        <FieldRow
          title="Investment amount"
          centerTitle
          tooltipText={
            <>
              This is the investment amount, excluding advice fee.
              <br />
              <br />
              The investment amount is able to be edited before any allocations
              have taken place. If you wish to make any adjustments after this,
              please delete the allocation(s) first.
            </>
          }
        >
          {shareholdings?.length ? (
            <Box alignItems="center" display="flex" gridGap={4}>
              {numberToCurrencyString(investmentDetails?.investmentAmount)}
              {totalTransferredAmount > 0 ? (
                <Tooltip title={transferTooltipText}>
                  <CompareArrows />
                </Tooltip>
              ) : null}
            </Box>
          ) : (
            <AppTextInput
              type={'number'}
              name="investmentAmount"
              variant="outlined"
              style={{ maxWidth: 300 }}
              value={investmentAmount ?? 0}
              gbpStartAdornment={true}
              onChange={(event) =>
                setInvestmentDetails({
                  ...investmentDetails,
                  investmentAmount: Number(event.target.value) ?? 0,
                })
              }
            />
          )}
        </FieldRow>
        <FieldRow title="Adviser" centerTitle>
          <AdviserSelect
            adviser={adviser}
            style={{ maxWidth: 300 }}
            onChange={(_event, adviser) =>
              setInvestmentDetails({
                ...investmentDetails,
                adviser,
              })
            }
            isInvestmentDetailsLoaded={isInvestmentDetailsLoaded}
          />
        </FieldRow>
        <FieldRow title="Advised client suitability assessment" centerTitle>
          {advisedClientCertifiedAt ? (
            <span>
              Confirmed by {adviser?.fullName} -{' '}
              {dateToLabel(advisedClientCertifiedAt, '-', true)}
            </span>
          ) : (
            'N/A - Direct investment'
          )}
        </FieldRow>
        <FieldRow title="Advice fee" centerTitle>
          {numberToCurrencyString(adviserFee || 0)}
        </FieldRow>
        <FieldRow title="Commission" centerTitle>
          {numberToCurrencyString(commissionFee || 0)}
        </FieldRow>
        <FieldRow title="External investment ID" centerTitle>
          <AppTextInput
            name="externalInvestmentId"
            variant="outlined"
            style={{ maxWidth: 300 }}
            value={externalInvestmentId}
            onChange={(event) =>
              setInvestmentDetails({
                ...investmentDetails,
                externalInvestmentId: event.target.value,
              })
            }
          />
        </FieldRow>
      </GridContainer>
      <div className={classes.endField}></div>
    </>
  );
};

export default InvestmentDetails;
