import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { CashBalanceItemTypeToLabelMap } from 'constants/cashBalanceProperties';

type Fund = {
  fundName: string;
};

type CashBalance = {
  firm: {
    firmName: string;
  };
  ledger: Array<{
    attachmentSignedGetUrl: string;
    balance: number;
    date: string;
    moneyIn: number;
    moneyOut: number;
    notes: string;
    type?: string;
    recipient?: {
      fullName: string;
    };
    investment: {
      fundId: Fund;
      investmentDate: string;
    };
    company?: {
      isVCT: boolean;
      firmId?: {
        firmName: string;
      };
      fundId?: Fund;
      tradingName: string;
    };
  }>;
};

type InvestorDetails = {
  fullName: string;
};

export const downloadCashBalance = (
  cashBalance: CashBalance,
  investorDetail: InvestorDetails,
) => {
  const { firm, ledger } = cashBalance;
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`Cash balance - ${firm.firmName}`);
  worksheet.columns = [
    { header: 'Date', key: 'date', width: 18 },
    { header: 'Type', key: 'type', width: 25 },
    { header: 'Company', key: 'company', width: 25 },
    { header: 'Details', key: 'investment', width: 25 },
    {
      header: 'Money in',
      key: 'moneyIn',
      width: 18,
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Money out',
      key: 'moneyOut',
      width: 18,
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Balance',
      key: 'balance',
      width: 18,
      style: { numFmt: '£#,##0.00' },
    },
    { header: 'Notes', key: 'notes', width: 25 },
  ];

  for (const record of ledger) {
    worksheet.addRows([
      {
        date: dateToLabel(record.date),
        type: CashBalanceItemTypeToLabelMap[record.type],
        company: record.company
          ? record.company.isVCT
            ? `${record.company.firmId?.firmName} ${record.company.fundId?.fundName}`
            : record.company.tradingName
          : '',
        investment:
          record?.type === 'TRANSFER' && record.recipient
            ? `Transferred to ${record.recipient.fullName}`
            : record.investment
            ? `${record.investment.fundId?.fundName} - ${dateToLabel(
                record.investment.investmentDate,
              )}`
            : record?.notes ?? '',
        moneyIn: numberToCurrencyString(record.moneyIn),
        moneyOut: numberToCurrencyString(record.moneyOut),
        balance: numberToCurrencyString(record.balance),
        notes: record.notes
          ? record.type !== 'INVESTOR_FEE' &&
            record.type !== 'INVESTOR_DISCOUNT'
            ? record.notes
            : ''
          : '',
      },
    ]);
  }

  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${investorDetail?.fullName}-${firm.firmName}-cash-balance.xlsx`,
    );
  });
};
