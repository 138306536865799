import React from 'react';

import FieldRow from 'components/FormElements/FieldRow';
import Switch from 'components/Switch';
import TextField from 'components/FormElements/AppTextInput';
import CkEditor from 'components/CkEditor';
import GridContainer from 'components/GridContainer';
import MultiFirmTrancheSelector from 'components/MultiFirmTrancheSelector';
import MultiFirmCompanySelector from 'components/MultiFirmCompanySelector';

const AddCustomUpdateDetails = ({ classes, data, errorText, setData }) => {
  const onChange = (event) => {
    const { value, name } = event.target;
    data[name] = value;
    setData({ ...data });
  };

  return (
    <GridContainer item md={11}>
      <FieldRow title="Select Company">
        <MultiFirmCompanySelector
          handleCompanyMultiSelectChanges={(value) => {
            setData({ ...data, ...value, companyId: value.selectedCompanyIds });
          }}
          error={errorText?.companyId}
          disabled={data?.trancheId?.length}
          value={data?.companyId}
        />
      </FieldRow>

      <FieldRow title="or select Tranche(s)">
        <MultiFirmTrancheSelector
          handleChangeSelectedTranches={(value) => {
            setData({ ...data, ...value, trancheId: value.selectedTrancheIds });
          }}
          trancheFilters={{ fundStatus: [0, 1] }}
          disabled={data?.companyId?.length}
          value={data?.trancheId}
        />
      </FieldRow>
      <FieldRow title="Send to advised investors?" centerTitle>
        <Switch
          inputProps={{ 'aria-label': 'Switch' }}
          checked={data.sendToAdvisedInvestor}
          onChange={(event) => {
            setData({
              ...data,
              sendToAdvisedInvestor: event.target.checked,
              addAdviserToCC: false,
            });
          }}
        />
      </FieldRow>
      {data?.sendToAdvisedInvestor && (
        <FieldRow title="Copy (CC:) to advisers" centerTitle>
          <Switch
            inputProps={{ 'aria-label': 'Switch' }}
            checked={data.addAdviserToCC}
            onChange={(event) =>
              setData({ ...data, addAdviserToCC: event.target.checked })
            }
          />
        </FieldRow>
      )}
      <FieldRow title="Subject line*" centerTitle>
        <TextField
          name="subject"
          placeholder="e.g. An update on Company X’s performance"
          required
          fullWidth
          error={errorText?.subject}
          helperText={errorText?.subject}
          value={data.subject}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Email title*" centerTitle>
        <TextField
          name="title"
          placeholder="e.g. Company X - an update"
          required
          fullWidth
          value={data.title}
          error={errorText?.title}
          helperText={errorText?.title}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Email body*" centerTitle>
        Dear $investorfirstname,
        <CkEditor
          name="emailBody"
          placeholder="e.g. An update on Company X’s performance"
          onChange={(event, editor) => {
            const value = editor.getData();
            setData({
              ...data,
              emailBody: value,
            });
          }}
          value={data.emailBody}
        />
        <p className={classes.errorCk}>{errorText?.emailBody}</p>
      </FieldRow>
      <FieldRow title="Manager name*" centerTitle>
        <TextField
          name="managerName"
          placeholder="Enter manager name "
          required
          fullWidth
          value={data.managerName}
          error={errorText?.managerName}
          helperText={errorText?.managerName}
          onChange={onChange}
        />
      </FieldRow>
    </GridContainer>
  );
};

export default AddCustomUpdateDetails;
