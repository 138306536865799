import React, { PropsWithChildren } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import PageBreadcrumbs from './PageBreadcrumbs';
import PageHeader from './PageHeader';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  pageFull: {
    width: '100%',
  },
}));

interface PageContainerInterface extends BoxProps {
  heading?: string;
  breadcrumbs?: any;
}

const PageContainer: React.FC<PropsWithChildren<PageContainerInterface>> = ({
  heading,
  breadcrumbs,
  children,
  className,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Slide in={true} direction="up" mountOnEnter unmountOnExit>
      <Box className={clsx(classes.pageFull, className)} {...restProps}>
        {(heading || breadcrumbs) && (
          <PageHeader
            heading={heading}
            breadcrumbComponent={
              breadcrumbs && <PageBreadcrumbs items={breadcrumbs} />
            }
            children={undefined}
          />
        )}
        {children}
      </Box>
    </Slide>
  );
};

export default PageContainer;
