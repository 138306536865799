import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { InvestmentPaymentType } from 'further-types/payment';
import { useNotification } from 'hooks/ui/useNotification';

export type Payment = {
  _id: string;
  investorId: string;
  investmentId: {
    _id: string;
    investmentAmount: number;
    investmentDate: Date;
    isPaymentReceived: boolean;
    source: string;
  };
  type: InvestmentPaymentType;
  paymentReceived?: boolean;
  items: [];
  amount: number;
  sentAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

type Response = {
  responseMsg: string;
  data: Payment[];
};

function usePaymentsQuery(investmentId: string) {
  return useQuery({
    queryKey: ['payments', investmentId],
    queryFn: async () => {
      const { data } = await api.get(
        `investment/get-payment?id=${investmentId}`,
      );
      return data;
    },
    select: (data?: Response) => data?.data,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: !!investmentId,
  });
}

function useMarkPaymentReceived(investmentId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (paymentId: string) => {
      const { data } = await api.put(
        `investment/payment-received/${paymentId}`,
      );
      return data;
    },
    onSuccess: async () =>
      queryClient.invalidateQueries({ queryKey: ['payments', investmentId] }),
  });
}

export function usePayments(investmentId: string) {
  const fetchPayments = usePaymentsQuery(investmentId);
  const markPaymentReceived = useMarkPaymentReceived(investmentId);
  const notification = useNotification();

  useEffect(
    function handleErrors() {
      // @ts-ignore
      const fetchError = fetchPayments?.error?.response.data.responseMsg;

      if (fetchError?.length) {
        notification.error(fetchError);
      }

      const paymentReceivedError =
        // @ts-ignore
        markPaymentReceived.error?.response.data.responseMsg;

      if (paymentReceivedError?.length) {
        notification.error(paymentReceivedError);
      }
    },
    [fetchPayments.error, markPaymentReceived.error],
  );

  useEffect(
    function handlePaymentReceivedSuccess() {
      const successMessage = markPaymentReceived.data?.responseMsg;

      if (successMessage?.length) {
        notification.success(successMessage);
      }
    },
    [markPaymentReceived.data?.responseMsg],
  );

  return { fetchPayments, markPaymentReceived };
}
