import { FC, ReactNode } from 'react';
import Table from 'components/Table';
import { makeStyles, Typography } from '@material-ui/core';
import useInvestorChangeLog from 'hooks/data/investor/useInvestorChangeLog';
import { getFormattedAddress, dateToLabel } from 'further-ui/utils';
import { investorTypeToLabel } from 'utils/investorType';
import { startCase } from 'lodash';
import { Api } from 'further-types/investor';

const useStyles = makeStyles(() => ({
  emptyPill: {
    padding: '3px 16px 2px 16px',
    borderRadius: '4px',
    border: '1px solid #c5c5c5',
    textTransform: 'uppercase',
    fontSize: '0.65rem',
    color: '#828282',
    marginLeft: '7px',
  },
}));

type Props = {
  investorId: string;
  headerClassName: string;
};

const getChangedByLabel = (
  changedBy: Api.InvestorChangeLogEntry['changedBy'],
  investorId: string,
) => {
  const name = `${changedBy?.firstName ?? ''} ${changedBy?.lastName ?? ''}`;
  const useRoleName = changedBy?.userType === 'adminUser';
  const isChangedBySelf =
    changedBy?.userType === 'investor' && changedBy?._id === investorId;

  if (isChangedBySelf) return 'This investor';

  if (!changedBy) return '';

  return useRoleName
    ? `${name} (${changedBy?.role?.name})`
    : `${name} (${startCase(changedBy?.userType)})`;
};

const formatEntry = (
  entry: [string, any],
  multiFieldChange: boolean,
  emptyBadge: ReactNode,
) => {
  const [field, value] = entry;
  const isAddress = field === 'address';

  if (isAddress && Array.isArray(value) && value.length > 0) {
    return (
      <div>
        {multiFieldChange ? <span>{startCase(field)}: </span> : null}
        <span>{value.map(getFormattedAddress)}</span>
      </div>
    );
  }

  const formatArrayValue = (arr: any[]) => {
    if (arr.length === 0) return '';
    return arr.every((item) => item.country)
      ? arr.map((item) => item.country).join(', ')
      : arr.join(', ');
  };

  const formatStringValue = (value: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}/;
    if (dateRegex.test(value)) {
      return dateToLabel(value);
    } else if (field === 'investorType') {
      return investorTypeToLabel(value);
    } else {
      return value;
    }
  };

  const formatValue = (value: any) => {
    if (value === null || value === undefined || value === '')
      return emptyBadge;
    if (typeof value === 'string') return formatStringValue(value);
    if (Array.isArray(value)) return formatArrayValue(value);
    return JSON.stringify(value); // as a fallback just output whatever we have
  };

  return (
    <div>
      {multiFieldChange ? <span>{startCase(field)}: </span> : null}
      <span>{formatValue(value)}</span>
    </div>
  );
};

const InvestorChangeLog: FC<Props> = ({ investorId, headerClassName }) => {
  const { data } = useInvestorChangeLog(investorId);
  const classes = useStyles();

  const emptyBadge = <span className={classes.emptyPill}>Empty</span>;

  const columns = [
    {
      label: 'Details changed',
      key: 'detailsChanged',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        elm.detailsChanged.length > 1
          ? 'Various'
          : startCase(elm.detailsChanged[0]),
    },
    {
      label: 'Original details',
      key: 'originalDetails',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        Object.entries(elm.originalDetails).map((entry) =>
          formatEntry(
            entry,
            Object.keys(elm.originalDetails).length > 1,
            emptyBadge,
          ),
        ),
    },
    {
      label: 'New details',
      key: 'newDetails',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        Object.entries(elm.newDetails).map((entry) =>
          formatEntry(
            entry,
            Object.keys(elm.newDetails).length > 1,
            emptyBadge,
          ),
        ),
    },
    {
      label: 'Date changed',
      key: 'date',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        dateToLabel(elm.date, 'N/A', true),
    },
    {
      label: 'Changed by',
      key: 'changedBy',
      sort: false,
      render: (elm: Api.InvestorChangeLogEntry) =>
        getChangedByLabel(elm.changedBy, investorId),
    },
  ];

  return (
    <>
      <Typography variant="h2" className={headerClassName}>
        Change log
      </Typography>
      <Table
        columns={columns}
        tablebody={data ?? []}
        variant="nohover"
        emptyMessage="This investor has no changes."
        pagination={false}
      />
    </>
  );
};

export default InvestorChangeLog;
