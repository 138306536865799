import React from 'react';
import { makeStyles } from '@material-ui/core';
import CmtAvatar from 'components/CmtAvatar';
import FieldRow from 'components/FormElements/FieldRow';

const useStyles = makeStyles(() => ({
  cardDetail: {
    display: 'flex',
  },
  marginLeft: {
    marginLeft: 10,
  },
}));

const CompanyCard = ({ company }) => {
  const classes = useStyles();
  return (
    <FieldRow
      title="Company tile preview"
      informationText="This tile will show in the middle of your email, as previewed here."
    >
      <div className={classes.cardDetail}>
        <CmtAvatar size={40} src={company?.companyLogo} />
        <div className={classes.marginLeft}>
          <b>{company?.tradingName || '-'}</b>
          <p
            dangerouslySetInnerHTML={{
              __html: company?.description,
            }}
          />
        </div>
      </div>
    </FieldRow>
  );
};

export default CompanyCard;
