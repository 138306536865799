import axios from 'axios';
import {
  GET_INVESTOR_DETAIL,
  UPDATE_INVESTOR_QUESTION,
  RESET_INVESTOR,
} from 'constants/actionTypes';

export const updateDisabled = (data) => {
  return () => {
    return axios
      .put(`investor/update-disable`, { ...data })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      });
  };
};

export const deleteInvestor = (id) => {
  return () => {
    return axios
      .delete(`investor/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const getInvestorDetail = (id) => {
  return (dispatch) => {
    return axios
      .get(`investor/list/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_INVESTOR_DETAIL,
            payload: response.data.data,
          });
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const resetInvestor = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_INVESTOR,
    });
  };
};
export const updateInvestorQuestion = (id) => {
  return (dispatch) => {
    return axios
      .put(`investor/allow-investor-question/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_INVESTOR_QUESTION,
            payload: response.data.data,
          });
          return Promise.resolve(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const manuallyPassAppropriateness = (id) => {
  return () => {
    return axios
      .put(`investor/manually-pass-appropriateness/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getAddress = (value) => {
  return () => {
    return axios
      .get(`investor/get-uk-address`, { params: { input: value } })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};
export const getQuestionList = () => {
  return () => {
    return axios
      .get(`investor/question-list?isAdmin=true`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};
