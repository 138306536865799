import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import CardHeader from 'components/CardHeader';
import Summary from '../Add/Summary';
import { INVESTOR_REPORTING } from 'constants/routes';
import { getCompanyLabel } from 'further-ui/utils';

import { useCustomUpdate } from 'hooks/data/customUpdate/useCustomUpdate';

const useStyles = makeStyles((theme) => ({
  cardContentPadding: {
    padding: '2rem',
  },
  divider: {
    margin: '30px 0',
  },
}));

const ViewCustomUpdate = () => {
  const { id } = useParams();
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    {
      label: 'Investor Reporting',
      link: INVESTOR_REPORTING,
    },
  ];
  const heading = 'Investor Reporting: View custom update';
  const classes = useStyles();
  const { fectchCustomUpdate } = useCustomUpdate();
  const { data } = fectchCustomUpdate(id);

  let selectedTranchesOrCompanies = [];
  if (data?.fundIds?.length) {
    selectedTranchesOrCompanies = data.fundIds.map((rec) => rec.fundName);
  } else {
    selectedTranchesOrCompanies = data?.companyIds?.map((rec) =>
      getCompanyLabel(rec),
    );
  }
  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent className={classes.cardContentPadding}>
          <CardHeader title={'Custom update summary'} />
          <Summary
            data={{
              ...data,
              numberOfRecipients: data?.investorIds?.length,
            }}
            classes={classes}
            selectedTranchesOrCompanies={selectedTranchesOrCompanies}
            isTrancheSelected={data?.fundIds?.length}
          />
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default ViewCustomUpdate;
