import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { exportWithdrawals } from '@redux/actions/Withdrawals';
import { Alert } from '@material-ui/lab';
import { useNotification } from 'hooks/ui/useNotification';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  mailto: {
    color: theme.palette.text.green,
    fontWeight: 'bold',
  },
}));

type Props = {
  withdrawalRequests: Array<unknown>;
  downloadOnly: boolean;
  onStatusUpdateSuccess: () => void;
  search: { isExportCustodian?: boolean };
  orderBy: string;
  order: string;
};

const ExportButton: React.FC<Props> = ({
  withdrawalRequests,
  downloadOnly,
  onStatusUpdateSuccess,
  search,
  orderBy,
  order,
}) => {
  const dispatch = useDispatch();
  const { success, error } = useNotification();
  const [loading, setLoading] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const noData = !withdrawalRequests || withdrawalRequests.length === 0;
  const classes = useStyles();

  const exportExcelSheet = async () => {
    try {
      setLoading(true);
      const shouldExportCustodian = !downloadOnly;
      const requestData = {
        ...search,
        orderBy,
        order,
      };
      if (shouldExportCustodian) {
        requestData.isExportCustodian = shouldExportCustodian;
      }

      await dispatch(exportWithdrawals(requestData));

      if (shouldExportCustodian) {
        onStatusUpdateSuccess();
        success(
          'Export file downloaded, and the status of these requests has been updated in our system.',
        );
      }
    } catch (e) {
      error(e.response.data.responseMsg);
      setUpdateError(true);
    } finally {
      setLoading(false);
    }
  };

  if (updateError) {
    return (
      <Alert severity="error">
        We were unable to update the status of the withdrawal requests in the
        system. They will still show as requests.{' '}
        <strong>
          If a file downloaded, please don't sent this file to your custodian.
        </strong>{' '}
        Instead, please refresh the page and try again, or contact{' '}
        <a className={classes.mailto} href="mailto:support@joinfurther.com">
          support@joinfurther.com
        </a>{' '}
        for assistance.
      </Alert>
    );
  }

  return downloadOnly ? (
    <Button
      color="primary"
      disabled={noData}
      variant="contained"
      onClick={exportExcelSheet}
    >
      General export
    </Button>
  ) : (
    <Button
      onClick={exportExcelSheet}
      disabled={noData || loading}
      color="primary"
      variant="contained"
    >
      {loading ? (
        <>
          <CircularProgress
            size={12}
            style={{ color: 'white', marginRight: '1rem' }}
          />
          <span>Download export</span>
        </>
      ) : (
        'Download export'
      )}
    </Button>
  );
};

export default ExportButton;
