import Grid from '@material-ui/core/Grid';
import { Fragment } from 'react';
import moment from 'moment';
import { format, parseISO } from 'date-fns';
import GridContainer from 'components/GridContainer';
import { dateofBirthToLabel } from 'further-ui/utils';
import DownloadTransferButton from '../DownloadTransferButton';
import useStyles from './styles';

type Props = {
  investorDetail: any;
};

const IndividualDetails: React.FC<Props> = ({ investorDetail }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>First name</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>{investorDetail?.firstName}</span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Middle name</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.middleName || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Last name</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.lastName || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Email address</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.email || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Phone number</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.phoneNumber || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Date of birth</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.dob
                ? dateofBirthToLabel(investorDetail?.dob)
                : '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Date registered</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.createdAt
                ? format(parseISO(investorDetail?.createdAt), 'dd/MM/yyyy')
                : '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Last login</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail.lastLoginDate
                ? moment
                    .utc(investorDetail.lastLoginDate)
                    .format('DD/MM/YYYY HH:mm:ss')
                : '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Profession</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>{investorDetail?.profession}</span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Email verified</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.isEmailVerify ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        {investorDetail.address && investorDetail.address.length
          ? investorDetail.address.map((single, index) => {
              return (
                <Fragment key={index}>
                  <GridContainer>
                    <Grid item xs={12} md={5}>
                      <span className={classes.label}>
                        {index === 0 ? 'Current address' : 'Previous address'}
                      </span>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      {single?.isManual ? (
                        <div className={classes.value}>
                          <div>{single?.address1}</div>
                          <div>{single?.address2}</div>
                          <div>{single?.city}</div>
                          <div>
                            {/* we force postcodes to be a string on investor upload but there may be some legacy 
                              number postcodes remaining so check type before converting to upperCase */}
                            {typeof single?.postcode === 'string'
                              ? single?.postcode.toUpperCase()
                              : single?.postcode}
                          </div>
                          <div>{single?.country}</div>
                        </div>
                      ) : (
                        <div className={classes.value}>
                          <div>{single?.address1}</div>
                          <div>{single?.address2}</div>
                          <div>{single?.city}</div>
                          <div>
                            {typeof single?.postcode === 'string'
                              ? single?.postcode.toUpperCase()
                              : single?.postcode}
                          </div>
                          <div>{single?.country}</div>
                        </div>
                      )}
                    </Grid>
                  </GridContainer>
                  {index === 0 && (
                    <GridContainer>
                      <Grid item xs={5}>
                        <span className={classes.label}>
                          Has lived at this address for past 3 years?
                        </span>
                      </Grid>
                      <Grid item xs={7}>
                        <span className={classes.value}>
                          {investorDetail?.address &&
                          investorDetail?.address.length === 1
                            ? 'Yes'
                            : 'No'}
                        </span>
                      </Grid>
                    </GridContainer>
                  )}
                </Fragment>
              );
            })
          : []}
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Nationality</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>{investorDetail?.nationality}</span>
          </Grid>
        </GridContainer>
      </Grid>

      {investorDetail.otherNationality?.length > 0 && (
        <Grid item xs={12} md={8}>
          <GridContainer>
            <Grid item xs={5}>
              <span className={classes.label}>
                Other{' '}
                {investorDetail.otherNationality.length > 1
                  ? 'nationalities'
                  : 'nationality'}
              </span>
            </Grid>
            <Grid item xs={7}>
              {investorDetail.otherNationality.map((record, index) => {
                return (
                  <div key={index}>
                    <span className={classes.value}>{record?.country}</span>
                  </div>
                );
              })}
            </Grid>
          </GridContainer>
        </Grid>
      )}

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>External investor ID</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.externalInvestorId || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Email disabled</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.disableAllEmails ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Vulnerable client</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.isVulnerableClient ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Deceased</span>
          </Grid>
          <Grid item xs={1}>
            <span className={classes.value}>
              {investorDetail?.isDeceased ? 'Yes' : 'No'}
            </span>
          </Grid>

          {investorDetail?.isDeceased && (
            <Grid item xs={8} md={5} className={classes.downloadTransferButton}>
              <DownloadTransferButton
                investorId={investorDetail._id}
                investorName={`${investorDetail.fullName}`}
              />
            </Grid>
          )}
        </GridContainer>
      </Grid>

      {investorDetail?.isDeceased && (
        <Grid item xs={12} md={8}>
          <GridContainer>
            <Grid item xs={5}>
              <span className={classes.label}>Date of death</span>
            </Grid>
            <Grid item xs={7}>
              <span className={classes.value}>
                {investorDetail?.dateOfDeath
                  ? dateofBirthToLabel(investorDetail?.dateOfDeath)
                  : '-'}
              </span>
            </Grid>
          </GridContainer>
        </Grid>
      )}

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Requires hard copy reports</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.requiresHardCopyReports ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
    </>
  );
};

export default IndividualDetails;
