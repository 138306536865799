import React, { ReactNode } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  SelectProps,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    '& .MuiOutlinedInput-input': {
      paddingTop: 10.5,
      paddingBottom: 10.5,
      backgroundColor: 'transparent',
      borderRadius: 4,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
  },
  cmSelect: {
    '& > div': {
      fontSize: '14px !important',
    },
  },
  label: {
    fontSize: '14px',
    marginBottom: 4,
    color: '#999999',
  },
}));

interface Props extends SelectProps {
  data: Array<any>;
  renderRow?: (item: any, index: number) => ReactNode;
  value?: any;
  isCheckedAll?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  valueKey?: string;
  labelKey?: string;
  backgroundColor?: string;
  selectAllLabel?: string;
}

const AppSelectBox: React.FC<Props> = ({
  data = [],
  renderRow,
  placeholder,
  name,
  id,
  onChange,
  label,
  value = '',
  variant = 'outlined',
  disabled = false,
  multiple = false,
  displayEmpty = true,
  fullWidth = true,
  required = false,
  error = false,
  isCheckedAll = false,
  helperText = '',
  size = 'small',
  valueKey = 'id',
  labelKey = 'title',
  selectAllLabel = 'Select all',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      className={clsx(classes.formControlRoot, 'form-control')}
    >
      {label && (
        <InputLabel className={classes.label} id={'label-' + id || name}>
          {label}
        </InputLabel>
      )}
      <Select
        className={classes.cmSelect}
        {...rest}
        name={name}
        labelId={'label-' + id || name}
        id={id || name}
        value={value}
        multiple={multiple}
        onChange={onChange}
        label={label ? (required ? label + ' *' : label) : undefined}
        error={error || helperText !== ''}
        displayEmpty={displayEmpty}
        IconComponent={ExpandMoreIcon}
      >
        {placeholder && displayEmpty && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}
        {multiple && (
          <MenuItem value={'all'}>
            <Checkbox
              color="primary"
              checked={isCheckedAll || data.length === value.length}
            />
            {selectAllLabel}
          </MenuItem>
        )}
        {data.map((item, index) =>
          renderRow ? (
            renderRow(item, index)
          ) : multiple ? (
            <MenuItem
              key={index}
              value={valueKey ? item[valueKey] : item}
              disabled={item?.disabled}
            >
              <Checkbox
                color="primary"
                checked={value.findIndex((rec) => rec === item[valueKey]) >= 0}
              />
              {item[labelKey]}
            </MenuItem>
          ) : (
            <MenuItem
              key={index}
              value={valueKey ? item[valueKey] : item}
              disabled={item?.disabled}
            >
              {item[labelKey]}
            </MenuItem>
          ),
        )}
      </Select>
      {helperText && (
        <FormHelperText error={error || helperText !== ''}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AppSelectBox;
