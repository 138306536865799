import { useNotification } from 'hooks/ui/useNotification';
import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';

type SelectedInvestor = {
  _id: string;
  fullName: string;
};

export function useBulkDownloadExitStatementPdf(
  exitStatementId: string,
  exitStatementTitle: string,
  selectedInvestors: SelectedInvestor[],
) {
  const notification = useNotification();

  const date = format(new Date(), 'dd-MM-yyyy');
  const filename = `${exitStatementTitle} Statements - ${date}`;

  async function requestBulkExitStatementPdfData() {
    const response = await api.post(
      `exit/statement/bulk-export-pdf-by-statement/${exitStatementId}`,
      {
        selectedInvestors,
        exitStatementTitle,
      },
      { responseType: 'blob' },
    );

    if (response.status !== 200) {
      throw new Error('Failed to fetch PDF zip data');
    }

    saveAs(response.data, filename);
  }

  return useMutation({
    mutationFn: requestBulkExitStatementPdfData,
    onError: () => {
      notification.error('Unable to download PDFs');
    },
  });
}
