import { useEffect, useState, Fragment } from 'react';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import { TrancheVisibility } from 'further-types/fund';
import FeeManagement from './FeeManagement';
import BasicInformation from './BasicInformation';
import TrancheDataSnapshot from './TrancheDataSnapshot';
import TrancheHighlight from './TrancheHighlight';
import TranchePromotionInformation from './TrancheInvestmentDocumentation';
import InvestmentStrategy from './InvestmentStrategy';
import TrackRecord from './TrackRecord';
import FeeDisplay from './FeeDisplay';
import Research from './Research';
import PressLink from './PressLink';
import BankDetails from './BankDetails';
import Faq from './Faq';
import FinalDealToggleAndLog from './FinalDealToggleAndLog';
import AdditionalInformation from './AdditionalInformation';
import ConfirmationEmailCustomisation from './ConfirmationEmailCustomisation';
import AccordionItem from './AccordionItem';
import { type } from 'constants/typeConstant';
import { useGetRole } from 'hooks/ui/useGetRole';
import { isValidEmail } from 'further-ui/utils';
import { useNotification } from 'hooks/ui/useNotification';
import useFileUpload from 'hooks/ui/useFileUpload';
import { UploadType } from 'further-types/files';
import { useTags } from 'hooks/data/tag/useTags';
import { useFund } from 'hooks/data/fund/useFund';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  heading: {
    fontWeight: 700,
  },
  dropZone: {
    minHeight: 0,
  },
  addBtn: {
    textAlign: 'right',
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  cmAcc: {
    border: '0 !important',
  },
  cmAccHeader: {
    padding: '0 25px',
  },
  cmAccDetail: {
    padding: '5px 25px 25px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const AddUpdateTranche = () => {
  const history = useHistory();
  const { isSuperAdmin, firmId: usersFirmId } = useGetRole();
  const { error: showError } = useNotification();
  const { uploadFile } = useFileUpload();
  const { id: idInUrl } = useParams();
  const [id, setId] = useState(idInUrl ?? '');
  const [isSyndicate, setIsSyndicate] = useState(false);
  const { get, edit, create } = useFund(id);

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Tranches', link: '/tranche' },
    {
      label: idInUrl ? 'Update Tranche' : 'Add Tranche',
      link: '/',
      isActive: true,
    },
  ];

  const { tags } = useTags();

  const [expanded, setExpanded] = useState('basicInformation');
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [trackRecord, setTrackRecord] = useState([
    {
      year: '',
      realisedReturn: '',
      unrealisedReturn: '',
      taxBenefit: '',
      subLabel: '',
      xAxisLabel: '',
    },
  ]);
  const [fee, setFee] = useState([
    {
      expectedAnnualisedFeeText: '',
      expectedAnnualisedFeeTextSubtitle: '',
      expectedAnnualisedFee: '',
      expectedAnnualisedFeeYear: 0,
    },
  ]);
  const [errorTextBasic, setErrorTextBasic] = useState({});
  const [errorTextKeyData, setErrorTextKeyData] = useState({});
  const [errorTextFundHighlight, setErrorTextFundHighlight] = useState({});

  const [errorText, setErrorText] = useState([
    { realisedReturn: '', unrealisedReturn: '' },
  ]);
  const [errorTextFee, setErrorTextFee] = useState([
    {
      expectedAnnualisedFeeText: '',
      expectedAnnualisedFeeTextSubtitle: '',
      expectedAnnualisedFee: '',
      expectedAnnualisedFeeYear: '',
    },
  ]);
  const [investmentStrategy, setInvestmentStrategy] = useState();
  const [trackRecordText, setTrackRecordText] = useState();
  const [additionalNote, setAdditionalNote] = useState();
  const [expectedAnnualisedFee, setExpectedAnnualisedFee] = useState(undefined);

  const [researchValue, setResearchValue] = useState([
    {
      researchLabel: '',
      researchProvidedBy: '',
      score: '',
      sampleText: '',
      researchFile: null,
      previewResearchFile: null,
    },
  ]);
  const [tagName, setTagName] = useState();
  const [bank, setBank] = useState({ openBankingMode: false });
  const [bankError, setBankError] = useState();
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [hasKiCertificate, setHasKiCertificate] = useState(false);
  const [additionalInfoError, setAdditionalInfoError] = useState();
  const [overallHurdleBasis, setOverallHurdleBasis] = useState('');
  const [overallFeesInHurdle, setOverallFeesInHurdle] = useState();

  const [value, setValue] = useState({
    firmId: usersFirmId || '',
    fundName: '',
    fundStatus: 1,
    startDate: null,
    finishDate: null,
    tag: null,
    order: '',
    fundClosedSoon: false,
    fundClosedSoonText: '',
    fundClosedMessage: '',
    isViewFund: false,
    isProfessionalClientsOnly: false,
    trancheVisibility: TrancheVisibility.ShowOnFirm,
    highestHistoricReturn: '',
    fundTag1: '',
    fundTag2: '',
  });
  const [keyData, setKeyData] = useState({
    fundSize: '',
    fundAum: '',
    sector: '',
    fundGeography: '',
    launchedIn: '',
    sizeOfCurrentRaise: '',
    incomeTaxRebate: '',
    capitalGainsRelief: true,
    inheritanceTaxRelief: true,
    lossProtection: true,
    targetReturn: '',
    expectedHoldPeriod: '',
    targetPortfolioSize: '',
    currentNumberOfCompanies: '',
    targetAnnualDividend: '',
    minimumInvestment: '',
    vctReturnData: '',
    vctReturnDataLabel: '',
    targetFundSize: '',
  });
  const [tranchePromotion, setTranchePromotion] = useState({
    applicationFormFile: null,
    keyInfoFile: null,
    prospectusFile: null,
    prospectusFilePreview: null,
    keyInfoFilePreview: null,
    applicationFormFilePreview: null,
  });
  const [fundHighlight, setFundHighlight] = useState({
    fundDescription: '',
    isChooseMedia: '0',
    link: '',
  });
  const [pressLink, setPressLink] = useState([
    { label: '', publication: '', link: '', date: '' },
  ]);
  const [errorPressLink, setErrorPressLink] = useState([
    { label: '', link: '', date: '', publication: '' },
  ]);
  const [errorFaq, setErrorFaq] = useState([{ question: '', answer: '' }]);
  const [gallaryImage, setGallaryImage] = useState([]);
  const [previewGallery, setPreviewGallery] = useState([]);
  const [feeDescription, setFeeDescription] = useState();
  const [faq, setFaq] = useState([{ question: '', answer: '' }]);
  const [investment, setInvestment] = useState([]);
  const [feeManagement, setFeeManagement] = useState({
    key: 'feeManagement',
    coreFees: [],
    performanceFees: [],
  });
  const [feeManagementError, setFeeManagementError] = useState({
    coreFee: [],
    performanceFees: [],
  });
  const [confirmationEmailCustomisation, setConfirmationEmailCustomisation] =
    useState({
      whatToExpectNow:
        'We are now processing your application. If any questions arise as we complete this process, our team may contact you. If you do not hear from us, your subscription has been accepted successfully.',
      additionalAttachment: null,
      additionalAttachmentPreview: null,
    });

  const calculateCoreFee = (vat, netFee, index) => {
    if (vat) {
      feeManagement.coreFees[index]['grossFee'] = Number(
        (netFee * 1.2).toFixed(10),
      );
    } else {
      feeManagement.coreFees[index]['grossFee'] = netFee;
    }
  };

  const onChangeCoreFee = (event, index) => {
    const { name, value } = event.target;
    if (name === 'vat') {
      calculateCoreFee(value, feeManagement.coreFees[index].netFee, index);
    } else if (name === 'netFee') {
      calculateCoreFee(feeManagement.coreFees[index].vat, value, index);
    }
    if (name === 'displayFeeType') {
      let isInitial = false;
      let isFixed = false;
      if (
        type.addFeeTypes.initialFixed === value ||
        type.addFeeTypes.initialPercentage === value
      ) {
        isInitial = true;
      }
      if (
        type.addFeeTypes.initialFixed === value ||
        type.addFeeTypes.recurringFixed === value
      ) {
        isFixed = true;
      }
      feeManagement.coreFees[index].feeCalculationBasis = isFixed
        ? type.feeCalculationBasis.fixed
        : type.feeCalculationBasis.percentage;
      feeManagement.coreFees[index].feeType = isInitial
        ? type.feeTypes.initial
        : type.feeTypes.recurring;
      if (isInitial) {
        delete feeManagement.coreFees[index].chargingFrequency;
        delete feeManagement.coreFees[index].startingPeriod;
        delete feeManagement.coreFees[index].finishingPeriod;
      } else {
        feeManagement.coreFees[index].chargingFrequency =
          feeManagement?.coreFees[index]?.chargingFrequency || null;
        feeManagement.coreFees[index].startingPeriod =
          feeManagement?.coreFees[index]?.startingPeriod || null;
        feeManagement.coreFees[index].finishingPeriod =
          feeManagement?.coreFees[index]?.finishingPeriod || null;
      }
    }
    feeManagement.coreFees[index][name] = value;
    setFeeManagement({ ...feeManagement });
  };

  const calculateFeePerformance = (vat, netFee, index) => {
    if (vat) {
      feeManagement.performanceFees[index]['feePercentageIncVAT'] = (
        netFee * 1.2
      ).toFixed(2);
    } else {
      feeManagement.performanceFees[index]['feePercentageIncVAT'] = netFee;
    }
  };

  const onChangePerformanceFees = (event, index) => {
    const { name, value } = event.target;

    if (name === 'hurdleBasis') {
      setOverallHurdleBasis(value);
    }

    if (name === 'includeFeesInHurdle') {
      setOverallFeesInHurdle(value);
    }

    if (!index && index !== 0) {
      // if no index supplied, apply the value to all performance fees
      feeManagement.performanceFees.forEach((fee) => {
        fee[name] = value;
      });
    } else {
      if (name === 'vatPercentage') {
        calculateFeePerformance(
          value,
          feeManagement.performanceFees[index].feePercentageExVAT,
          index,
        );
      } else if (name === 'feePercentageExVAT') {
        calculateFeePerformance(
          feeManagement.performanceFees[index].vatPercentage,
          value,
          index,
        );
      }
      feeManagement.performanceFees[index][name] = value;
    }
    setFeeManagement({ ...feeManagement });
  };

  const handleAddClick = () => {
    setTrackRecord([
      ...trackRecord,
      {
        year: '',
        realisedReturn: '',
        unrealisedReturn: '',
        taxBenefit: '',
        xAxisLabel: '',
        subLabel: '',
      },
    ]);
  };
  const handleRemoveClick = (index) => {
    const list = [...trackRecord];
    list.splice(index, 1);
    setTrackRecord(list);
  };
  const handleAddClickPressLink = () => {
    setPressLink([
      ...pressLink,
      { label: '', link: '', publication: '', date: null },
    ]);
  };
  const handleAddClickFaq = () => {
    setFaq([...faq, { question: '', answer: '' }]);
  };
  const handleRemoveClickFaq = (index) => {
    const list = [...faq];
    list.splice(index, 1);
    setFaq(list);
  };
  const handleRemoveClickPressLink = (index) => {
    const list = [...pressLink];
    list.splice(index, 1);
    setPressLink(list);
  };
  const handleInputChangeFaq = (e, index) => {
    const { name, value } = e.target;
    const list = [...faq];
    list[index][name] = value;
    setFaq(list);
  };
  const ckEditorHandleChange = (data, index) => {
    faq[index]['answer'] = data;
    setFaq([...faq]);
  };
  const handleInputChangeAdditional = (e) => {
    const { value } = e.target;
    setAdditionalInfo(value);
  };

  const handleAddClickFee = () => {
    setFee([
      ...fee,
      {
        expectedAnnualisedFeeText: '',
        expectedAnnualisedFeeTextSubtitle: '',
        expectedAnnualisedFee: '',
      },
    ]);
  };
  const handleRemoveClickFee = (index) => {
    const list = [...fee];
    list.splice(index, 1);
    setFee(list);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...trackRecord];
    list[index][name] = value;
    setTrackRecord(list);
  };
  const handleInputChangeFee = (e, index) => {
    const { name, value } = e.target;
    const list = [...fee];
    list[index][name] = value;
    setFee(list);
  };
  const handleInputChangePressLink = (e, index, flag) => {
    if (flag) {
      const list = [...pressLink];
      list[index]['date'] = e;
      setPressLink(list);
    } else {
      const { name, value } = e.target;
      const list = [...pressLink];
      list[index][name] = value;
      setPressLink(list);
    }
  };
  const validate = () => {
    let trackError = [];
    let isError = false;
    if (trackRecord) {
      trackRecord.forEach((singleRec) => {
        const errors = {};
        if (!singleRec.realisedReturn) {
          errors.realisedReturn = 'Please enter realised return';
          isError = true;
        }
        if (!singleRec.unrealisedReturn) {
          errors.unrealisedReturn = 'Please enter unrealised return';
          isError = true;
        }
        trackError.push(errors);
      });
      setErrorText(trackError);
    }

    return {
      trackError,
      isError,
    };
  };
  const validateFee = () => {
    let trackError = [];
    let isError = false;
    if (fee) {
      fee.forEach((singleRec) => {
        const errors = {};
        if (!singleRec.expectedAnnualisedFeeText) {
          errors.expectedAnnualisedFeeText = 'Please enter fee name.';
          isError = true;
        }
        if (!singleRec.expectedAnnualisedFeeTextSubtitle) {
          errors.expectedAnnualisedFeeTextSubtitle =
            'Please enter fee information.';
          isError = true;
        }
        if (!singleRec.expectedAnnualisedFee) {
          errors.expectedAnnualisedFee = 'Please enter fee percentage.';
          isError = true;
        }
        trackError.push(errors);
      });
      setErrorTextFee(trackError);
    }

    return {
      trackError,
      isError,
    };
  };
  const validatePresslink = () => {
    let trackError = [];
    let isError = false;
    if (pressLink) {
      pressLink.forEach((singleRec) => {
        const errors = {};
        if (!singleRec.label) {
          errors.label = 'Please enter label name';
          isError = true;
        }
        if (!singleRec.link) {
          errors.link = 'Please enter link.';
          isError = true;
        }
        if (!singleRec.publication) {
          errors.publication = 'Please enter publication.';
          isError = true;
        }
        if (!singleRec.date) {
          errors.date = 'Please select date.';
          isError = true;
        }
        trackError.push(errors);
      });
      setErrorPressLink(trackError);
    }
    return {
      trackError,
      isError,
    };
  };
  const validateBasicInformation = () => {
    const errors = {};
    let isError = false;
    if (!value.firmId) {
      errors.firmId = 'Please select firm.';
      isError = true;
    }
    if (!value.fundName) {
      errors.fundName = 'Please enter tranche name.';
      isError = true;
    }
    if (!value.finishDate) {
      errors.finishDate = 'Please select finish date.';
      isError = true;
    }
    if (value.tag === null) {
      errors.tag = 'Please select fund type.';
      isError = true;
    }
    if (
      value.fundClosedSoon &&
      !value.fundClosedSoonText &&
      value.fundStatus === 1
    ) {
      errors.fundClosedSoonText = 'Please enter fund closed soon text.';
      isError = true;
    }
    if (value.order && !/^\d+$/.test(value.order)) {
      errors.order = 'Order should be a number.';
      isError = true;
    }
    setErrorTextBasic(errors);
    return {
      errors,
      isError,
    };
  };
  const validateKeyData = () => {
    const errors = {};
    let isError = false;

    if (!/^£*[0-9]*\.?(.?:|[0-9]{0,2}|m|bn|tn)*$/.test(keyData.fundSize)) {
      errors.fundSize = 'The following characters are permitted: £ m bn tn.';
      isError = true;
    }
    if (!keyData.minimumInvestment) {
      errors.minimumInvestment = 'Please enter minimum investment.';
      isError = true;
    }

    if (!keyData.incomeTaxRebate) {
      errors.incomeTaxRebate = 'Please enter income tax relief.';
      isError = true;
    }

    if (!keyData.targetReturn) {
      errors.targetReturn = 'Please select target return.';
      isError = true;
    }

    if (!keyData.targetPortfolioSize) {
      errors.targetPortfolioSize = 'Please enter target portfolio size.';
      isError = true;
    }

    if (
      keyData.sizeOfCurrentRaise &&
      !/^£*[0-9]*\.?(.?:|[0-9]{0,2}|m|bn|tn)*$/.test(keyData.sizeOfCurrentRaise)
    ) {
      errors.sizeOfCurrentRaise =
        'The following characters are permitted: £ m bn tn.';
      isError = true;
    }

    setErrorTextKeyData(errors);

    return {
      errors,
      isError,
    };
  };
  const validateFundHighlight = () => {
    const errors = {};
    let isError = false;
    if (!fundHighlight.isChooseMedia) {
      errors.isChooseMedia = 'Please select is choose media.';
      isError = true;
    }
    setErrorTextFundHighlight(errors);
    return {
      errors,
      isError,
    };
  };
  const handleClick = async () => {
    let response;
    let validateData = validateBasicInformation();
    if (!validateData.isError) {
      const requestPayload = {
        key: 'basic',
        ...value,
      };
      if (id) {
        edit.mutate(requestPayload);
      } else {
        response = await create.mutateAsync(requestPayload);
        setId(response?._id);
      }
      setExpanded('trancheDataSnapshot');
    }
  };

  const handelClickAdditional = async () => {
    if (!additionalInfo) {
      setAdditionalInfoError('Please enter notification list.');
    } else {
      const trimmedAdditionalInfo = additionalInfo
        .split(';')
        .map((email) => email.trim());
      const isValid = trimmedAdditionalInfo.every((email) => {
        if (email) {
          return isValidEmail(email);
        }
        return true;
      });
      if (!isValid) {
        setAdditionalInfoError(
          'Separate emails with a semi-colon, e.g. name@domain.com;name2@domain.com',
        );
      } else {
        const value = {
          additionalInfo: trimmedAdditionalInfo.join(';'),
          key: 'additionalInfo',
        };
        edit.mutate(value);
        setAdditionalInfoError('');
        setExpanded('confirmationEmailCustomisation');
      }
    }
  };

  const handleClickKeyData = async () => {
    let validateData = validateKeyData();
    if (!validateData.isError) {
      const requestPayload = {
        key: 'keydata',
        ...keyData,
      };
      edit.mutate(requestPayload);
      setExpanded('trancheInvestmentDocumentation');
    }
  };
  const handleClickFundHighlight = async () => {
    let validateData = validateFundHighlight();
    if (!validateData.isError) {
      let url;
      if (fundHighlight.link) {
        url = fundHighlight.link.replace('watch?v=', 'embed/');
      }
      let galleryImages = [];
      if (gallaryImage?.length) {
        gallaryImage.forEach((singleImage) => {
          if (singleImage?.includes('.com/')) {
            galleryImages.push(singleImage.split('.com/')[1]);
          } else {
            galleryImages.push(singleImage);
          }
        });
      }
      const requestPayload = {
        gallaryImages: galleryImages,
        key: 'fundhighlight',
        fundDescription: fundHighlight?.fundDescription,
        isChooseMedia: fundHighlight.isChooseMedia,
        link: url,
      };
      edit.mutate(requestPayload);
      setExpanded('investmentStrategy');
    }
  };
  const handleClickTranchePromotion = async () => {
    if (tranchePromotion?.prospectusFile?.includes('.com/')) {
      tranchePromotion.prospectusFile =
        tranchePromotion?.prospectusFile.split('.com/')[1];
    }
    if (tranchePromotion?.applicationFormFile?.includes('.com/')) {
      tranchePromotion.applicationFormFile =
        tranchePromotion?.applicationFormFile.split('.com/')[1];
    }
    if (tranchePromotion?.keyInfoFile?.includes('.com/')) {
      tranchePromotion.keyInfoFile =
        tranchePromotion?.keyInfoFile.split('.com/')[1];
    }
    setTranchePromotion({ ...tranchePromotion });
    const value = {
      key: 'prospectus',
      prospectusFile: tranchePromotion?.prospectusFile,
      keyInfoFile: tranchePromotion?.keyInfoFile,
      applicationFormFile: tranchePromotion?.applicationFormFile,
    };
    edit.mutate(value);
    setExpanded('feeManagement');
  };

  const handleInputChangeEmailCustomisation = async (name, value) => {
    if (name === 'additionalAttachment') {
      if (!value?.[0]?.path) return;

      try {
        const { filePath } = await uploadFile(
          value[0],
          UploadType.FundDocuments,
        );
        confirmationEmailCustomisation[name] = filePath;
        confirmationEmailCustomisation[name + 'Preview'] = value[0].preview;
      } catch (err) {
        showError("Something went wrong - the file couldn't be uploaded");
      }
    } else if (name === 'additionalAttachmentRemove') {
      confirmationEmailCustomisation.additionalAttachment = null;
      confirmationEmailCustomisation.additionalAttachmentPreview = null;
    } else {
      confirmationEmailCustomisation[name] = value;
    }

    setConfirmationEmailCustomisation({ ...confirmationEmailCustomisation });
  };
  const handleClickConfirmationEmailCustomisation = async () => {
    if (
      confirmationEmailCustomisation?.additionalAttachment?.includes('.com/')
    ) {
      confirmationEmailCustomisation.additionalAttachment =
        confirmationEmailCustomisation?.additionalAttachment.split('.com/')[1];
    }

    edit.mutate({
      key: 'confirmationEmailCustomisation',
      ...confirmationEmailCustomisation,
    });
    setExpanded('trancheHighlights');
  };

  const handleClickBank = async () => {
    let error = false;
    let errors = {};
    if (!bank.accountName) {
      error = true;
      errors.accountName = 'Please enter account holder name.';
    } else if (!/^[a-zA-Z ]*$/.test(bank.accountName)) {
      errors.accountName = 'Only characters are allowed.';
      error = true;
    }
    if (!bank.accountNumber) {
      error = true;
      errors.accountNumber = 'Please enter bank account number.';
    } else if (!/^[0-9]{8}$/g.test(bank.accountNumber)) {
      error = true;
      errors.accountNumber = 'Please enter an 8 digit account number.';
    }
    if (!bank.sortcode) {
      error = true;
      errors.sortcode = 'Please enter bank sort code.';
    } else if (!/^[0-9]{6}$/g.test(bank.sortcode)) {
      error = true;
      errors.sortcode = 'Please enter an 6 digit account sortcode.';
    }
    if (!error) {
      let formData = new FormData();
      formData.append('key', 'bankDetails');
      formData.append('accountName', bank.accountName);
      formData.append('accountNumber', bank.accountNumber);
      formData.append('sortcode', bank.sortcode);
      formData.append('title', bank.title || '');
      formData.append('swift', bank.swift || '');
      formData.append('iban', bank.iban || '');
      formData.append('openBankingMode', bank.openBankingMode);
      edit.mutate(formData);
      setExpanded('additionalInformation');
      setBankError(null);
    } else {
      setBankError(errors);
    }
  };

  const handleClickTrackRecord = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      let value = {
        trackRecord,
        trackRecordText: trackRecordText ? trackRecordText : '',
        additionalNote: additionalNote ? additionalNote : '',
        key: 'trackrecord',
      };
      edit.mutate(value);
      setExpanded('fundPageFeeDisplay');
    }
  };
  const handleClickPressLink = async () => {
    let validateData = validatePresslink();
    if (!validateData.isError) {
      let value = {
        pressLink,
        key: 'press',
      };
      const response = await edit.mutateAsync(value);
      if (response?.pressLink.length) {
        var arr = [];
        response.pressLink.forEach((singlePress) => {
          singlePress.date = moment(singlePress.date).format('YYYY-MM-DD');
          arr.push(singlePress);
        });
        setPressLink(arr);
      }
      setExpanded('faqs');
    }
  };
  const validateFaq = () => {
    let trackError = [];
    let isError = false;
    if (faq) {
      faq.forEach((singleRec) => {
        const errors = {};
        if (!singleRec.question) {
          errors.question = 'Please enter question';
          isError = true;
        }
        if (!singleRec.answer) {
          errors.answer = 'Please enter answer';
          isError = true;
        }
        trackError.push(errors);
      });
      setErrorFaq(trackError);
    }
    return {
      trackError,
      isError,
    };
  };
  const handleClickFaq = async () => {
    let validateData = validateFaq();
    if (!validateData.isError) {
      let value = {
        faq,
        key: 'faq',
      };
      edit.mutate(value);
    }
  };
  const handleFee = async () => {
    let validateData = validateFee();
    if (!validateData.isError) {
      let value = {
        expectedAnnualisedFee: Number(expectedAnnualisedFee),
        feeDescription,
        fee,
        key: 'fees',
      };
      edit.mutate(value);
      setExpanded('research');
    }
  };
  const handleClickResearch = async () => {
    if (researchValue?.researchFile?.includes('.com/')) {
      researchValue.researchFile =
        researchValue?.researchFile.split('.com/')[1];
    }
    const requestPayload = {
      key: 'expert',
      researchProvidedBy: researchValue?.researchProvidedBy,
      sampleText: researchValue?.sampleText,
      researchLabel: researchValue?.researchLabel,
      score: researchValue?.score,
      researchFile: researchValue?.researchFile,
    };
    edit.mutate(requestPayload);
    setExpanded('pressLinks');
  };
  useEffect(() => {
    if (idInUrl) {
      const getTranchebyId = async () => {
        let response = get.data;
        if (response) {
          let value = {
            firmId: response?.firmId?._id,
            fundName: response?.fundName,
            fundStatus: response?.fundStatus,
            tag: response?.tag ? response?.tag?._id : '',
            startDate: response?.startDate,
            finishDate: moment(response.finishDate),
            fundClosedSoon: response?.fundClosedSoon,
            fundClosedSoonText: response?.fundClosedSoonText,
            fundClosedMessage: response?.fundClosedMessage
              ? response?.fundClosedMessage
              : '',
            isViewFund: response?.isViewFund,
            isProfessionalClientsOnly: response?.isProfessionalClientsOnly,
            trancheVisibility: response?.trancheVisibility
              ? response?.trancheVisibility
              : TrancheVisibility.ShowOnFirm,
            highestHistoricReturn: response?.highestHistoricReturn,
            fundTag1: response?.fundTag1,
            fundTag2: response?.fundTag2,
          };
          if (response.fundStatus === 1) {
            value = { ...value, order: response.order };
          }
          setValue({ ...value });
          setTagName(response?.tag?.name);
          setIsSyndicate(response.tag?.isSyndicate);
          setHasKiCertificate(response?.hasKiCertificate);
          setKeyData({
            fundSize: response?.fundSize,
            sector: response?.sector,
            sizeOfCurrentRaise: response?.sizeOfCurrentRaise,
            targetReturn: response?.targetReturn,
            targetPortfolioSize: response?.targetPortfolioSize,
            targetAnnualDividend: response?.targetAnnualDividend,
            currentNumberOfCompanies: response?.currentNumberOfCompanies,
            minimumInvestment: response?.minimumInvestment,
            expectedAnnualisedFee: response?.expectedAnnualisedFee,
            expectedHoldPeriod: response?.expectedHoldPeriod,
            lossProtection: response?.lossProtection,
            incomeTaxRebate: response?.incomeTaxRebate,
            capitalGainsRelief: response?.capitalGainsRelief,
            inheritanceTaxRelief: response?.inheritanceTaxRelief,
            fundGeography: response?.fundGeography,
            launchedIn: response?.launchedIn,
            fundAum: response?.fundAum,
            vctReturnData: response?.vctReturnData,
            vctReturnDataLabel: response?.vctReturnDataLabel,
            targetFundSize: response?.targetFundSize,
          });
          setBank({
            accountName: response?.accountName,
            accountNumber: response?.accountNumber,
            sortcode: response?.sortcode,
            title: response?.title,
            iban: response?.iban,
            swift: response?.swift,
            openBankingMode: response?.openBankingMode,
          });

          setFundHighlight({
            fundDescription: response?.fundDescription,
            link: response?.link,
            isChooseMedia: response?.isChooseMedia,
          });
          if (response?.pressLink.length) {
            var arr = [];
            response.pressLink.forEach((singlePress) => {
              singlePress.date = moment(singlePress.date).format('YYYY-MM-DD');
              arr.push(singlePress);
            });
            setPressLink(arr);
          }
          setInvestmentStrategy(response?.investmentStrategy);
          setGallaryImage(response?.gallaryImages);
          if (response?.gallaryImages?.length) {
            const preview = response?.gallaryImages?.map((singleImage) => ({
              preview: singleImage,
            }));
            setPreviewGallery(preview);
          }
          setFeeDescription(response?.feeDescription);
          setTrackRecordText(response?.trackRecordText);
          setAdditionalNote(response?.additionalNote);
          setResearchValue({
            researchLabel: response?.researchLabel,
            researchProvidedBy: response?.researchProvidedBy,
            sampleText: response?.sampleText,
            researchFile: response?.researchFile,
            score: response?.score,
            previewResearchFile: response?.researchFile,
          });
          setTrackRecord(response?.trackRecord);
          setFee(response?.fee);
          setExpectedAnnualisedFee(response?.expectedAnnualisedFee);
          setTranchePromotion({
            keyInfoFile: response?.keyInfoFile,
            applicationFormFile: response?.applicationFormFile,
            prospectusFile: response?.prospectusFile,
            keyInfoFilePreview: response?.keyInfoFile,
            applicationFormFilePreview: response?.applicationFormFile,
            prospectusFilePreview: response?.prospectusFile,
          });
          if (response.additionalInfo) {
            setAdditionalInfo(response?.additionalInfo);
          }
          setFaq(response?.faq);
          setInvestment(response.investment);
          response.coreFees?.forEach((singleFee) => {
            if (
              singleFee.feeCalculationBasis === type.feeCalculationBasis.fixed
            ) {
              singleFee.displayFeeType =
                singleFee.feeType === type.feeTypes.initial
                  ? type.addFeeTypes.initialFixed
                  : type.addFeeTypes.recurringFixed;
            } else {
              singleFee.displayFeeType =
                singleFee.feeType === type.feeTypes.initial
                  ? type.addFeeTypes.initialPercentage
                  : type.addFeeTypes.recurringPercentage;
            }
          });
          setFeeManagement({
            key: 'feeManagement',
            performanceFees: response?.performanceFees,
            coreFees: response?.coreFees,
            trancheHasHadAnExit: response?.trancheHasHadAnExit,
          });
          setOverallHurdleBasis(response?.performanceFees[0]?.hurdleBasis);
          setOverallFeesInHurdle(
            response?.performanceFees[0]?.includeFeesInHurdle,
          );
          if (response.whatToExpectNow) {
            confirmationEmailCustomisation.whatToExpectNow =
              response.whatToExpectNow;
          }
          setConfirmationEmailCustomisation({
            ...confirmationEmailCustomisation,
            additionalAttachment: response.additionalAttachment,
            additionalAttachmentPreview: response.additionalAttachment,
          });
          setLoading(false);
        }
      };
      getTranchebyId();
    } else {
      setLoading(false);
    }
  }, [id, idInUrl, get.data]);

  const handleInvestmentStrategy = async () => {
    let formData = new FormData();
    formData.append('key', 'investment');
    if (investmentStrategy) {
      formData.append('investmentStrategy', investmentStrategy);
    }
    edit.mutate(formData);
    setExpanded('trackRecord');
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (id) {
      setExpanded(isExpanded ? panel : false);
    }
  };
  const handleCancel = () => {
    history.push('/tranche');
  };
  const handleChangeTag = (event, item) => {
    setValue({ ...value, tag: event });
    setIsSyndicate(item.isSyndicate);
    setTagName(item?.name);
  };
  const handleDateChange = (date) => {
    setValue({ ...value, finishDate: date });
  };

  const onClickFeeManagement = async () => {
    let error = false;
    const { coreFees, performanceFees } = feeManagement;
    let coreError = [];
    if (coreFees?.length) {
      coreFees.forEach((coreFee) => {
        let data = {
          feeName: '',
          feeType: '',
          netFee: '',
          vat: '',
          chargingFrequency: '',
          startingPeriod: '',
          finishingPeriod: '',
        };
        if (!coreFee?.feeName) {
          data.feeName = 'Please enter fee name';
          error = true;
        }
        if (!coreFee?.feeType) {
          data.feeType = 'Please select fee type';
          error = true;
        } else {
          if (coreFee?.feeType === 'RECURRING') {
            if (!coreFee?.chargingFrequency) {
              data.chargingFrequency = 'Please enter charging frequency';
              error = true;
            }
            if (!coreFee?.startingPeriod) {
              data.startingPeriod = 'Please enter starting period';
              error = true;
            } else if (coreFee?.startingPeriod === '0') {
              data.startingPeriod = 'Lowest starting period is 1';
              error = true;
            }
            if (!coreFee?.finishingPeriod) {
              data.finishingPeriod = 'Please enter finishing period';
              error = true;
            } else if (coreFee?.finishingPeriod === '0') {
              data.finishingPeriod = 'Lowest finishing period is 1';
              error = true;
            } else if (
              parseInt(coreFee?.startingPeriod) >
              parseInt(coreFee?.finishingPeriod)
            ) {
              data.finishingPeriod =
                'Please ensure the finishing period is not before the starting period.';
              error = true;
            }
          }
        }
        if (!coreFee?.netFee) {
          data.netFee = 'Please enter net fee';
          error = true;
        }
        if (!coreFee?.vat) {
          if (coreFee?.vat !== 0) {
            data.vat = 'Please select VAT';
            error = true;
          }
        }
        if (!coreFee.feeAccounting) {
          data.feeAccounting = 'Please select fee accounting.';
          error = true;
        }
        coreError.push(data);
      });
      feeManagementError.coreFee = coreError;
    }
    let performError = [];
    if (performanceFees?.length) {
      performanceFees.forEach((rec) => {
        let data = {
          feeName: '',
          includeFeesInHurdle: '',
          feePercentageExVAT: '',
          vatPercentage: '',
          hurdle: '',
          hurdleBasis: '',
        };
        if (!rec?.feeName) {
          data.feeName = 'Please enter fee name.';
          error = true;
        }
        if (!rec?.includeFeesInHurdle && rec?.includeFeesInHurdle !== false) {
          data.includeFeesInHurdle = 'Please select fee type.';
          error = true;
        }
        if (!rec?.hurdle && rec?.hurdle !== 0) {
          data.hurdle = 'Please enter hurdle.';
          error = true;
        } else {
          if (parseFloat(rec?.hurdle) < 0) {
            data.hurdle = 'Hurdle should be greater or equal 0';
            error = true;
          }
        }
        if (!rec?.hurdleBasis) {
          data.hurdleBasis = 'Please select hurdle basis.';
          error = true;
        }
        if (!rec?.feePercentageExVAT) {
          data.feePercentageExVAT = 'Please enter net fee.';
          error = true;
        }
        if (!rec?.vatPercentage) {
          if (rec?.vatPercentage !== 0) {
            data.vatPercentage = 'Please select VAT.';
            error = true;
          }
        }

        performError.push(data);
      });
      feeManagementError.performanceFees = performError;
    }
    if (!error) {
      edit.mutate(feeManagement);
      setExpanded('bankDetails');
    } else {
      setFeeManagementError({ ...feeManagementError });
    }
  };

  const removePerformanceFee = (index, type) => {
    if (type === 'coreFee') {
      feeManagement.coreFees.splice(index, 1);
      if (feeManagementError?.coreFee?.length) {
        feeManagementError.coreFee.splice(index, 1);
      }
    } else {
      feeManagement.performanceFees.splice(index, 1);
      if (feeManagementError?.performanceFees?.length) {
        feeManagementError.performanceFees.splice(index, 1);
      }
    }
    setFeeManagement({ ...feeManagement });
    setFeeManagementError({ ...feeManagementError });
  };

  const handleGalleryImage = async (files) => {
    for (const file of files) {
      if (file.path) {
        try {
          const { filePath } = await uploadFile(file, UploadType.FundDocuments);

          gallaryImage.push(filePath);
          previewGallery.push({ preview: file?.preview });
        } catch (err) {
          showError("Something went wrong - the file couldn't be uploaded");
        }
      }
    }
    setPreviewGallery(previewGallery);
    setGallaryImage([...gallaryImage]);
  };
  const onChangeTranchePromotion = async (files, name) => {
    if (!files?.[0]?.path) return;

    try {
      const { filePath } = await uploadFile(files[0], UploadType.FundDocuments);
      tranchePromotion[name] = filePath;
      tranchePromotion[name + 'Preview'] = files[0].preview;
      setTranchePromotion({
        ...tranchePromotion,
      });
    } catch (err) {
      showError("Something went wrong - the file couldn't be uploaded");
    }
  };
  const onChangeResearchFile = async (files) => {
    if (!files?.[0]?.path) return;
    try {
      const { filePath } = await uploadFile(files[0], UploadType.FundDocuments);

      setResearchValue({
        ...researchValue,
        researchFile: filePath,
        previewResearchFile: files[0].preview,
      });
    } catch (err) {
      showError("Something went wrong - the file couldn't be uploaded");
    }
  };

  return (
    <Fragment>
      <PageContainer
        heading={id ? 'Tranche: Tranche details' : 'Add Tranche'}
        breadcrumbs={breadcrumbs}
      >
        {loading ? (
          ''
        ) : (
          <>
            <div style={{ backgroundColor: '#fff', padding: '0 40px' }}>
              <AccordionItem
                expanded={expanded}
                heading="Basic information"
                handleChange={handleChange}
                panelName="basicInformation"
              >
                <BasicInformation
                  errorTextBasic={errorTextBasic}
                  handleCancel={handleCancel}
                  handleChangeTag={handleChangeTag}
                  handleClick={handleClick}
                  handleDateChange={handleDateChange}
                  id={id}
                  investment={investment}
                  setValue={setValue}
                  tagList={tags.data ?? []}
                  tagName={tagName}
                  value={value}
                  disabled={edit.isLoading || create.isLoading}
                />
              </AccordionItem>

              <AccordionItem
                expanded={expanded}
                heading="Tranche data snapshot"
                handleChange={handleChange}
                panelName="trancheDataSnapshot"
                disabled={!id}
              >
                <TrancheDataSnapshot
                  errorTextKeyData={errorTextKeyData}
                  handleCancel={handleCancel}
                  disabled={edit.isLoading}
                  handleClickKeyData={handleClickKeyData}
                  id={id}
                  keyData={keyData}
                  setKeyData={setKeyData}
                  tagName={tagName}
                />
              </AccordionItem>

              <AccordionItem
                expanded={expanded}
                heading="Tranche investment documentation"
                handleChange={handleChange}
                panelName="trancheInvestmentDocumentation"
                disabled={!id}
              >
                <TranchePromotionInformation
                  handleCancel={handleCancel}
                  handleClickTranchePromotion={handleClickTranchePromotion}
                  id={id}
                  disabled={edit.isLoading}
                  onChangeTranchePromotion={onChangeTranchePromotion}
                  setTranchePromotion={setTranchePromotion}
                  isSyndicate={isSyndicate}
                  tranchePromotion={tranchePromotion}
                />
              </AccordionItem>
              <AccordionItem
                expanded={expanded}
                heading="Fee management"
                handleChange={handleChange}
                panelName="feeManagement"
                disabled={!id}
              >
                <GridContainer>
                  <Grid item xs={12}>
                    <FeeManagement
                      classes={classes}
                      feeManagement={feeManagement}
                      onChangeCoreFee={onChangeCoreFee}
                      onChangePerformanceFees={onChangePerformanceFees}
                      setFeeManagement={setFeeManagement}
                      feeManagementError={feeManagementError}
                      removePerformanceFee={removePerformanceFee}
                      overallHurdleBasis={overallHurdleBasis}
                      overallFeesInHurdle={overallFeesInHurdle}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.buttonContainer}>
                      <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.marginLeftBtn}
                        disabled={edit.isLoading}
                        color="primary"
                        onClick={onClickFeeManagement}
                      >
                        {id ? 'Update' : 'Save'}
                      </Button>
                    </div>
                  </Grid>
                </GridContainer>
              </AccordionItem>

              <AccordionItem
                expanded={expanded}
                heading="Bank details"
                handleChange={handleChange}
                panelName="bankDetails"
                disabled={!id}
              >
                <BankDetails
                  bank={bank}
                  bankError={bankError}
                  handleCancel={handleCancel}
                  handleClickBank={handleClickBank}
                  id={id}
                  disabled={edit.isLoading}
                  setBank={setBank}
                />
              </AccordionItem>
              <AccordionItem
                expanded={expanded}
                heading="Additional information"
                handleChange={handleChange}
                panelName="additionalInformation"
                disabled={!id}
              >
                <AdditionalInformation
                  additionalInfo={additionalInfo}
                  additionalInfoError={additionalInfoError}
                  handleCancel={handleCancel}
                  disabled={edit.isLoading}
                  handelClickAdditional={handelClickAdditional}
                  handleInputChangeAdditional={handleInputChangeAdditional}
                  id={id}
                />
              </AccordionItem>
              <AccordionItem
                expanded={expanded}
                heading="Confirmation email customisation"
                handleChange={handleChange}
                panelName="confirmationEmailCustomisation"
                disabled={!id}
              >
                <ConfirmationEmailCustomisation
                  confirmationEmailCustomisation={
                    confirmationEmailCustomisation
                  }
                  handleCancel={handleCancel}
                  disabled={edit.isLoading}
                  handleClickConfirmationEmailCustomisation={
                    handleClickConfirmationEmailCustomisation
                  }
                  handleInputChangeEmailCustomisation={
                    handleInputChangeEmailCustomisation
                  }
                  id={id}
                />
              </AccordionItem>

              {isSuperAdmin && (
                <>
                  <AccordionItem
                    expanded={expanded}
                    heading="Tranche highlights"
                    handleChange={handleChange}
                    panelName="trancheHighlights"
                    disabled={!id}
                  >
                    <TrancheHighlight
                      errorTextFundHighlight={errorTextFundHighlight}
                      fundHighlight={fundHighlight}
                      gallaryImage={gallaryImage}
                      handleCancel={handleCancel}
                      handleClickFundHighlight={handleClickFundHighlight}
                      handleGalleryImage={handleGalleryImage}
                      disabled={edit.isLoading}
                      id={id}
                      previewGallery={previewGallery}
                      setFundHighlight={setFundHighlight}
                      setGallaryImage={setGallaryImage}
                      setPreviewGallery={setPreviewGallery}
                    />
                  </AccordionItem>

                  <AccordionItem
                    expanded={expanded}
                    heading="Investment strategy"
                    handleChange={handleChange}
                    panelName="investmentStrategy"
                    disabled={!id}
                  >
                    <InvestmentStrategy
                      handleCancel={handleCancel}
                      handleInvestmentStrategy={handleInvestmentStrategy}
                      id={id}
                      disabled={edit.isLoading}
                      investmentStrategy={investmentStrategy}
                      setInvestmentStrategy={setInvestmentStrategy}
                    />
                  </AccordionItem>

                  <AccordionItem
                    expanded={expanded}
                    heading="Track record"
                    handleChange={handleChange}
                    panelName="trackRecord"
                    disabled={!id}
                  >
                    <TrackRecord
                      additionalNote={additionalNote}
                      errorText={errorText}
                      handleAddClick={handleAddClick}
                      handleCancel={handleCancel}
                      handleClickTrackRecord={handleClickTrackRecord}
                      handleInputChange={handleInputChange}
                      handleRemoveClick={handleRemoveClick}
                      disabled={edit.isLoading}
                      id={id}
                      setAdditionalNote={setAdditionalNote}
                      setTrackRecordText={setTrackRecordText}
                      trackRecord={trackRecord}
                      trackRecordText={trackRecordText}
                    />
                  </AccordionItem>

                  <AccordionItem
                    expanded={expanded}
                    heading="Fund page fee display"
                    handleChange={handleChange}
                    panelName="fundPageFeeDisplay"
                    disabled={!id}
                  >
                    <FeeDisplay
                      errorTextFee={errorTextFee}
                      fee={fee}
                      feeDescription={feeDescription}
                      handleAddClickFee={handleAddClickFee}
                      expectedAnnualisedFee={expectedAnnualisedFee}
                      setExpectedAnnualisedFee={setExpectedAnnualisedFee}
                      disabled={edit.isLoading}
                      handleCancel={handleCancel}
                      handleFee={handleFee}
                      handleInputChangeFee={handleInputChangeFee}
                      handleRemoveClickFee={handleRemoveClickFee}
                      id={id}
                      setFeeDescription={setFeeDescription}
                    />
                  </AccordionItem>

                  <AccordionItem
                    expanded={expanded}
                    heading="Research"
                    handleChange={handleChange}
                    panelName="research"
                    disabled={!id}
                  >
                    <Research
                      handleCancel={handleCancel}
                      handleClickResearch={handleClickResearch}
                      id={id}
                      disabled={edit.isLoading}
                      onChangeResearchFile={onChangeResearchFile}
                      researchValue={researchValue}
                      setResearchValue={setResearchValue}
                    />
                  </AccordionItem>

                  <AccordionItem
                    expanded={expanded}
                    heading="Press links"
                    handleChange={handleChange}
                    panelName="pressLinks"
                    disabled={!id}
                  >
                    <PressLink
                      errorPressLink={errorPressLink}
                      handleAddClickPressLink={handleAddClickPressLink}
                      handleCancel={handleCancel}
                      disabled={edit.isLoading}
                      handleClickPressLink={handleClickPressLink}
                      handleInputChangePressLink={handleInputChangePressLink}
                      handleRemoveClickPressLink={handleRemoveClickPressLink}
                      id={id}
                      pressLink={pressLink}
                    />
                  </AccordionItem>

                  <AccordionItem
                    expanded={expanded}
                    heading="FAQs"
                    handleChange={handleChange}
                    panelName="faqs"
                    disabled={!id}
                  >
                    <Faq
                      ckEditorHandleChange={ckEditorHandleChange}
                      errorFaq={errorFaq}
                      faq={faq}
                      disabled={edit.isLoading}
                      handleAddClickFaq={handleAddClickFaq}
                      handleCancel={handleCancel}
                      handleClickFaq={handleClickFaq}
                      handleInputChangeFaq={handleInputChangeFaq}
                      handleRemoveClickFaq={handleRemoveClickFaq}
                      id={id}
                    />
                  </AccordionItem>
                </>
              )}
              <AccordionItem
                expanded={expanded}
                heading="Final deal"
                handleChange={handleChange}
                panelName="finalDeal"
                disabled={!id}
              >
                {/* This one does not need a "disabled" as it's handled internally by this component. */}
                <FinalDealToggleAndLog
                  hasKiCertficate={hasKiCertificate}
                  fundId={id}
                />
              </AccordionItem>
            </div>
          </>
        )}
      </PageContainer>
    </Fragment>
  );
};
export default AddUpdateTranche;
