import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Delete,
  Edit,
  Visibility,
  Email,
  InfoOutlined,
} from '@material-ui/icons';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { deleteInvestor, updateDisabled } from '@redux/actions/Investor';
import { Link } from 'react-router-dom';
import {
  INVESTOR_ADD,
  createInvestorEditRoute,
  createInvestorViewRoute,
} from 'constants/routes';
import {
  useInvestorsExport,
  useInvestors,
} from 'hooks/data/investor/useInvestors';
import AlertDialog from 'components/AlertDialog';
import { dateToLabel, dateofBirthToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { Tooltip } from 'components/Tooltip';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useSortTranches } from 'hooks/ui/useSortTranches';
import { useFunds } from 'hooks/data/fund/useFunds';
import { usePagination } from 'hooks/ui/usePagination';
import { useDebounce } from 'usehooks-ts';
import Switch from 'components/Switch';
import InvestorsBulkActions from './InvestorsBulkActions';
import usePlatformInvites from 'hooks/data/investor/useInvestorPlatformInvites';
import { useNotification } from 'hooks/ui/useNotification';
import downloadFile from 'utils/downloadFile';
import { useFirm } from 'hooks/data/firm/useFirm';
import useFilters from 'hooks/ui/useFilters';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { CustomTheme } from 'theme/themeColors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.green,
  },
  deleteButton: {
    color: theme.palette.text.red,
  },
  dFlex: {
    display: 'flex',
  },
  marginSelect: {
    marginBottom: 10,
  },
  bulkActions: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  exportSpinner: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: 10,
  },
  buttonsGrid: {
    display: 'flex',
    gap: '8px;',
    justifyContent: 'flex-end',
  },
}));

const Investor = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deleteDisclosure = useDisclosure();
  const disabledDisclosure = useDisclosure();
  const { isSuperAdmin, firmId } = useGetRole();
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const { search } = useLocation();
  const history = useHistory();
  const { success, error } = useNotification();
  const handleApiRequest = useApiRequestHandler();
  const searchParams = new URLSearchParams(search);
  const { firms } = useFirm({ params: { firmId } });
  const pagination = usePagination({
    pageSize: 10,
    orderBy: 'createdAt',
    order: 'desc',
    id: 'investor-list',
  });
  const { filters, handleChangeFilters } = useFilters('investor-filter', {
    defaultFilters: {
      firmId,
      fundId: searchParams.get('fundId'),
      searchTerm: '',
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const {
    resendInvite: { mutate: resendInvite },
  } = usePlatformInvites({
    onResendInviteSuccess: () => {
      refetch();
      success('Investor invite sent successfully.');
    },
  });

  const [updatePermission, deletePermission] = useGetPermissions([
    'edit:investor',
    'delete:investor',
  ]);

  //@ts-ignore
  const { tranches } = useFunds({
    leanResponse: true,
  });
  const sortedTranches = useSortTranches(tranches?.data);

  const debouncedSearchTerm = useDebounce(filters.searchTerm, 400);
  const params = {
    page: pagination.page === 0 ? 1 : pagination.page,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
    keyName: pagination.orderBy,
    fundId: filters.fundId,
    search: debouncedSearchTerm,
    firmId: filters.firmId,
  };
  const { data, refetch, isFetching } = useInvestors({ params });
  const { exportInvestors, isExportLoading } = useInvestorsExport({
    params: { ...params, includeExportData: true },
  });

  const columns = useMemo(
    () => [
      {
        label: 'Investor name',
        key: 'fullName',
      },
      { label: 'Email', key: 'email' },
      {
        label: 'Birth/incorporation',
        key: 'birthOrIncorporation',
        render: (elm) => dateofBirthToLabel(elm.birthOrIncorporation),
      },
      {
        label: 'KYC',
        key: 'kycStatusLabel',
      },
      {
        label: 'Created date',
        key: 'createdAt',
        render: (elm) => dateToLabel(elm?.createdAt),
      },
      {
        label: 'Emails',
        key: 'disableAllEmails',
        render: (elm) => (elm?.disableAllEmails ? 'Disabled' : 'Live'),
      },
      {
        label: 'Last login date',
        key: 'lastLoginDate',
        render: (elm) => dateToLabel(elm?.lastLoginDate),
      },
      {
        label: 'Actions',
        sort: false,
        render: (elm) => (
          <div className={classes.dFlex}>
            {isSuperAdmin ? (
              <Tooltip title="Resending investor invites is only possible for firm admin users.">
                <span>
                  <Button disabled>
                    <Email />
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                title="This resends the investor invite and enables future emails to this investor."
                className={classes.customButton}
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  void handleResendPlatformInvite(elm._id);
                }}
              >
                <Email />
              </Button>
            )}
            {updatePermission ? (
              elm?.isEditAllow || isSuperAdmin ? (
                <Link to={createInvestorEditRoute(elm?._id)}>
                  <Button
                    title="Edit"
                    color="primary"
                    className={classes.customButton}
                  >
                    <Edit />
                  </Button>
                </Link>
              ) : (
                <Tooltip title="As this investor is connected with multiple funds, they cannot be edited manually. Please send edit requests to support@joinfurther.com.">
                  <span>
                    <Button disabled className={classes.customButton}>
                      <Edit />
                    </Button>
                  </span>
                </Tooltip>
              )
            ) : null}
            {elm?.isDeleteAllowed ? (
              <Button
                title="Delete"
                className={`${classes.customButton} ${classes.deleteButton}`}
                onClick={() => deleteDisclosure.stageAction({ id: elm._id })}
                disabled={!deletePermission}
              >
                <Delete htmlColor="#E8453C" />
              </Button>
            ) : (
              <Tooltip title="When an investor has made an investment, financial regulation prohibits account deletion.">
                <span>
                  <Button
                    className={`${classes.customButton} ${classes.deleteButton}`}
                    disabled={
                      !deletePermission ||
                      elm?.investmentData?.length ||
                      !elm?.isEditAllow
                    }
                  >
                    <Delete />
                  </Button>
                </span>
              </Tooltip>
            )}
            <Link to={createInvestorViewRoute(elm._id)}>
              <Button
                title="View"
                color="primary"
                className={classes.customButton}
              >
                <Visibility />
              </Button>
            </Link>
          </div>
        ),
      },
      isSuperAdmin && {
        label: (
          <span>
            Active?
            <Tooltip title="When an investor has made an investment, financial regulation prohibits account deletion. If an active user no longer wishes to use the Further platform, switch the green toggle off to deactivate the user.">
              <IconButton size="small">
                <InfoOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
        ),
        sort: false,
        render: (elm, index) =>
          elm?.investmentData?.length ? (
            <Tooltip
              title={
                elm?.isDisabled
                  ? 'Click to activate the user. Activated users are able to log in and view their portal.'
                  : 'Click to deactivate the user. Deactivated users are unable to log in and cannot view their portal.'
              }
            >
              <Switch
                inputProps={{ 'aria-label': 'Switch' }}
                checked={!elm?.isDisabled}
                onChange={(event) => handleSwitch(event, elm, index)}
                disabled={!isSuperAdmin}
              />
            </Tooltip>
          ) : (
            ''
          ),
      },
    ],
    [updatePermission, deletePermission, deleteDisclosure, isSuperAdmin],
  );

  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'Investors', isActive: true },
  ];

  const handleResendPlatformInvite = (id) => {
    resendInvite(id);
  };

  const handleSwitch = async (event, rec, index) => {
    data.result[index].isDisabled = event.target.checked;
    data.result[index].disableAllEmails = event.target.checked;
    if (!event.target.checked) {
      disabledDisclosure.stageAction({ id: rec._id });
    } else {
      handleApiRequest(async () => {
        await dispatch(
          updateDisabled({
            isDisabled: false,
            disableAllEmails: false,
            id: rec._id,
          }),
        );
        await refetch();
      });
    }
  };

  const handleDisable = async () => {
    handleApiRequest(async () => {
      await dispatch(
        updateDisabled({
          isDisabled: true,
          disableAllEmails: true,
          id: disabledDisclosure.actionProps.id,
        }),
      );
      disabledDisclosure.onClose();
      await refetch();
    }, 'Something went wrong');
  };

  const handleConfirmDelete = async () => {
    const investorId = deleteDisclosure.actionProps.id;
    await handleApiRequest(async (showSuccess) => {
      const response = await dispatch(deleteInvestor(investorId));
      if (response) {
        //@ts-ignore
        showSuccess(response.data.responseMsg);
        deleteDisclosure.onClose();
        await refetch();
      }
    });
  };

  const exportExcelData = async () => {
    const { data, error: exportError } = await exportInvestors();
    if (exportError) {
      error((exportError as Error).message);
      return;
    }
    if (data) {
      downloadFile(data, `Investors-${Date.now()}.xlsx`);
    }
  };
  const fundOptions = [
    { label: 'No investments', _id: 'no-investment' },
  ].concat(sortedTranches);

  return (
    <PageContainer heading="Investors" breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent>
          <GridContainer>
            {isSuperAdmin && (
              <Grid item md={3} xs={12} className={classes.marginSelect}>
                <Autocomplete
                  key={firms?.data?.result?.length}
                  //@ts-ignore
                  id="firmId"
                  options={firms?.data?.result}
                  getOptionLabel={(option) => option.firmName}
                  filterSelectedOptions
                  value={firms?.data?.result?.find(
                    (firm) => firm._id === filters.firmId,
                  )}
                  onChange={(_, newValue) => {
                    handleChangeFilters({ firmId: newValue?._id });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Firm"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item md={3} xs={12} className={classes.marginSelect}>
              <Autocomplete
                key={sortedTranches?.length}
                //@ts-ignore
                id="fundId"
                options={fundOptions}
                getOptionLabel={(option) => option.label}
                value={fundOptions?.find((fund) => fund._id === filters.fundId)}
                filterSelectedOptions
                onChange={(_, newValue) => {
                  handleChangeFilters({ fundId: newValue?._id });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Tranche"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3} className={classes.marginSelect}>
              <TextField
                type="search"
                name="investorName"
                placeholder="Search by name or email"
                fullWidth
                value={filters.searchTerm}
                onChange={(event) => {
                  handleChangeFilters({ searchTerm: event.target.value });
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={isSuperAdmin ? 3 : 6}
              className={classes.buttonsGrid}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={exportExcelData}
                disabled={isFetching || isExportLoading}
              >
                {isExportLoading ? (
                  <CircularProgress
                    className={classes.exportSpinner}
                    size={18}
                  />
                ) : null}
                Export
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(INVESTOR_ADD)}
              >
                Add investor
              </Button>
            </Grid>

            {selectedInvestors.length ? (
              <Grid item xs={12} sm={7} md={3} className={classes.bulkActions}>
                <InvestorsBulkActions
                  refetchInvestors={refetch}
                  selectedInvestors={selectedInvestors}
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Table
                columns={columns}
                onRequestSort={pagination.handleRequestSort}
                order={pagination.order}
                orderBy={pagination.orderBy}
                tablebody={data?.result ?? []}
                onPageChange={pagination.handleChangePage}
                onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                count={data?.total || 0}
                pagination={true}
                loading={isFetching}
                variant="nohover"
                selectable
                onSelectionChange={setSelectedInvestors}
              />
            </Grid>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        onConfirm={handleConfirmDelete}
        title={'Are you sure you wish to delete this investor?'}
        content={
          <p>
            This cannot be undone and all information relating to the investor
            will be lost.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete investor',
        }}
      />
      <AlertDialog
        open={disabledDisclosure.isOpen}
        onClose={disabledDisclosure.onClose}
        onConfirm={handleDisable}
        title={'Are you sure you wish to deactivate this investor?'}
        content={
          <p>
            Deactivating this user will prevent them from being able to log in
            to the platform, make investments or access their investor portal.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Deactivate investor',
        }}
      />
    </PageContainer>
  );
};
export default Investor;
