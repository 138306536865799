import React from 'react';
import {
  AccountBalance,
  SupervisedUserCircle,
  LockOutlined,
  LockOpenOutlined,
  HowToReg,
  MonetizationOn,
  BusinessCenter,
  TableChart,
  FlashOn,
  Business,
  Description,
  ShowChart,
  GroupOutlined,
  LocalOffer,
  Settings,
  ArrowBack,
  Mail,
  ViewQuilt,
  ThumbUp,
  History,
} from '@material-ui/icons';
import {
  ADVISER,
  ADVISER_ADD,
  COMPANY,
  DASHBOARD_ALL_FIRMS,
  DASHBOARD_MY_FIRM,
  EIS,
  EIS_ADD,
  EXITS,
  EXITS_ADD,
  INTEREST_PAYMENTS,
  INVESTMENT,
  INVESTOR_REPORTING,
  INVESTOR_REPORTING_ADD,
  INVESTOR_UPLOAD,
  ORGANISATION,
  ORGANISATION_ADD,
  TRANCHE,
  WITHDRAWALS,
  WITHDRAWALS_ADD,
  ADD_CUSTOM_UPDATE,
  INVESTMENT_TRANSFERS,
  INVESTOR_BULLETIN,
  INVESTMENT_ADD,
  KI_EIS_ADD,
  TAX_REPORT_AUDIT,
  TAG,
  CHANGE_LOG,
  USER,
  PROFILE,
  FIRM,
  INVESTOR,
  INCOMPLETE_REGISTER,
  SUBSCRIPTION,
  INCOMPLETE_TRANSACTION,
  FEE_SUMMARY,
  FEE_DISCOUNT_ADD,
  UPLOAD_SHEET,
  ALL_FUNDS,
} from 'constants/routes';

const createSummarySection = (
  openTrancheDashboardLinks,
  firmDashboardLinks,
) => [
  {
    name: 'Summary',
    type: 'section',
    children: [
      {
        name: 'Admin Dashboard',
        icon: <ViewQuilt />,
        link: DASHBOARD_ALL_FIRMS,
        type: 'item',
        onlyShownForRoles: ['super-admin', 'further-staff'],
      },
      firmDashboardLinks && firmDashboardLinks.length
        ? {
            name: 'Firm Dashboard',
            type: 'collapse',
            icon: <ViewQuilt />,
            children: firmDashboardLinks,
          }
        : {
            name: 'Firm Dashboard',
            type: 'item',
            icon: <ViewQuilt />,
            link: DASHBOARD_MY_FIRM,
          },
      openTrancheDashboardLinks && openTrancheDashboardLinks.length
        ? {
            name: 'Open Tranches',
            type: 'collapse',
            icon: <LockOpenOutlined />,
            children: openTrancheDashboardLinks,
          }
        : {},
      {
        name: 'All tranches',
        type: 'item',
        icon: <LockOutlined />,
        link: ALL_FUNDS,
      },
    ],
  },
];

export const createSidebarNav = (
  openTrancheDashboardLinks,
  firmDashboardLinks,
) => [
  ...createSummarySection(openTrancheDashboardLinks, firmDashboardLinks),
  {
    name: 'Fund Administration',
    type: 'section',
    children: [
      {
        name: 'My Firm',
        type: 'item',
        icon: <Business />,
        link: FIRM,
        permission: 'read:firm',
        onlyShownForRoles: ['firm-manager'],
      },
      {
        name: 'Firms',
        type: 'item',
        icon: <Business />,
        link: FIRM,
        permission: 'read:firm',
        onlyShownForRoles: ['super-admin', 'further-staff'],
      },
      {
        name: 'Tranches',
        type: 'item',
        icon: <Description />,
        link: TRANCHE,
        permission: 'read:firm',
      },

      {
        name: 'Investors',
        type: 'collapse',
        icon: <HowToReg />,
        permission: 'read:investor',
        children: [
          {
            name: 'View Investors',
            type: 'item',
            link: INVESTOR,
          },
          {
            name: 'Upload Investors',
            type: 'item',
            link: INVESTOR_UPLOAD,
          },
          {
            name: 'Incomplete Registrations',
            type: 'item',
            link: INCOMPLETE_REGISTER,
            onlyShownForRoles: ['super-admin', 'further-staff'],
          },
          {
            name: 'Email Subscribers',
            type: 'item',
            link: SUBSCRIPTION,
            onlyShownForRoles: ['super-admin', 'further-staff'],
          },
        ],
      },

      {
        name: 'Investments',
        type: 'collapse',
        icon: <ShowChart />,
        permission: 'read:investment',
        children: [
          {
            name: 'View Investments',
            type: 'item',
            link: INVESTMENT,
          },
          {
            name: 'Upload Investments',
            type: 'item',
            link: INVESTOR_UPLOAD,
          },
          {
            name: 'Create Investment',
            type: 'item',
            link: INVESTMENT_ADD,
          },
          {
            name: 'Interest Payments',
            type: 'item',
            link: INTEREST_PAYMENTS,
          },
          {
            name: 'Incomplete Investments',
            type: 'item',
            link: INCOMPLETE_TRANSACTION,
          },
          {
            name: 'Transfer Record',
            type: 'item',
            link: INVESTMENT_TRANSFERS,
          },
        ],
      },

      {
        name: 'Fees',
        type: 'collapse',
        icon: <MonetizationOn />,
        permission: 'read:investment',
        children: [
          {
            name: 'Fees Summary',
            type: 'item',
            link: FEE_SUMMARY,
          },
          {
            name: 'One-Off Fees / Discounts',
            type: 'item',
            link: FEE_DISCOUNT_ADD,
          },
        ],
      },

      {
        name: 'Withdrawals',
        type: 'collapse',
        icon: <AccountBalance />,
        permission: 'read:investment',
        children: [
          {
            name: 'Request Withdrawals',
            type: 'item',
            link: WITHDRAWALS_ADD,
          },
          {
            name: 'Review Withdrawals',
            type: 'item',
            link: WITHDRAWALS,
          },
        ],
      },

      {
        name: 'Advisers',
        type: 'collapse',
        icon: <GroupOutlined />,
        permission: 'read:advisers',
        children: [
          {
            name: 'View Advisers',
            type: 'item',
            link: ADVISER,
          },
          {
            name: 'Create Adviser',
            type: 'item',
            link: ADVISER_ADD,
          },
          {
            name: 'View Organisations',
            type: 'item',
            link: ORGANISATION,
          },
          {
            name: 'Create Adviser Organisation',
            type: 'item',
            link: ORGANISATION_ADD,
          },
        ],
      },
    ],
  },
  {
    name: 'Portfolio',
    type: 'section',
    children: [
      {
        name: 'Companies',
        type: 'item',
        icon: <BusinessCenter />,
        link: COMPANY,
        permission: 'read:companies',
      },
      {
        name: 'Allocations',
        type: 'collapse',
        icon: <TableChart />,
        children: [
          {
            name: 'Upload Shareholding',
            type: 'item',
            link: UPLOAD_SHEET,
          },
          {
            name: 'Tranche Record',
            type: 'item',
            link: '/coming-soon?title=Tranche%20Record',
            onlyShownForRoles: ['super-admin', 'further-staff'],
          },
        ],
      },
      {
        name: 'S/EIS Certificates',
        type: 'collapse',
        icon: <FlashOn />,
        permission: 'read:seis',
        children: [
          {
            name: 'View S/EIS Tasklist',
            type: 'item',
            link: EIS,
          },
          {
            name: 'Create S/EIS Certificates',
            type: 'item',
            link: EIS_ADD,
          },
          {
            name: 'Create KI Fund Certificates',
            type: 'item',
            link: KI_EIS_ADD,
          },
        ],
      },
      {
        name: 'Exits',
        type: 'collapse',
        icon: <ThumbUp />,
        children: [
          {
            name: 'View Exits',
            type: 'item',
            link: EXITS,
          },
          {
            name: 'Process New Exit',
            type: 'item',
            link: EXITS_ADD,
          },
        ],
      },
      {
        name: 'Investor Communications',
        type: 'collapse',
        icon: <Mail />,
        permission: 'read:investor',
        children: [
          {
            name: 'Investor Reporting',
            type: 'item',
            link: INVESTOR_REPORTING,
          },
          {
            name: 'Create Periodic Update',
            type: 'item',
            link: INVESTOR_REPORTING_ADD,
          },
          {
            name: 'Create Custom Update',
            type: 'item',
            link: ADD_CUSTOM_UPDATE,
          },
          {
            name: 'Investor Bulletin',
            type: 'item',
            link: INVESTOR_BULLETIN,
          },
          {
            name: 'Tax Report Audit',
            type: 'item',
            link: TAX_REPORT_AUDIT,
          },
        ],
      },
    ],
  },

  {
    type: 'section',
    name: 'Settings',
    children: [
      {
        name: 'Fund Types',
        type: 'item',
        icon: <LocalOffer />,
        link: TAG,
        onlyShownForRoles: ['super-admin', 'further-staff'],
      },
      {
        name: 'Change log',
        type: 'item',
        icon: <History />,
        link: CHANGE_LOG,
        onlyShownForRoles: ['super-admin'],
      },
      {
        name: 'Users',
        type: 'item',
        icon: <SupervisedUserCircle />,
        link: USER,
        permission: 'read:users',
        onlyShownForRoles: ['super-admin', 'further-staff'],
      },
      {
        name: 'Profile',
        type: 'item',
        icon: <Settings />,
        link: PROFILE,
      },
      {
        name: 'Logout',
        type: 'item',
        isLogout: true,
        icon: <ArrowBack />,
      },
    ],
  },
];

// These are exported for use in horizontal layouts which are not used

export const minimalHorizontalMenus = [];
export const horizontalDefaultNavs = [];
