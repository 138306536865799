import { useState, useEffect } from 'react';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { useGetRole } from 'hooks/ui/useGetRole';
import '@silevis/reactgrid/styles.css';
import ReactGridForm from './ReactGridForm';
import PreviewTable from './PreviewTable';
import FullPageSuccess from 'components/FullPageSuccess';
import { INVESTOR_UPLOAD } from 'constants/routes';
import { useFunds } from 'hooks/data/fund/useFunds';
import { useAdvisers } from 'hooks/data/adviser/useAdvisers';
import CardHeader from 'components/CardHeader';
import { makeStyles } from '@material-ui/core';

const STEPS = {
  CREATE_GRID_DATA: 0,
  PREVIEW_UPLOAD: 1,
  UPLOAD_SUCCESS: 2,
};

const Wrapper = ({ children }) => (
  <PageContainer heading="Investors: Upload investors and investments">
    {children}
  </PageContainer>
);

const useStyles = makeStyles(() => ({
  paragraph: {
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}));

const UploadInvestorInvestment = () => {
  const classes = useStyles();
  const [gridDropdownsData, setGridDropdownsData] = useState({
    tranche: [],
    adviser: [],
  });
  const [activeStep, setActiveStep] = useState(STEPS.CREATE_GRID_DATA);
  const [uploadPreviewState, setUploadPreviewState] = useState({
    totalInvestors: 0,
    existingInvestorAccounts: [],
  });
  const { firmId } = useGetRole();
  //@ts-ignore useFunds is not typed
  const { tranches } = useFunds({ firmId, fullyDeployed: false });
  const { data } = useAdvisers({ params: { firmId } });

  useEffect(
    function setDropdownListsData() {
      if (tranches.data && data?.adviser) {
        const tranchesList = tranches.data.map(
          ({ _id, fundName, firmId: { firmName } }) => ({
            label: `${firmName} ${fundName}`,
            value: _id,
          }),
        );

        const advisersList = data.adviser.map(({ _id, fullName, email }) => ({
          label: fullName ? `${fullName} ${email}` : email,
          value: _id,
        }));

        const listSorter = ({ label: labelA }, { label: labelB }) =>
          labelA.localeCompare(labelB);

        tranchesList.sort(listSorter);
        advisersList.sort(listSorter);

        setGridDropdownsData((gridDropdownsData) => ({
          ...gridDropdownsData,
          tranche: tranchesList,
          adviser: advisersList,
        }));
      }
    },
    [tranches.data, data?.adviser],
  );

  const uploadPreviewSet = (uploadPreviewState) => {
    setUploadPreviewState(uploadPreviewState);
    setActiveStep(STEPS.PREVIEW_UPLOAD);
  };

  const uploadSuccessSet = () => {
    setActiveStep(STEPS.UPLOAD_SUCCESS);
  };

  if (activeStep === STEPS.CREATE_GRID_DATA)
    return (
      <Wrapper>
        <CmtCard>
          <CmtCardContent>
            <CardHeader title="Input your investors and investments into the table" />
            <div className={classes.paragraph}>
              <p>
                <b>How to use this feature:</b> The below upload table can be
                used for uploading one or more investors and investments at the
                same time. Type or paste information into cells to continue.
                Leaving this page without submitting can cause entered data to
                be lost.
              </p>
              <p>
                If an email address is already in use on the platform and the
                date of birth matches that account, any associated investment
                will be added to the relevant existing investor's account.
              </p>
            </div>
            <ReactGridForm
              gridDropdownsData={gridDropdownsData}
              uploadPreviewSet={uploadPreviewSet}
            />
          </CmtCardContent>
        </CmtCard>
      </Wrapper>
    );

  if (activeStep === STEPS.PREVIEW_UPLOAD)
    return (
      <Wrapper>
        <CmtCard>
          <CmtCardContent>
            <PreviewTable
              totalInvestors={uploadPreviewState.totalInvestors}
              existingInvestorAccounts={
                uploadPreviewState.existingInvestorAccounts
              }
              handleSubmitCallback={uploadSuccessSet}
              handleBack={() => setActiveStep(STEPS.CREATE_GRID_DATA)}
              tranches={tranches?.data}
            />
          </CmtCardContent>
        </CmtCard>
      </Wrapper>
    );

  if (activeStep === STEPS.UPLOAD_SUCCESS) {
    return (
      <Wrapper>
        <FullPageSuccess
          title="Your investors and investments have been uploaded successfully"
          additionalText={`
                Congratulations, these investors and investments have been
                successfully uploaded and are visible within your investor and
                investment databases.`}
          proceedText="Create another upload"
          proceedLink={INVESTOR_UPLOAD}
          proceedCallback={() => {
            setActiveStep(STEPS.CREATE_GRID_DATA);
          }}
        />
      </Wrapper>
    );
  }

  return null;
};

export default UploadInvestorInvestment;
