import React, { useMemo } from 'react';
import Table from 'components/Table';
import CardHeader from 'components/CardHeader';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { Button } from '@material-ui/core';
import { INVESTMENT_SOURCE } from 'constants/investment';
import { Payment } from 'hooks/data/investment/usePayments';
import { InvestmentPaymentType } from 'further-types/payment';

type Props = {
  payments: Payment[];
  markPaymentReceived: (paymentId: string) => void;
};

const PaymentsTable: React.FunctionComponent<Props> = ({
  payments,
  markPaymentReceived,
}) => {
  const columns = useMemo(
    () => [
      {
        label: 'Type',
        key: 'type',
        sort: false,
        render: (elm: Payment) => {
          const types = {
            [InvestmentPaymentType.Banked]: 'Banked',
            [InvestmentPaymentType.ManuallyRecorded]: 'Manual',
            [InvestmentPaymentType.CashBalance]: 'Cash balance',
          } as const;

          if (elm.type && elm.type in types) {
            return types[elm.type];
          }
          return 'Manual';
        },
      },
      {
        label: 'Payment date',
        key: 'createdAt',
        sort: false,
        render: (elm: Payment) =>
          dateToLabel(elm.sentAt || elm.investmentId.investmentDate),
      },
      {
        label: 'Amount',
        key: 'investmentAmount',
        sort: false,
        render: (elm: Payment) => numberToCurrencyString(elm.amount),
      },
      {
        label: 'Payment status',
        sort: false,
        render: (elm: Payment) => {
          switch (elm.type) {
            case InvestmentPaymentType.Banked: {
              return 'Payment received (via Open Banking)';
            }
            case InvestmentPaymentType.CashBalance: {
              if (elm.paymentReceived || elm.investmentId.isPaymentReceived) {
                return 'Payment received';
              }
              return 'Payment pending'; // this should never happen because payments from cash balance are received straight away
            }
            case InvestmentPaymentType.ManuallyRecorded:
            default: {
              if (elm.paymentReceived) {
                return elm.investmentId.source === INVESTMENT_SOURCE.UPLOAD
                  ? 'Payment received'
                  : 'Payment received (via bank transfer)';
              }
              return (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => markPaymentReceived(elm._id)}
                  data-testid={`btn-mark-payment-received-${elm._id}`}
                >
                  Mark payment received
                </Button>
              );
            }
          }
        },
      },
    ],
    [markPaymentReceived],
  );

  return (
    <>
      <CardHeader title="Payment details" className={''} />
      <Table
        columns={columns}
        tablebody={payments}
        emptyMessage="No payments found"
        variant="nohover"
      />
    </>
  );
};

export default PaymentsTable;
